export function getUserScenarioDoneById(user) {
	// Get scenario done by id
	return user.logs.reduce((dict, log) => {
		if (log.type.slug == 'complete_scenario') {
			dict[log.data_key] = true
		}

		return dict
	}, {})
}

export function getUserProgressionForGroup(user, group, defaultCourse) {
	// Get course from group or take the default one
	const course = (group.course || defaultCourse)

	if (!course) {
		return 0
	}

	const userScenarioDoneById = getUserScenarioDoneById(user)

	if (Object.keys(userScenarioDoneById).length === 0) {
		return 0
	}

	let scenarioDone = 0
	let scenarioAvailable = 0

	// Count scenario done and available in this group
	course.modules.forEach((module) => {
		// Count scenario done in this module
		module.scenarios.forEach((scenario) => {
			if (userScenarioDoneById[scenario.id]) {
				scenarioDone += 1
			}
		})

		// Count scenario available in this module
		scenarioAvailable += module.scenarios.length

		// Count scenario available and done in the module sequences
		module.sequences.forEach((sequence) => {
			// Count scenario done in this sequence
			sequence.scenarios.forEach((scenario) => {
				if (userScenarioDoneById[scenario.id]) {
					scenarioDone += 1
				}
			})

			// Count scenario available in this sequence
			scenarioAvailable += sequence.scenarios.length
		})
	})

	// Set progression percentage for this group
	if (scenarioAvailable <= 0) {
		return 0
	}

	return (scenarioDone / scenarioAvailable)
}

export function getUserProgression(user, defaultCourse) {
	if (user.groups.length <= 0)
		return 0

	// Make an average of user progression per group
	let sum = 0
	let count = 0

	user.groups.forEach((userGroup) => {
		const progression = getUserProgressionForGroup(user, userGroup.group, defaultCourse)

		sum += progression
		count += 1
	})

	if (count <= 0)
		return 0

	return (sum / count)
}

export function getUserState(user) {
	if (user.disabled) {
		return 'disabled'
	} else if (!user.cognito_id && !user.cognito_confirmed) {
		return 'inactive'
	} else if (user.cognito_id) {
		if (user.cognito_confirmed) {
			return 'active'
		} else {
			return 'pending'
		}
	} else {
		return 'exist'
	}
}

export function getUserGroupState(user) {
	// Check if the user group is not disabled and still active (start/end date)
	if (user.groups?.length > 0) {
		if (user.groups[0].group.disabled) {
			return 'disabled'
		} else {
			const now = Date.now()
			const startDate = new Date(user.groups[0].group.start_date)
			const endDate = new Date(user.groups[0].group.end_date)

			if (now < startDate.getTime() || now > endDate.getTime()) {
				return 'inactive'
			}
		}
	}

	return null
}
