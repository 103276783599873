<template>
    <label class="switch">
        <input :disabled="disabled" type="checkbox" :checked="checked" @input="onInput">
        <span class="slider round"></span>
    </label>
</template>
<script>
export default {
    props: ['onInput', 'checked', 'disabled']
}
</script>
<style lang="scss" scoped>
    /* The switch - the box around the slider */
    .switch {
        @apply text-center cursor-pointer;
        position: relative;
        display: inline-block;
        width: 50px;
        height: 24px;
        margin-bottom: 0;
    }

    .switch input[type="radio"] {
        @apply appearance-none border-2 rounded-full w-4 h-4 cursor-pointer;
        border-color: #CCC;

        &:checked {
            @apply bg-red border-red;
        }
    }

    /* Hide default HTML checkbox */
    .switch input[type="checkbox"] {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        @apply bg-principal-selected;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 24px;
    }

    .slider.round:before {
        border-radius: 50%;
    } 
</style>