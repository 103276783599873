<template>
    <div class="scenarios">
        <List
        listing="scenario" 
        :fields="fields" 
        :filters="filters"
        :default-sort-by="'location'"
        :default-sort-desc="false"
        modify-button-text="Éditer le Scénario"
        :with-delete-button="false"
        searchText="Rechercher un scénario"
        editRouteName="scenario-edit">
            <template v-slot:buttons>
                <div class="md:flex flex-row justify-around hidden md:w-1/3">
                    <button type="button" class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2" @click="addScenario">Ajouter un scénario</button>
                </div>
            </template>
            <template v-slot:fab>
                <vue-fab
                    class="md:hidden"
                    fabItemAnimate="alive"
                    fabAliveAnimateBezier="ease-in-out"
                    mainBtnColor="#fff"
                    size="big"
                >
                    <fab-item class="twn-fab add" @clickItem="addScenario" color="#DC9799" :idx="0.5" title="Ajouter un scénario" icon="add" />
                </vue-fab>
            </template>
        </List>
    </div>
</template>

<script>
import List from '@/components/List'

import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"

export default {
    name:'ScenarioList',
    components: { List },
    mixins: [ dispatchStoreRequest ],
    data() {
        return {
            fields: [
                {
                    key:'identifier',
                    label: 'Identifiant',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Titre',
                    sortable: true,
                },
                {
                    key: 'nodes_aggregate',
                    label: 'Noeuds',
                    sortable: true,
                    db: `nodes_aggregate {
                        aggregate {
                            count
                        }
                    }`,
                    nested: 'count'
                },
                {
                    key: 'sequences',
                    label: 'Parcours',
                    db: `
                    modules {
                        module {
                            identifier
                            title
                            course {
                                id
                                identifier
                                title
                            }
                        }
                    }
                    sequences {
                        sequence {
                            module {
                                identifier
                                title
                                course {
                                    id
                                    identifier
                                    title
                                }
                            }
                        }
                    }`,
                    formatter: (value, key, item) => {
                        const courses = {}

                        item.modules.forEach((scenarioModule) => {
                            const module = scenarioModule.module
                            const course = scenarioModule.module.course

                            if (!courses[course.id]) {
                                courses[course.id] = [course.identifier, course.title].join(' - ') + ' : ' + [module.identifier, module.title].join(' - ')
                            }
                        })

                        item.sequences.forEach((scenarioSequence) => {
                            const module = scenarioSequence.sequence.module
                            const course = scenarioSequence.sequence.module.course

                            if (!courses[course.id]) {
                                courses[course.id] = [course.identifier, course.title].join(' - ') + ' : ' + [module.identifier, module.title].join(' - ')
                            }
                        })

                        return Object.keys(courses).map(key => courses[key]).join(', ')
                    }
                }
            ],
            filters: []
        }
    },
    async created(){
        await this.dispatchStoreRequest('Scenarios/getList')
    },
    methods: {
      addScenario() {
        this.$router.push({ name:'scenario-edit' })
      }
    }
}
</script>

<style lang="scss" scoped></style>