import utils from "@/utils/trackingReportTemplate"

export default function usersDashboardData(data) {
	const countbyState = utils.groupMap(utils.groupBy(data.users, 'state'), (users) => {
		return users.length
	})

	const hasFinishedCount = utils.countMatch(data.users, user => user.progressionData.hasDoneLevel2)

	return {
		active: countbyState.active || 0,
		inactive: countbyState.inactive || 0,
		hasFinished: (hasFinishedCount / data.confirmedUsers.length),
		total: data.users.length,
		pending: countbyState.pending || 0,
		disabled: countbyState.disabled || 0,
	}
}