<template>
  <div class="relative dashboard-survey-results shadow-light">
    <p>{{ survey.title }}</p>

    <router-link
      v-if="!isReadOnly && !isStatic"
      class="absolute top-0 right-0 p-4 text-sm italic underline"
      :to="{
        name: 'game-edit',
        params: { itemID: survey.id, gameTypeSlug: 'survey' },
      }"
      target="_blank"
    >
      Modifier
    </router-link>

    <!-- Questions -->
    <div
      v-for="(question, index) in survey.questions"
      :key="question.id"
      :class="{ 'mb-4': index < survey.questions.length - 1 }"
    >
      <p class="mb-2">{{ question.text }}</p>

      <!-- Answers -->
      <div
        v-for="(answer, index) in question.answers"
        :key="answer.id"
        class="flex"
      >
        <span
          class="w-5 h-5 flex-shrink-0 text-white text-center bg-dashboard-blue-dark mr-2"
        >
          {{ getAnswerIndexLabel(index) }}
        </span>
        <span
          v-if="question.type && question.type.slug === 'scale'"
          class="flex-grow mr-2"
        >
          ({{ answer.value }}) {{ answer.text }}
        </span>
        <span v-else class="flex-grow mr-2">
          {{ answer.text }}
        </span>
        <span class="w-16 flex-shrink-0 text-right">
          {{ formatedAnswersResult[answer.id + question.id] || "-" }}
        </span>
      </div>

      <!-- Number of answers for this question -->
      <p
        v-if="survey && survey.results.questionCount[question.id]"
        class="text-sm text-right"
      >
        {{ survey.results.questionCount[question.id] }} réponses
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

function formatToPercentage(firstValue, secondValue) {
  const value = ((firstValue || 0) / secondValue) * 100;

  if (value > 0 && value < 1) {
    return value.toFixed(2) + " %";
  }

  return Math.round(value) + " %";
}

export default {
  name: "SurveyResultsBlock",
  props: {
    survey: {
      type: Object,
      required: true,
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("Auth", ["userInfo"]),
    isSuperAdmin() {
      return this.userInfo && this.userInfo.role == "superadmin";
    },
    isReadOnly() {
      if (this.isSuperAdmin) {
        return false;
      }

      return this.survey?.author_id !== this.userInfo?.id;
    },
    formatedAnswersResult() {
      return this.survey.questions.reduce((dict, question) => {
        const questionCount = this.survey.results.questionCount[question.id];

        question.answers.forEach((answer) => {
          const answerCount =
            this.survey.results.answerCount[answer.id + question.id] || 0;
          
          if (!questionCount) {
            dict[answer.id + question.id] = "-";
          } else {
            dict[answer.id + question.id] = formatToPercentage(
              answerCount,
              questionCount
            );
          }
        });

        return dict;
      }, {});
    },
  },
  methods: {
    getAnswerIndexLabel(index) {
      let label = "";

      const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for (let i = 0; i < Math.ceil((index + 1) / letters.length); i += 1) {
        label += letters[index % letters.length];
      }

      return label;
    },
  },
};
</script>

<style lang="scss">
.dashboard-survey-results {
  @apply bg-white rounded-dashboard-sm p-8;

  & > p {
    @apply text-lg text-black mb-4;
  }
}
</style>
