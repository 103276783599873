<template>
	<div class="mt-6">
		<span class="text-xl font-principal-medium">
			Actes de l’enquête
		</span>

		<div v-if="sequences && sequences.length > 0">
			<draggable v-model="sequences" handle=".handle">
				<InvestigationSequenceForm
				v-for="(sequence, index) in sequences"
				:key="index"
				:sequence="sequence"
				@update-sequence="updateSequenceData(index, $event)" />
			</draggable>
		</div>
	</div>
</template>

<script>
	import Draggable from "vuedraggable"

	import InvestigationSequenceForm from "@/components/common/course/InvestigationSequenceForm"

	export default {
		name: 'InvestigationSequences',
		components: {
			Draggable,
			InvestigationSequenceForm
		},
		props: {
			module: {
				type: Object,
				required: true
			}
		},
		computed: {
			sequences: {
				get() {
					return [...this.module.sequences]
				},
				set(sequences) {
					// Make sure order field is correct
					sequences.forEach((sequence, index) => {
						sequence.order = index
					})

					this.$emit('update-module', {
						sequences
					})
				}
			}
		},
		watch: {
			module: {
				handler(module) {
					// Automaticly add 3 sequences if not provided
					if (module && module.sequences.length <= 2) {
						for (var i = module.sequences.length; i < 3; i++) {
							this.addSequence()
						}
					}
				},
				immediate: true
			}
		},
		methods: {
			addSequence() {
				this.sequences.push({
					identifier: '',
					title: '',
					order: this.sequences.length,
					scenarios: [],
					media: [],
					metas: []
				})

				this.$emit('update-module', {
					sequences: this.sequences
				})
			},
			updateSequenceData(index, sequenceData) {
				// Copy sequence data
				let sequence = {...this.sequences[index]}

				// Update specified data key
				const dataKeys = Object.keys(sequenceData)

				for (var i = 0; i < dataKeys.length; i++) {
					sequence[dataKeys[i]] = sequenceData[dataKeys[i]]
				}

				// Replace sequence data
				this.sequences.splice(index, 1, sequence)

				// Dispatch update to parent
				this.$emit('update-module', {
					sequences: this.sequences
				})
			}
		},
	}
</script>

<style lang="scss" scoped>
</style>