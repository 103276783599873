import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//import layouts
import Default from '@/layouts/Default.vue'
import NoSidebar from '@/layouts/NoSidebar.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import './utils/veeValidate'

import vSelect from 'vue-select'

//Import tailwind
import '@/assets/scss/_index.scss'

import apollo from './apolloClient'

import VueFab from 'vue-float-action-button'

import vClickOutside from 'v-click-outside'

import VueApollo from 'vue-apollo'

import VueChartist from 'vue-chartist'

import '@/bootstrap/importBootstrapComponents'

Vue.component('default-layout', Default)
Vue.component('no-sidebar-layout', NoSidebar)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.component('v-select', vSelect)

Vue.use(vClickOutside)
Vue.use(VueFab)
Vue.use(VueApollo);
Vue.use(VueChartist)

const apolloProvider = new VueApollo({
  defaultClient: apollo,
})


Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  apolloProvider,
  store,
  render: h => h(App)
}).$mount('#app')
