<template>
	<div class="flex flex-col">
		<label class="mb-1" :for="`slide-block-media-${uuid}`">
			Média Image, Vidéo ou Audio
		</label>

		<div class="flex">
			<div class="w-12 h-8 mr-2">
				<img
					v-if="media_id && assetsById[media_id]"
					class="max-h-full m-auto"
					:class="{ 'py-1': !assetsById[media_id].thumbnail_url }"
					:src="assetsById[media_id].thumbnail_url || iconForType[assetsById[media_id].type.slug]"
				>
				<p
					v-else
					class="w-12 text-center"
				>
					-
				</p>
			</div>
			<v-select
				:id="`slide-block-media-${uuid}`"
				class="flex-grow"
				placeholder="Rechercher un média..."
				:options="assets"
				:getOptionLabel="getOptionLabel"
				:reduce="asset => asset.id"
				v-model="media_id"
			/>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

	let uuid = 0

	const iconForType = {
		video: require('../../../assets/images/thumbnails/VIDEO_THUMBNAIL.svg'),
		audio: require('../../../assets/images/thumbnails/AUDIO_THUMBNAIL.svg'),
	}

	export default {
		name: 'BlockSlideFormMedia',
		mixins: [
			dispatchStoreRequest,
		],
		props: {
			block: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				iconForType,
			}
		},
		computed: {
			...mapState('Assets', {
				assets: (state) => state.assets.filter((medium) => {
					return (['image', 'video', 'audio'].indexOf(medium.type.slug) > -1)
				}),
				assetsById(state) {
					if (!state.assets || state.assets.length <= 0)
						return {}

					return state.assets.reduce((dict, asset) => {
						dict[asset.id] = asset

						return dict
					}, {})
				},
			}),
			media_id: {
				get() {
					if (this.block?.media?.length <= 0 || !this.block.media[0].media_id || !this.assetsById[this.block.media[0].media_id])
						return null

					return this.block.media[0].media_id
				},
				set(media_id) {
					const media = [{ media_id }]

					this.$emit('update', {
						media
					})
				},
			},
		},
		beforeCreate() {
			this.uuid = uuid.toString()
			uuid += 1
		},
		async created() {
			await this.dispatchStoreRequest('Assets/getAssetList')
		},
		methods: {
			getOptionLabel(option) {
				if (!option)
					return '~ Élément supprimé ~'

				return [option.identifier, option.title, option.name].filter(str => str || false).join(' - ') || '~ Élément sans titre ou supprimé ~'
			},
		},
	}
</script>