import gql from "graphql-tag"

const GC_GET_GAME_LIST = gql`
	query GetGameList {
		game: activity {
			id
			name: identifier
			title
			game_type_id: activity_type_id
			type {
				id
				name
				slug
			}
		}
	}
`

const GC_GET_GAME_TYPES = gql`
	query GetGameTypes {
		game_type: activity_type(order_by: { slug: asc }) {
			id
			name
			description
			slug
		}
	}
`

const GC_GET_QUESTION_TYPES = gql`
query GetQuestionTypes {
	question_type {
		id
		name
		slug
	}
}
`

const GC_GET_JAUGES = gql`
query GetJauges{
	jauges: score_type(order_by: {slug: asc}) {
		id
		name
		slug
	}
}
`

const GC_ADD_GAME_ONE = gql`
	mutation AddGameOne(
		$name: String
		$title: String
		$instruction: String
		$gameType: uuid!
		$metas: [activity_meta_insert_input!]!
		$questions: [question_insert_input!]!
		$show_correction: Boolean
		$scores: [activity_score_insert_input!]!
		$medias: [activity_media_insert_input!]!
		$correction: String
		$help: String!
	) {
		insert_activity_one(
			object: {
				identifier: $name
				title: $title
				correction: $correction
				help: $help
				show_correction: $show_correction
				instruction: $instruction
				activity_type_id: $gameType
				metas: { data: $metas }
				questions: { data: $questions }
				media: { data: $medias }
				scores: { data: $scores }
			}
		) {
			id
		}
	}
`

const GC_GET_GAME_BY_ID = gql`
	query GameByID($id: uuid!) {
		game: activity(where: { id: { _eq: $id } }) {
			name: identifier
			title
			instruction
			show_correction
			id
			correction
			help
			metas {
				id
				value
				meta_type_id
				activity_id
			}
			contentLogs(order_by: {created_at: desc}) {
				created_at
				user {
					name: last_name
					first_name
				}
			}
			medias: media{
				media_id
				order
			}
			scores{
				score
				score_type_id
			}
			type {
				name
				id
				description
				slug
			}
			questions {
				id
				text
				correction
				correction_media_id
				help
				column
				line
				question_type_id
				media_id
				activity_id
				required
				answers {
					id
					text
					media_id
					is_correct
				}
			}
		}
	}
`

const GC_DELETE_GAME_BY_ID = gql`
	mutation DeleteGameOne($id: uuid!) {
		delete_activity(where: { id: { _eq: $id } }) {
			affected_rows
		}
		delete_question(where: { activity_id: { _eq: $id } }) {
			affected_rows
		}
		delete_activity_score(where: { activity_id: { _eq: $id } }){
			affected_rows
		}
		delete_activity_media(where: { activity_id: { _eq: $id } }){
			affected_rows
		}
	}
`

const GC_UPDATE_GAME_BY_ID = gql`
	mutation UpdateGameOne(
		$id: uuid!
		$instruction: String
		$name: String
		$title: String
		$gameType: uuid!
		$correction: String
		$help: String!
		$metas: [activity_meta_insert_input!]!
		$questions: [question_insert_input!]!
		$show_correction: Boolean
		$scores: [activity_score_insert_input!]!
		$medias: [activity_media_insert_input!]!
	) {
		delete_activity_meta(where: { activity_id: { _eq: $id } }) {
			affected_rows
		}
		delete_question(where: { activity_id: { _eq: $id } }) {
			affected_rows
		}
		delete_activity_score(where: { activity_id: { _eq: $id } }) {
			affected_rows
		}
		delete_activity_media(where: { activity_id: { _eq: $id } }) {
			affected_rows
		}
		update_activity_by_pk(
			pk_columns: {id: $id},
			_set: {
				identifier: $name
				title: $title
				correction: $correction
				help: $help
				show_correction: $show_correction
				instruction: $instruction
				activity_type_id: $gameType
			}
		) {
			id
		}
		insert_activity_meta(objects: $metas) {
			affected_rows
		}
		insert_question(objects: $questions) {
			affected_rows
		}
		insert_activity_score(objects: $scores) {
			affected_rows
		}
		insert_activity_media(objects: $medias) {
			affected_rows
		}
	}
`

const GC_CHECK_NAME_EXISTENCE = gql`
	query NameExist($name: String!) {
		game: activity(where: { identifier: { _eq: $name } }) {
			id
		}
	}
`

const GC_GET_GAMES_BY_TYPE = gql`
	query ActivitiesByType($typeSlug: String!) {
		activity(where: { type: { slug: { _eq: $typeSlug } } }) {
			id
			identifier
			title
			questions {
				id
				text
				type {
					slug
				}
				answers {
					id
					text
				}
			}
		}
	}
`

export {
	GC_GET_GAME_TYPES,
	GC_GET_QUESTION_TYPES,
	GC_GET_GAME_LIST,
	GC_ADD_GAME_ONE,
	GC_GET_JAUGES,
	GC_GET_GAME_BY_ID,
	GC_UPDATE_GAME_BY_ID,
	GC_DELETE_GAME_BY_ID,
	GC_CHECK_NAME_EXISTENCE,
	GC_GET_GAMES_BY_TYPE,
}
