<template>
	<div>
		<!-- Title -->
		<div>
			<label for="slide-title">Titre</label>
			<input type="text" id="slide-title" class="block twn-input w-full" v-model="title">
		</div>

		<!-- Blocks -->
		<Draggable
			v-model="blocks"
			handle=".handle"
		>
			<div
				class="w-full slide-block flex flex-row relative justify-center items-center my-8"
				v-for="(block, index) in blocks"
				:key="block.id || index"
			>
				<!-- Header/Handle -->
				<div class="handle self-stretch w-10 flex justify-center items-center cursor-move">
					<div class="rotate-icon">
						<b-icon-justify></b-icon-justify>
					</div>
				</div>

				<!-- Content -->
				<div class="flex items-center flex-1">
					<!-- Index -->
					<div class="handle cursor-pointer index bg-principal-selected w-10 self-stretch flex justify-center items-center text-xs">
						<div>{{ block.order + 1 }}</div>
					</div>

					<!-- Form -->
					<div class="content p-8 w-full">
						<component 
							:is="blockComponentForType[block.slide_block_type_slug]"
							:block="block"
							@update="$emit('update-block', { index, fields: $event })"
						/>
					</div>
				</div>

				<!-- Footer -->
				<div
					@click="$emit('delete-block', index)"
					class="cursor-pointer delete-slide-block self-stretch w-10 flex justify-center items-center text-white"
				>
					<b-icon-x></b-icon-x>
				</div>
			</div>
		</Draggable>

		<div class="flex justify-center items-center mt-6">
			<button
				class="plus rounded-lg h-10 px-4 flex justify-center items-center"
				@click="$emit('add-block', 'text')"
			>
				<span class="text-xl mr-1">+</span> Titre/texte
			</button>

			<button
				class="plus rounded-lg h-10 px-4 flex justify-center items-center ml-4"
				@click="$emit('add-block', 'epigraph_default')"
			>
				<span class="text-xl mr-1">+</span> Exergue
			</button>

			<button
				class="plus rounded-lg h-10 px-4 flex justify-center items-center ml-4"
				@click="$emit('add-block', 'media')"
			>
				<span class="text-xl mr-1">+</span> Média
			</button>

			<button
				class="plus rounded-lg h-10 px-4 flex justify-center items-center ml-4"
				@click="$emit('add-block', 'media_carousel')"
			>
				<span class="text-xl mr-1">+</span> Carrousel de médias
			</button>
		</div>
	</div>
</template>

<script>
	import Draggable from 'vuedraggable'

	import BlockSlideFormText from './BlockSlideFormText'
	import BlockSlideFormEpigraph from './BlockSlideFormEpigraph'
	import BlockSlideFormMedia from './BlockSlideFormMedia'
	import BlockSlideFormCarousel from './BlockSlideFormCarousel'

	export default {
		name: 'BlockSlide',
		props: {
			slide: {
				type: Object,
				required: true,
			},
		},
		components: {
			Draggable,
		},
		data() {
			return {
				blockComponentForType: {
					text: BlockSlideFormText,
					epigraph_default: BlockSlideFormEpigraph,
					epigraph_info: BlockSlideFormEpigraph,
					epigraph_warning: BlockSlideFormEpigraph,
					media: BlockSlideFormMedia,
					media_carousel: BlockSlideFormCarousel,
				},
			}
		},
		computed: {
			title: {
				get() {
					return this.slide.title
				},
				set(title) {
					this.$emit('update', {
						title,
					})
				}
			},
			blocks: {
				get() {
					if (!this.slide.blocks)
						return []

					return [...this.slide.blocks]
				},
				set(blocks) {
					this.$emit('set-blocks', blocks)
				}
			}
		},
	}
</script>

<style lang="scss">
	.slide-block {
		background: white;
		box-shadow: $cardShadow;
		border-radius: 10px;
		color: $textLight;

		&.removeState {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}

		.index {
			background-color: rgba(220, 151, 153, 0.1);
		}

		.rotate-icon {
			transform: rotate(90deg);
		}

		.image-content {
			.image {
				img {
					max-height: 150px;
				}
			}
		}

		.delete-slide-block {
			@apply bg-principal-selected;
			border-radius: 0 10px 10px 0;
		}

		input, textarea, select {
			color: $textMainColor;
		}

		label {
			@apply cursor-pointer;
			margin-bottom: 0;
		}
	}
</style>