function mailDateFromLog(user, mailType) {
	if (!user.mailingLogs[mailType]) {
		return null
	}

	return new Date(user.mailingLogs[mailType].created_at)
}

export default {
	name: 'Utilisateurs',
	content: [
		{ 
			cells: [ 'CLIENT', '', 'PROMOTION', '', 'UTILISATEUR', '', '', '', '', '', '', ''],
		},
		{
			table: 'users',
			headers: [ 'ID Client', 'Client', 'ID Promotion', 'Promotion', 'Nom', 'Prénom', 'Email', 'Date d\'invitation', 'Dernière connexion', 'Nombre de connexion', 'Durée totale de connexion'],
			row: (user, data) => {
				const userGroupId = user.groups?.[0]?.id
				const customerGroup = (userGroupId && data.customersGroupsById[userGroupId])

				const accessDate = (mailDateFromLog(user, 'access-account') || mailDateFromLog(user, 'access-before') || mailDateFromLog(user, 'access-late') || mailDateFromLog(user, 'access-active'))

				return [
					{ value: (customerGroup?.customer.identifier || '') },
					{ value: (customerGroup?.customer.name || '') },
					{ value: (customerGroup?.identifier || '') },
					{ value: (customerGroup?.name || '') },
					'last_name',
					'first_name',
					'email',
					{ value: (accessDate?.getTime() || '-'), type: (accessDate ? 'date' : undefined) },
					{ value: user.activeTimeData.lastSessionTimestamp, type: 'date' },
					{ value: user.activeTimeData.times.length },
					{ value: user.activeTimeData.sum, type: 'duration' },
				]
			},
		}
	]
}