<template>
    <div class="game-simple">
        <!-- Messages/Questions list -->
        <Draggable v-model="messages" handle=".handle" v-bind="dragOptions">
            <div class="" v-for="(question, index) in messages" :key="index">
                <Message
                class="my-5"
                :question="question"
                :identifier="getMessageIndexLabel(index)"
                @delete-question="deleteQuestion(index)"
                @update-question="updateQuestion(index, $event)" />
            </div>
        </Draggable>

        <div
            v-if="canHadMessage"
            @click="addMessage"
            class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl "
        >
            +
        </div>

        <!-- Media panel -->
        <RightPanel ref="rightPanel" title="Choix d'image">
            <Medias
                :defaultTypeFilterSlug="['image']"
                :selectOnly="true"
                :draggable-asset="false"
                @select-media="updateMessageBackground"
            ></Medias>
        </RightPanel>
    </div>
</template>
<script>

import { mapState } from 'vuex'

import Draggable from 'vuedraggable'

import Message from '@/components/Games/Message'
import RightPanel from "@/components/RightPanel"
import Medias from "@/views/Medias"

export default {
    name: 'Messages',
    components: {
        Message,
        Draggable,
        RightPanel,
        Medias
    },
    props: {
		game: {
			type: Object,
			required: true,
			default: null
		}
    },
    data() {
        return {
            selectedMessage: null,
            selectedMessageLastEvent: null,
            dragOptions: {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            }
        }
    },
    computed: {
        ...mapState('Assets', {
            assets(state) {
                if (!state.assets || state.assets.length <= 0) return {}

                return state.assets.reduce((dict, asset) => {
                    dict[asset.id] = asset

                    return dict
                }, {})
            }
        }),
        ...mapState("Games", {
            types(state){
                if (!state.question_type_list || state.question_type_list.length <= 0)
                    return null

                return state.question_type_list.reduce((dict, type) => {
                    dict[type.slug] = type
                    return dict
                }, {})
            }
        }),
        messages: {
            get() {
                if (!this.game)
                    return []

                return JSON.parse(JSON.stringify(this.game.questions));
            },
            set(value){
                this.$emit('update-all-questions', value)
            }
        },
        backgroundImage() {
            if (!this.game.medias || this.game.medias.length <= 0 || !this.game.medias[0].media_id || !this.assets[this.game.medias[0].media_id])
                return null

            return this.assets[this.game.medias[0].media_id].url
        },
        canHadMessage() {
            return (this.game.type.slug != 'message_phone')
        }
    },
    watch: {
        game: {
            handler(game) {
                // Automaticly add one message if none provided
                if (game && game.questions.length <= 0) {
                    this.addMessage()
                }
            },
            immediate: true
        }
    },
    methods: {
        getMessageIndexLabel(index) {
            let label = ""

            const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
            for (var i = 0; i < Math.ceil((index + 1) / letters.length); i++) {
                label += letters[index % letters.length]
            }

            return label
        },
        addMessage() {
            const defaultTypeId = (this.types && this.types['message_visio'] && this.types['message_visio'].id)

            // Add a question to represent a message
            const question = {
                text: '',
                correction: '',
                help: '',
                media_id: null,
                answers: [],
                question_type_id: defaultTypeId
            }

            this.$emit('add-questions', [ question ])
        },
        selectMessage(event, index) {
            this.selectedMessage = index
            this.selectedMessageLastEvent = event
        },
        unselectMessage() {
            if (event.buttons > 0)
                return

            this.selectedMessage = null
        },
        moveMessage(event, index, message) {
            if (this.selectedMessage != index || event.buttons <= 0)
                return

            // Compute delta since last event
            const parent = event.currentTarget.parentElement
            
            const deltaX = (event.clientX - this.selectedMessageLastEvent.clientX) / parent.clientWidth
            const deltaY = (event.clientY - this.selectedMessageLastEvent.clientY) / parent.clientHeight

            // Check if the new coordinates are out of container
            const maxTop = (parent.clientHeight - event.currentTarget.clientHeight) / parent.clientHeight
            const maxLeft = (parent.clientWidth - event.currentTarget.clientWidth) / parent.clientWidth

            let column = (message.column + deltaX)
            let line = (message.line + deltaY)

            if (column < 0) {
                column = 0
            } else if (column > maxLeft) {
                column = maxLeft
            }

            if (line < 0) {
                line = 0
            } else if (line > maxTop) {
                line = maxTop
            }

            // Update message coordinates
            this.$emit('update-question', index, {
                column,
                line
            })

            this.selectedMessageLastEvent = event
        },
        openMessageBackgroundPanel() {
            this.$refs['rightPanel'].show()
        },
        updateMessageBackground(asset){
            if (asset.type.slug != 'image')
                return

            this.$emit('update-game-media', asset.id)
            this.$refs['rightPanel'].hide()
        },
        updateQuestion(i, data){
            this.$emit('update-question', i, data)
        },
        deleteQuestion(i){
            this.$emit('delete-questions', [i])
        }
    }
}
</script>

<style lang="scss">
</style>