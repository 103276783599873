<template>
	<div class="dashboard-chart dashboard-chart-line">
		<!-- Chart -->
		<div class="dashboard-chart-container">
			<chartist
			ratio="ct-double-octave"
			type="Line"
			:data="chartData"
			:options="chartOptions"
			:event-handlers="chartEvents" />

			<span
			class="dashboard-chart-tooltip"
			:class="{ 'opacity-0': !showTooltip }"
			:style="{ left: tooltipPosition.x + 'px', top: tooltipPosition.y + 'px' }"
			>{{tooltipValue}}</span>
		</div>
	</div>
</template>

<script>
	import utils from "@/utils/trackingReportTemplate"

	export default {
		name: 'LineChart',
		props: {
			data: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				chartOptions: {
					low: 0,
					chartPadding: {
						top: 30,
						right: 14,
						bottom: 5,
						left: 14
					},
					lineSmooth: false,
					axisX: {
						labelInterpolationFnc(label) {
							return label.text
						}
					},
					axisY: {
						offset: 0,
						showLabel: false,
						onlyInteger: true,
						labelInterpolationFnc(label) {
							// Only show the first grid line
							return (label > 0 ? null : label)
						}
					}
				},
				chartEvents: [{
					event: 'draw',
					fn: this.onChartDraw
				}],
				tooltipValue: 0,
				tooltipPosition: { x: 0, y: 0 },
				showTooltip: false
			}
		},
		computed: {
			chartData() {
				const monthsMap = ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUN', 'JUL', 'AOU', 'SEP', 'OCT', 'NOV', 'DEC']

				const dataKey = Object.keys(this.data)
				
				let toDateFn = null
				let toStringFn = null

				// Check if data are split per month or year
				if (dataKey[0] && utils.dateFromMonthId(dataKey[0])) {
					toDateFn = utils.dateFromMonthId
					toStringFn = ((date) => monthsMap[date.getMonth()])
				} else {
					toDateFn = utils.dateFromYearId
					toStringFn = ((date) => date.getFullYear())
				}

				const formattedData = dataKey.reduce((formattedData, key) => {
					const date = toDateFn(key)

					formattedData.labels.push({
						date,
						text: toStringFn(date)
					})
					formattedData.series[0].push({
						x: date,
						y: this.data[key]
					})

					return formattedData
				}, {
					labels: [],
					series: [[]]
				})

				// Sort data and label by ascending date
				formattedData.labels.sort((label1, label2) => (label1.date.getTime() > label2.date.getTime() ? 1 : -1))
				formattedData.series[0].sort((data1, data2) => (data1.x.getTime() > data2.x.getTime() ? 1 : -1))

				return formattedData
			},
			chartMaxValue() {
				if (!this.chartData)
					return -1

				return this.chartData.series[0].reduce((maxValue, value) => {
					if (value.y > maxValue) {
						return value.y
					}

					return maxValue
				}, -1)
			}
		},
		methods: {
			onChartDraw(data) {
				switch (data.type) {
					case 'grid':{
						if (data.axis.units.dir == 'horizontal') {
							// Update grid line height to stop at current value height
							const node = data.element.getNode()
							const chartHeight = data.axis.chartRect.height()

							const currentValue = this.chartData.series[0][data.index].y

							node.setAttribute('y1', data.y2 - (chartHeight * (currentValue / (this.chartMaxValue || 1))))
						} else {
							// Update grid line width to expand to chart offset
							const node = data.element.getNode()
							const leftPadding = data.axis.chartRect.padding.left
							const rightPadding = data.axis.chartRect.padding.right

							node.setAttribute('x1', data.x1 - leftPadding + 2)
							node.setAttribute('x2', data.x2 + rightPadding - 2)
						}
						break;
					}

					case 'point': {
						// Add event listeners for the tooltip display logic
						const serieData = data.value
						const node = data.element.getNode()

						node.addEventListener('mouseover', () => {
							this.onPointMouseOver(serieData, data.x, data.y)
						})
						node.addEventListener('mouseout', () => {
							this.onPointMouseOut()
						})
						break;
					}
				}
			},
			onPointMouseOver(serieData, x, y) {
				this.tooltipValue = serieData.y
				this.tooltipPosition = { x, y }
				this.showTooltip = true
			},
			onPointMouseOut() {
				this.showTooltip = false
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-chart-line {
		.ct-series {
			.ct-line {
				@apply stroke-dashboard-blue-dark;
				stroke-width: 3;
			}

			.ct-point {
				stroke: transparent;
				stroke-width: 100;
			}
		}

		.ct-label.ct-horizontal {
			transform: translateX(-2ch) translateY(0.5rem);
		}

		.ct-grid.ct-horizontal {
			@apply stroke-dashboard-gray-lighter;
		}

		.dashboard-chart-container {
			@apply relative;
		}

		.dashboard-chart-tooltip {
			@apply absolute;
			transform: translateX(-50%) translateY(-100%) translateY(-0.5rem);
		}
	}
</style>