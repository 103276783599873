<template>
    <div class="flex flex-col justify-start relative">
        <div class="font-semibold flex flex-col md:flex-row items-start md:items-center justify-between mb-5 flex-wrap">
            <div class="search flex flex-row items-center w-1/3 pr-2">
                <label class="mb-0" for="search">
                    <b-icon-search class="h2 my-3 mx-2"></b-icon-search>
                </label>
                <input v-model="searchStr" type="text" id="search" class="form-control w-4/5" :placeholder="searchText" ref="search" spellcheck="false">
            </div>
            <div id="filters" class="filters flex w-1/3 justify-around">
                <div class="w-full mx-1" v-for="(filter, i) in filterInfo.select" :key="i">
                    <v-select class="style-chooser text-xs" :multiple="filter.multiple" :placeholder="criteria.select[i].label" :label="filter.label" :options="filter.data" v-model="criteria.select[i].filter"></v-select>
                </div>
            </div>
            <slot name="buttons" :items="getItemsList" >
            </slot>
            <slot name="fab"></slot>
        </div>
        <b-table
            class="table_list min-w-0 bootstrap"
            responsive="lg"
            :current-page="page"
            :per-page="pageSize"
            borderless
            :busy="tableBusy"
            :items="getItemsList"
            :fields="fields"
            @filtered="onFiltered"
            :filter="criteria"
            :filter-function="filterTable"
            :tbody-tr-class="{ 'cursor-pointer': true, 'edit-item-hover': !readOnly }"
            :sort-by="defaultSortBy"
            :sort-desc="defaultSortDesc"
            @row-clicked="selectItem"
        >
            <!-- https://bootstrap-vue.js.org/docs/components/table/#tables -->
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-1"></b-spinner>
                <strong>Chargement...</strong>
                </div>
            </template>

            <template v-for="slotName in cellSlots" v-slot:[slotName]="scope">
                <slot :name="slotName" v-bind="scope"></slot>
            </template>
            
            <template v-slot:row-details="row">
                <slot name="row-details" :row="row"></slot>
            </template>
            <hr>
        </b-table>

        <b-pagination-nav
            class="m-auto style-paginate"
            v-model="page"
            :link-gen="linkGen"
            :number-of-pages="numPage"
            use-router
        >
        </b-pagination-nav>

        <b-modal ref="delete-modal" class="bootstrap" centered hide-footer id="delete-modal" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
            </div>
        </b-modal>
        <div class="w-full h-full absolute bg-white bottom-0" v-bind:class="[selectedFilter !== -1 ? 'block' : 'hidden']">
            <div class="w-full h-screen mx-1 flex flex-col items-center justify-around" v-for="(filter, i) in filterInfo.select" :key="i" v-bind:class="[i == selectedFilter ? 'block' : 'hidden']">
                <v-select class="style-chooser text-xl my-5 mx-5 w-full" :multiple="filter.multiple" :placeholder="criteria.select[i].label" :label="filter.label" :options="filter.data" v-model="criteria.select[i].filter"></v-select>
                <button @click="selectFilter(i)" class="twn-button" type="button">Valider</button>
            </div>
        </div>
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import { FC_DELETE_ITEM } from '@/graphql/list'
import router from '@/router/index'
import vClickOutside from 'v-click-outside'

//this.listing gets the table name to list
//this.fields gets the table fields to show, maybe add a 'db-name' variable to get the actual column name
export default {
    name: 'cooc-list',
    directives: {
      clickOutside: vClickOutside.directive
    },
    data() {
        return {
            page: 1,
            filterInfo: {
                select: []
            },
            criteria: {
                select: []
            },
            tableBusy: false,
            searchStr: '',
            selectedID:'',
            totalItems: 0,
            selectedFilter: -1,
            appliedFilters: [],
            mobile_edit_menu: -1,
            filterApplied: false,
        }
    },
    props: {
        pageSize: {
            type: Number,
            required: false,
            default: 30
        },
        previousPage: {
            type: Number,
            required: false,
            default: null
        },
        listing: {
            type: String,
            required: false,
            default: null,
        },
        fields: {
            type: Array,
            required: false,
            default: null,
        },
        filters: {
            type: Array,
            required: false,
            default: null,
        },
        defaultSortBy: {
            type: String,
            required: false,
            default: '',
        },
        defaultSortDesc: {
            type: Boolean,
            required: false,
            default: false,
        },
        searchText: {
            type: String,
            required: false,
            default: null,
        },
        editRouteName: {
            type: String,
            required: false,
            default: null,
        },
        customRouteParams: {
            type: Function,
            required: false,
            default: null,
        },
        deleteItemOverride: {
            type: Function,
            required: false,
            default: null,
        },
        orderBy: {
            type: String,
            required: false,
            default: null
        },
        modifyButtonText: {
            type: String,
            default: 'Modifier'
        },
        withDeleteButton: {
            type: Boolean,
            default: true
        },
        readOnly:{
            type: Boolean,
            default: false,
            required: false
        }
    },
    computed: {
        ...mapGetters('List', {
            getRawItemsList: 'getItemsList'
        }),
        getItemsList() {
            return this.getRawItemsList.map(item => ({
                ...item,
                _showDetails: false
            }))
        },
        cellSlots() {
            return Object.keys(this.$scopedSlots)
                .filter(slotName => slotName.substring(0, 4) === 'cell')
        },
        numPage(){
            if (this.totalItems && this.pageSize){
                return Math.ceil(this.totalItems / this.pageSize)
            }
            return 1
        }
    },
    watch: {
        listing: {
            async handler() {
                await this.getList();
                if (this.previousPage){
                    this.page = this.previousPage
                    this.$router.push({
                        name: this.$router.currentRoute.name,
                        query:{
                            page: this.page
                        }
                    })
                }

                this.$refs['search']?.focus()

                // todo: common/cleaner system
                document.title = process.env.VUE_APP_PAGE_TITLE
            },
            immediate: true
        }
    },
    methods: {
        ...mapActions('List', ['listItems', 'clearListItems']),
        linkGen(page){
            return page === 1 ? '?' : '?page=' + page
        },
        async getList(){
            await this.clearListItems(data);

            this.totalItems = 0;
            this.page = 1
            
            if (this.fields === null) {
                return
            }

            let data = {
                table: this.listing,
                fields: this.fields,
                orderBy: this.orderBy
            }
            await this.listItems(data);
            this.totalItems = this.getItemsList.length;
            this.createFilters();
        },
        createFilters(){
            this.filterInfo.select = [];
            if (this.filters && Array.isArray(this.filters)){
                this.filters.forEach(filter => {
                    const filterField = this.fields.find((field) => field.key === filter.key)
                    const defaultFormatter = (value) => value
                    const filterFormatter = (filterField && filterField.formatter) || defaultFormatter

                    let tmp = [];
                    this.appliedFilters.push(false);
                    this.getItemsList.forEach(item => {
                        if (Array.isArray(item[filter.key])){
                            item[filter.key].forEach(i => tmp.push(filterFormatter(i)));
                        }
                        else if (item[filter.key] !== undefined && item[filter.key] !== null){
                            tmp.push(filterFormatter(item[filter.key].toString()));
                        }
                    })
                    let filterOptions = Array.from(new Set(tmp)).filter(a => a);
                    this.filterInfo.select.push({
                        label: filter.key,
                        data:filterOptions,
                        multiple: filter.multiple
                    })
                    this.criteria.select.push({key:filter.key, label: filter.label});
                })
            }
        },
        selectItem(item, index, event) {
            this.$emit('select-item', item)
            if (!this.readOnly){
                this.editItem(item, index, event)
            }
        },
        editItem(item, index, event){
            let params = {
                itemID: item.id,
                previousPage: this.page
            }

            if (this.customRouteParams){
                params = { ...params, ...this.customRouteParams(item)}
            }

            let name = `${this.listing}-edit`
            if (this.editRouteName && this.editRouteName != ''){
                name = this.editRouteName
            }

            if (event.metaKey || event.ctrlKey){
                let routeData = router.resolve({
                    name,
                    params
                })
                window.open(routeData.href, '_blank');
            } else {
                router.push({
                    name,
                    params
                })
            }
        },
        async deleteItem(){
            if (this.deleteItemOverride){
                await this.deleteItemOverride(this.selectedID);
            } else {
                await this.$apollo.query({
                    query: FC_DELETE_ITEM(this.listing),
                    variables: {
                        id: this.selectedID
                    }
                })

                this.$store.dispatch('Logs/ContentLog', {
                    id: this.selectedID,
                    action: 'content_delete'
                }, {root:true})
            }
            this.selectedID = "";
            this.$refs['delete-modal'].hide();
            this.getList();
        },
        showDeleteModal(id){
            this.selectedID = id;
            this.$refs['delete-modal'].show();
        },
        filterTable(row, filter) {
            let valid = true;
            let i = 0;

            this.tableBusy = true;
            filter.select.forEach(f => {
                if (f.filter){
                    i++;
                    for (let j = 0; j < filter.select.length; j++) {
                        if (filter.select[j].filter) {
                            const filterField = this.fields.find((field) => field.key === filter.select[j].key)
                            const defaultFormatter = (value) => value.toString()
                            const filterFormatter = (filterField && filterField.formatter) || defaultFormatter

                            if (row[filter.select[j].key] === null){
                                valid = false;
                            }
                            else if (Array.isArray(filter.select[j].filter)){
                                let tmpValid = 0;
                                filter.select[j].filter.forEach(ft => {
                                    if (Array.isArray(row[filter.select[j].key]) && row[filter.select[j].key].includes(ft)){
                                        tmpValid++;
                                    } else if (!Array.isArray(row[filter.select[j].key]) && row[filter.select[j].key] === ft){
                                        tmpValid++;
                                    }
                                })
                                if (tmpValid != filter.select[j].filter.length){
                                    valid = false;
                                }
                                else {
                                    valid = true;
                                }
                            }
                            else if (filter.select[j].filter && filterFormatter(row[filter.select[j].key]) !== filter.select[j].filter){
                                valid = false;
                            }
                        }
                    }
                }
            })
            if (i == 0)
                valid = true;

            if (this.searchStr !== '' && valid == true){
                valid = false;
                for (const field in row) {
                    if (row[field] && row[field].toString().toLowerCase().includes(this.searchStr.toLowerCase())) {
                        valid = true;
                    }
                }
            }
            this.tableBusy = false;
            return valid;
        },
        onFiltered(items, length){
            this.page = 1;
            this.totalItems = length;
        },
        selectFilter(){
            this.selectedFilter = -1;
        },
        clearFilters(){
            this.criteria.select.forEach((c, i) => {
                if (c.filter){
                    //set to null to apply changes to filtering fct
                    c.filter = null;
                    delete c.filter;
                }
                this.appliedFilters[i] = false;
            });
            this.filterApplied = false;
        }
    }
}
</script>

<style lang="scss">
.style-chooser{
    .vs__clear{
        @apply p-1;
    }
    .vs__dropdown-toggle{
        border: none;
        @apply cursor-pointer;
    }
}

.style-paginate{
    .page-link, .page-link:hover{
        @apply text-black;
    }
    .page-item.active .page-link{
        @apply bg-line-left border-principal-list-item;
    }

    .page-item.disabled .page-link{
        @apply opacity-50;
    }

}

#search.form-control{
    @apply text-principal-gray;
    border: none;
}

.fab i {
    color: $textLight !important;
}

.twn-fab > .fab-item-title{
    @apply rounded-full font-principal-medium;
    border-radius: 9999px !important;
    color: $textLight;
    background-color: white;
    box-shadow: $cardShadow !important;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem !important;
}

.item { 
    @apply rounded-full;
}

.table_list{
    @apply mt-5 rounded-lg py-5 px-8;
    box-shadow: $cardShadow;
    thead{
        th{
            padding-bottom: 2rem !important;
            @apply uppercase font-principal-bold px-0 text-sm py-10;
        }
        th:not(:hover){
            background-image: none !important;
        }
    }
    tbody, thead{
        th, td{
            @apply py-5 px-2;
        }
    }
    tbody{
        @apply text-principal-list-item;
        tr {
            &.edit-item-hover{
                border-radius: 3px;
            }
            &.edit-item-hover:hover{
                box-shadow: inset 0px 0px 7px -2px #020202;
            }
            &:not(:last-child){
                border-bottom: 1px solid;
                @apply border-line-top;
            }
            &:not(:hover) .show-actions{
                opacity: 0;
            }
        }
    }
}
</style>