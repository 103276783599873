<template>
	<div class="w-full flex">
		<!-- Common fields and metas -->
		<div class="left-block flex-grow w-2/3 my-4 border-gray-light border-r-1">
			<div class="w-1/5 pr-4">
				<label for="module-name">Identifiant</label>
				<input type="text" id="module-name" class="d-block twn-input w-full" v-model="identifier">
			</div>

			<div class="w-3/5 px-4">
				<label for="module-title">Titre</label>
				<input type="text" id="module-title" class="d-block twn-input w-full" v-model="title">
			</div>

			<div class="w-1/5 px-4">
				<label for="module-title">Durée (min)</label>
				<input type="text" id="module-title" class="d-block twn-input w-full" :value="getMetaValue('duration', 0)" @input="setMetaValue('duration', $event.target.value)">
			</div>

			<div class="w-full pr-4 mt-4">
				<label for="module-description">Texte de présentation</label>
				<textarea
					id="module-description"
					class="w-full twn-input"
					rows="3"
					:value="getMetaValue('description')"
					@input="setMetaValue('description', $event.target.value)"
				/>
			</div>
		</div>

		<!-- Documents -->
		<div class="w-1/3 my-4 pl-4">
			<label for="module-documents">Documentation</label>

			<!-- List -->
			<div class="document" v-for="(medium, index) in media" :key="medium.medium_id">
				<span>{{getMediumLabel(mediaData[medium.medium_id])}}</span>
				<button @click="removeDocument(index)"><b-icon-x></b-icon-x></button>
			</div>

			<!-- Add input -->
			<v-select
			id="module-documents"
			placeholder="Ajouter un document"
			:options="documentList"
			:getOptionLabel="getMediumLabel"
			:reduce="medium => medium.id"
			v-model="selectedDocument"
			@input="addDocument($event)" />
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	export default {
		name: 'SimpleModuleForm',
		props: {
			module: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				selectedDocument: null
			}
		},
		computed: {
			...mapState({
				narrativeList: state => state.Narratives.list,
				documentMedia: state => state.Media.list.filter((doc) => doc.type.slug == 'pdf'),
				metaTypes: state => {
					return state.Utils.metaTypeList.reduce((dict, meta) => {
						dict[meta.slug] = meta
						return dict
					}, {})
				}
			}),
			identifier: {
				get() {
					return this.module.identifier
				},
				set(value) {
					this.$emit('update-module', {
						identifier: value
					})
				}
			},
			title: {
				get() {
					return this.module.title
				},
				set(value) {
					this.$emit('update-module', {
						title: value
					})
				}
			},
			metas() {
				return [...this.module.metas]
			},
			media() {
				return [...this.module.media]
			},
			mediaData() {
				return this.documentMedia.reduce((data, medium) => {
					data[medium.id] = medium

					return data
				}, {})
			},
			documentList() {
				const ids = this.media.map(medium => medium.medium_id)

				return this.documentMedia.filter(doc => ids.indexOf(doc.id) < 0)
			}
		},
		methods: {
			getMediumLabel(medium) {
				if (!medium)
					return '~ Élément supprimé ~'

				return [medium.identifier, medium.title, medium.name].filter(str => str || false).join(' - ') || '~ Élément sans titre ou supprimé ~'
			},
			getMeta(slug) {
				for (var i = 0; i < this.metas.length; i++) {
					if (this.metas[i].meta_type_id == this.metaTypes[slug].id) {
						return { meta: this.metas[i], index: i }
					}
				}
				
				return { meta: null, index: -1 }
			},
			getMetaValue(slug, defaultValue = null) {
				const { meta } = this.getMeta(slug)

				return ((meta && meta.value) || defaultValue)
			},
			setMetaValue(slug, value) {
				let { meta, index } = this.getMeta(slug)

				if (meta) {
					if (value != null) {
						meta.value = value
					} else {
						this.metas.splice(index, 1)
					}
				} else if (value) {
					this.metas.push({
						value,
						meta_type_id: this.metaTypes[slug].id
					})
				}

				this.$emit('update-module', {
					metas: this.metas
				})
			},
			addDocument(medium_id) {
				if (!medium_id)
					return

				this.media.push({
					medium_id,
					order: this.media.length
				})

				this.$emit('update-module', {
					media: this.media
				})

				this.$nextTick(() => this.selectedDocument = null)
			},
			removeDocument(index) {
				if (this.media.length <= 0)
					return

				// Remove document
				this.media.splice(index, 1)
				
				// Update order fields
				this.media.forEach((sequence, i) => {
					if (i >= index){
						sequence.order--
					}
				})

				this.$emit('update-module', {
					media: this.media
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.left-block > div {
		@apply inline-block align-top;
	}

	.document {
		@apply w-full flex bg-gray-background px-3 py-1 mb-2;

		span {
			@apply flex-grow;
		}

		button {
			@apply text-red-700;
		}
	}
</style>