import utils from "@/utils/trackingReportTemplate"

import userFieldsValues from "@/constants/userFieldsValues"

function hasReceivedOneOf(user, mailTypes) {
	for (var i = 0; i < mailTypes.length; i++) {
		if (user.mailingLogs[mailTypes[i]]) {
			return true
		}
	}

	return false
}

export default function userMailingDashboardData(data) {
	// Get user grouped by job and sort job by display order
	const usersByJob = utils.sortKeys(utils.groupBy(data.users, 'job'), userFieldsValues.job.map(job => job.value))

	let mailingByJob = utils.groupMap(usersByJob, (users) => {
		const userCount = users.length

		return {
			noLogin: (utils.countMatch(users, user => hasReceivedOneOf(user, ['reminder-no-login', 'reminder-no-login-active'])) / userCount),
			notFinishedAfterOneDay: (utils.countMatch(users, user => hasReceivedOneOf(user, ['reminder-not-finished-day'])) / userCount),
			notFinishedAfterOneMonth: (utils.countMatch(users, user => hasReceivedOneOf(user, ['reminder-not-finished-month'])) / userCount)
		}
	})

	const userCount = data.users.length
	
	return {
		noLogin: (utils.countMatch(data.users, user => hasReceivedOneOf(user, ['reminder-no-login', 'reminder-no-login-active'])) / userCount),
		notFinishedAfterOneDay: (utils.countMatch(data.users, user => hasReceivedOneOf(user, ['reminder-not-finished-day'])) / userCount),
		notFinishedAfterOneMonth: (utils.countMatch(data.users, user => hasReceivedOneOf(user, ['reminder-not-finished-month'])) / userCount),
		byJob: mailingByJob
	}
}