<template>
	<div class="flex flex-col">
		<label class="mb-1" :for="`slide-block-carousel-${uuid}`">
			Carrousel de médias
		</label>

		<!-- Media list -->
		<div
			class="flex items-center"
			v-for="(medium, index) in mediaList"
			:key="index"
		>
			<div
				v-if="mediaList.length > 1"
				class="flex flex-col"
			>
				<button
					class="w-12 h-6 text-black opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed"
					:disabled="(index === 0)"
					@click="moveMediumTo(index, index - 1)"
				>
					<b-icon-caret-up-fill font-scale="1.5" />
				</button>
				<button
					class="w-12 h-6 text-black opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed"
					:disabled="(index === (mediaList.length - 1))"
					@click="moveMediumTo(index, index + 1)"
				>
					<b-icon-caret-down-fill font-scale="1.5" />
				</button>
			</div>
			<div class="w-12 h-8 mr-2">
				<img
					v-if="medium.media_id && assetsById[medium.media_id]"
					class="max-h-full m-auto"
					:class="{ 'py-2': !assetsById[medium.media_id].thumbnail_url }"
					:src="assetsById[medium.media_id].thumbnail_url || iconForType[assetsById[medium.media_id].type.slug]"
				>
				<p
					v-else
					class="w-12 text-center"
				>
					-
				</p>
			</div>
			<v-select
				:id="`slide-block-carousel-${uuid}`"
				class="flex-grow"
				placeholder="Rechercher un média..."
				:options="assets"
				:getOptionLabel="getOptionLabel"
				:reduce="asset => asset.id"
				:value="medium.media_id"
				@input="updateMediumId(index, $event)"
			/>
			<button
				v-if="mediaList.length > 1"
				class="h-8 text-red-700 ml-2"
				@click.stop="removeMedium(index)"
			>
				<b-icon-x class="align-middle" font-scale="1.25" />
			</button>
		</div>

		<!-- Add media button -->
		<button
			class="plus rounded-lg h-10 px-4 flex justify-center items-center text-black mx-auto mt-4"
			@click="addMedium"
		>
			<span class="text-xl mr-1">+</span> Ajouter un média
		</button>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

	let uuid = 0

	const iconForType = {
		pdf: require('../../../assets/images/thumbnails/PDF_THUMBNAIL.svg'),
		video: require('../../../assets/images/thumbnails/VIDEO_THUMBNAIL.svg'),
		audio: require('../../../assets/images/thumbnails/AUDIO_THUMBNAIL.svg'),
	}

	export default {
		name: 'BlockSlideFormMedia',
		mixins: [
			dispatchStoreRequest,
		],
		props: {
			block: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				iconForType,
			}
		},
		computed: {
			...mapState('Assets', {
				assets: (state) => state.assets,
				assetsById(state) {
					if (!state.assets || state.assets.length <= 0)
						return {}

					return state.assets.reduce((dict, asset) => {
						dict[asset.id] = asset

						return dict
					}, {})
				},
			}),
			mediaList: {
				get() {
					return (this.block?.media || [])
				},
				set(media) {
					// Make sure order field is correct
					media.forEach((medium, index) => {
						medium.order = index
					})

					this.$emit('update', {
						media
					})
				},
			},
		},
		watch: {
			block: {
				handler(block) {
					// Automaticly add one slide if none provided
					if (block && block.media && block.media.length <= 0) {
						this.addMedium()
					}
				},
				immediate: true
			},
		},
		beforeCreate() {
			this.uuid = uuid.toString()
			uuid += 1
		},
		async created() {
			await this.dispatchStoreRequest('Assets/getAssetList')
		},
		methods: {
			getOptionLabel(option) {
				if (!option)
					return '~ Élément supprimé ~'

				return [option.identifier, option.title, option.name].filter(str => str || false).join(' - ') || '~ Élément sans titre ou supprimé ~'
			},
			addMedium() {
				// Add new medium
				this.mediaList = [...this.block.media, { media_id: null }]
			},
			removeMedium(index) {
				// Copy media list
				const media = [...this.block.media]

				// Remove medium
				media.splice(index, 1)
				
				this.mediaList = media
			},
			updateMediumId(index, medium_id) {
				// Copy media list
				const media = [...this.block.media]

				// Update medium id
				media[index].media_id = medium_id
				
				this.mediaList = media
			},
			moveMediumTo(index, newIndex) {
				// Copy media list
				const media = [...this.block.media]

				media.splice(newIndex, 0, media.splice(index, 1)[0])

				this.mediaList = media
			},
		},
	}
</script>