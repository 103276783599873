<template>
	<div class="w-full question flex flex-row relative justify-center items-center">
		<!-- Header/Handle -->
		<div class="handle self-stretch w-10 flex justify-center items-center cursor-move">
			<div class="rotate-icon">
				<b-icon-justify></b-icon-justify>
			</div>
		</div>

		<!-- Index -->
		<div
			v-if="identifier"
			class="handle cursor-pointer index bg-principal-selected w-10 self-stretch flex justify-center items-center text-xs"
		>
			<div>{{ identifier }}</div>
		</div>

		<!-- Content -->
		<div class="flex flex-col items-center flex-1 p-10">
			<!-- Form -->
			<div class="content w-full flex">
				<div class="flex-grow">
					<!-- Text -->
					<div class="input-field w-full mt-4">
						<b-form-input
						placeholder="Consigne"
						type="text"
						v-model="instruction" />
					</div>
				</div>
			</div>

			<!-- Answers -->
			<div class="answers w-full flex flex-row justify-center items-center mt-5">
				<!-- Answer text -->
				<div class="box-answers w-full" v-bind:class="[answers.length == 0 ? 'w-0' : '']">
					<draggable v-model="answers" handle=".answer-handle">
						<div
							v-for="(answer, i) in answers"
							:key="i"
							class="w-full flex flex-row justify-center items-center"
						>
							<!-- Number -->
							<template>
								<div class="answer-handle cursor-pointer w-10 flex items-center justify-center">
									<div class="rotate-icon">
										<b-icon-justify></b-icon-justify>
									</div>
								</div>
								<div class="number answer-handle w-10 text-center self-stretch p-2 cursor-pointer">
									<div>{{ i + 1 }}</div>
								</div>
							</template>

							<!-- Input/Text -->
							<div class="input-field w-full flex justify-around items-center p-2">
								<b-form-textarea
									max-rows="6"
									class="h-full border-none w-1/2"
									@input="updateAnswerText($event, i)"
									type="text"
									placeholder="Question / relance"
									:value="answer.text"
									/>

									<v-select
									class="twn-select w-1/3 ml-auto mr-4"
									placeholder="Vidéo de réponse"
									:options="mediaList"
									:getOptionLabel="getOptionLabel"
									:reduce="medium => medium.id"
									:value="answer.media_id"
									@input="updateAnswerMedium($event, i)" />
							</div>
							<div
								class="bg-principal-selected cursor-pointer self-stretch flex items-center text-white justify-center w-10"
								@click="removeAnswer(i)"
								v-if="answers.length > 1"
							>
								<b-icon-x></b-icon-x>
							</div>
						</div>
					</draggable>
				</div>

				<!-- Right/Wrong answer switch -->
				<div v-if="enableCorrection" class="check-answers self-stretch flex flex-col justify-around items-center">
					<div
						v-for="(answer, i) in answers"
						:key="i"
						class="w-full mx-3 flex flex-row justify-center items-center"
					>
						<label class="switch">
							<input type="checkbox" :checked="answer.is_correct" @input="checkAnswer(i)" />
							<span class="slider round"></span>
						</label>
					</div>
				</div>

				<!-- New answer button -->
				<div
					:class="[answers.length == 0 ? 'w-full' : '']"
				>
					<div
						@click="addDefaultAnswer()"
						class="plus m-auto rounded-full w-10 h-10 flex justify-center items-center cursor-pointer"
					>
						+
					</div>
				</div>
			</div>
		</div>

		<!-- Footer -->
		<div
			@click="deleteQuestion"
			class="cursor-pointer deleteQuestion self-stretch w-10 flex justify-center items-center text-white"
		>
			<b-icon-x></b-icon-x>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	import Draggable from "vuedraggable"

	export default {
		name: 'Conversation',
		components: {
			Draggable
		},
		props: {
			identifier: {
				type: [String, Number],
				required: true
			},
			question: {
				type: Object,
				required: true
			},
			enableCorrection: {
				type: Boolean,
				required: true
			}
		},
		computed: {
			...mapState("Assets", {
				mediaList: (state) => state.assets.filter(medium => ['video'].indexOf(medium.type.slug) > -1)
			}),
			instruction: {
				get() {
					return this.question.text
				},
				set(value) {
					this.$emit("update-question", {
						text: value
					})
				}
			},
			answers: {
				get() {
					return this.question.answers
				},
				set(value) {
					this.$emit("update-question", {
						answers: value
					})
				}
			}
		},
		methods: {
			getOptionLabel(option) {
				if (!option)
					return '~ Élément supprimé ~'

				return [option.identifier, option.name, option.title].filter(str => str || false).join(' - ') || '~ Élément sans titre ou supprimé ~'
			},
			deleteQuestion() {
				this.$emit("delete-question")
			},
			updateAnswerText(input, i) {
				let newAnswers = [...this.answers]
				newAnswers[i].text = input
				this.answers = newAnswers
			},
			updateAnswerMedium(newValue, i) {
				let newAnswers = [...this.answers]
				newAnswers[i].media_id = newValue
				this.answers = newAnswers
			},
			addDefaultAnswer() {
				let newAnswers = [...this.answers]
				newAnswers.push({
					text: "",
					media_id: null,
					is_correct: false
				})
				this.$emit("update-question", {
					answers: newAnswers
				})
			},
			checkAnswer(i) {
				let correct = true
				let newAnswers = [...this.answers]
				if (newAnswers[i].is_correct) {
					correct = false
				}
				newAnswers[i].is_correct = correct
				this.answers = newAnswers
			},
			removeAnswer(i) {
				let newAnswers = [...this.answers]
				newAnswers.splice(i, 1)
				this.answers = newAnswers
			}
		}
	}
</script>

<style lang="scss">
	.question {
		background: white;
		box-shadow: $cardShadow;
		border-radius: 10px;

		&.removeState {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}

		color: $textLight;
		.index {
			background-color: rgba(220, 151, 153, 0.1);
		}

		.rotate-icon {
			transform: rotate(90deg);
		}

		.image-content {
			.image {
				img {
					max-height: 150px;
				}
			}
		}

		.deleteQuestion {
			@apply bg-principal-selected;
			border-radius: 0 10px 10px 0;
		}
	}
</style>
