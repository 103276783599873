// import extraChaptersData from "./extraChaptersData"
import extraUserData from "./extraUserData"

import activityDashboardData from "./activityDashboardData"
import mailingDashboardData from "./mailingDashboardData"
import progressionDashboardData from "./progressionDashboardData"

// One day in ms
const oneDay = (1 * 60 * 60 * 1000 * 24)

export default {
	types: {
		float: '#0.00',
		percentage: '0.00%',
		duration: {
			format: '[h]"h" mm"min"',
			transform: (time) => (time / oneDay)
		},
		date: {
			format: 'dd/MM/yyyy "à" HH"h"mm',
			transform: (time) => (time ? ((time / (86400 * 1000)) + 25569) : false) // Excel datetime value format
		}
	},
	remoteData: {
		user: {
			module: 'Logs',
			action: 'GetUserTrackingData',
			payload: {
				logTypes: ['login', 'logout', 'mail', 'activity', 'scenario', 'complete_scenario', 'course', 'module']
			}
		},
		courses: {
			module: 'Logs',
			action: 'GetCoursesTrackingData',
			groupByUniq: 'type.slug'
		}
	},
	extraData: {
		// Extra computed data for user
		user: extraUserData,
		// Dashboard data
		activityDashboardData,
		mailingDashboardData,
		progressionDashboardData
	},
	sheets: []
}