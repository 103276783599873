<template>
    <div class="scenario-detail flex flex-col flex-auto pb-12">
        <!-- Header -->
        <div class="pl-3 md:pl-10 flex-initial text-xs mb-4">
            <span>{{scenario.name}} {{(scenario.name && scenario.title ? '-' : '')}} {{scenario.title}}</span>
            <img
            class="ml-3 inline align-top cursor-pointer h-4"
            src="@/assets/images/SETTINGS.svg"
            @click="showSettingsPane()">
        </div>
        
        <!-- Node toolbar and buttons -->
        <div class="pl-3 md:pl-10 flex flex-row justify-between">
            <!-- <div class="dock pb-1 flex justify-start overflow-auto" ref="dock"></div> -->
            <button type="button" class="twn-button text-xs mb-2" @click="goBack">
				Retour
			</button>
            <div class="scenario-buttons pr-8">
                <button
                v-if="canPreviewScenario"
                @click="previewScenario"
                class="twn-button text-xs mb-2 mr-2"
                type="button"
                :disabled="hasPendingStoreRequest || !itemID">Aperçu</button>
                <button type="button" class="twn-button danger text-xs mb-2 mr-2" @click="showDeleteModal">Supprimer le scénario</button>
                <button
                @click="saveScenario(true)"
                class="twn-button text-xs mb-2 mr-2"
                type="button"
                :disabled="hasPendingStoreRequest || !itemID">Dupliquer</button>
                <button
                class="twn-button text-xs mb-2"
                type="button"
                :disabled="hasPendingStoreRequest"
                @click="saveScenario()">Enregistrer</button>
            </div>
        </div>
        <!-- NODE GAME LIST  -->
        <div class="node-list" >
            <draggable :value="nodes" @change="changeScenarioOrder" >
                <ScenarioGame v-for="(node, i) in nodes" :key="node.id" :node="node" :index="i" class="mb-4" @select-game="selectGame" @remove-game="removeGame"></ScenarioGame>        
            </draggable>
        </div>

        <div class="add-node flex w-1/2">
            <v-select class="text-xs w-1/2" @input="addNewGame" placeholder="Ajouter une activité" v-model="selectedGame"  :options="gameList" :getOptionLabel="getOptionLabel"></v-select>

            <v-select class="text-xs w-1/2 ml-4" @input="addNewNarrative" placeholder="Ajouter une présentation" v-model="selectedNarrative" :options="narrativeList" :getOptionLabel="getOptionLabel"></v-select>

            <v-select class="text-xs w-1/2 ml-4" @input="addNewVideo" placeholder="Ajouter une vidéo" v-model="selectedVideo" :options="videoList" :getOptionLabel="getOptionLabel"></v-select>
        </div>

        <!-- Right pane (settings, media/asset, ...) -->
        <RightPanel
        ref="rightPanel"
        :title="rightPanelTitle[currentPaneType]"
        @hide="didHideRightPanel">
            <!-- Items (Games, ...) List -->
            <keep-alive>
                <List
                v-if="currentPaneType == 'item'"
                :listing="itemListing"
                :fields="itemListFields"
                :filters="itemListFilters"
                :searchText="itemSearchText"
                :mobileFields="itemListFields"
                :read-only="true"
                @select-item="itemSelected">
                </List>
            </keep-alive>

            <!-- Settings Pane -->
            <ScenarioSettingsPanel
            :show="currentPaneType == 'settings'"
            :scenario="scenario"
            :location-list="[]"
            :entity-list="[]"
            :scenario-type-list="scenarioTypeList"
            :has-pending-request="hasPendingStoreRequest"
            @update-settings="updateAndSaveSettings" />
        </RightPanel>

        <b-modal ref="delete-modal-detail-scenario" class="bootstrap" centered hide-footer id="delete-modal-detail-scenario" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-detail-scenario')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    import { FC_DELETE_ITEM } from '@/graphql/list'
    
    import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"

    import List from '@/components/List'
    import ScenarioGame from '@/components/ScenarioGame'
    import RightPanel from '@/components/RightPanel'
    import ScenarioSettingsPanel from '@/views/Scenario/ScenarioSettingsPanel'

    import Draggable from "vuedraggable"

    export default {
        name: 'ScenarioDetail',
        props: {
            itemID: {
                type: String,
                required: false
            },
            previousPage: {
                type: Number,
                required: false
            }
        },
        components: {
            List,
            RightPanel,
            ScenarioSettingsPanel,
            Draggable,
            ScenarioGame
        },
        mixins: [ dispatchStoreRequest ],
        data() {
            return {
                // UI states
                currentPaneTarget: null,
                currentPaneType: false,
                rightPanelTitle: {
                    item: "Bibliotheque de mini-jeux",
                    settings: "Paramètres du scénario",
                },
                assetTypeFilter: null,
                // Game list config
                itemListing: 'activity',
                itemSearchText: 'Rechercher un mini-jeu',
                itemListFields: [
                    {
                        key: 'identifier',
                        label: 'ID',
                        sortable: true
                    },
                    {
                        key: 'title',
                        label: 'Nom',
                        sortable: true
                    },
                    {
                        key: 'type',
                        db: `type {
                            name
                        }`,
                        nested: 'name',
                        label: 'Type',
                        sortable: true,
                    }
                ],
                itemListFilters: [
                    {
                        key:'type',
                        label: 'Type'
                    }
                ],
                entityPaneType: null,
                // Local scenario states
                scenario: {
                    id: null,
                    name: "",
                    description: "",
                    title: "Nouveau scénario",
                    location: null,
                    type: null,
                    nodes: [],
                    contentLogs: [],
                    spawnPoint: null,
                    mapPoint: null,
                    presetScenario: null,
                    linkedScenario: null,
                    correction: null,
                    revisionDelay: 1,
                    scenes: [],
                },
                selectedGame: null,
                selectedNarrative: null,
                selectedVideo: null,
            }
        },
        computed: {
            canPreviewScenario() {
                return (process.env.VUE_APP_FRONT_URL ? true : false)
            },
            ...mapState({
                scenarioTypeList: state => state.Scenarios.type_list,
                triggerTypeList: state => state.Triggers.type_list,
                gameList: state => state.Games.list,
                narrativeList: state => state.Narratives.list,
                videoList: state => state.Media.list.filter((media) => {
                    return (media.type.slug === 'video')
                }),
            }),
            scenarioNodes() {
                return this.scenario.nodes || []
            },
            nodes() {
                if(!this.scenarioNodes.length ) return []
                const {triggered, nodes} = this.scenarioNodes.reduce((dict, node) => {
                    node.triggers.forEach(trig => {                        
                        dict.triggered[trig.triggered_node_id] = true
                    });
                    dict.nodes[node.id] = node
                    return dict
                }, {triggered: {}, nodes : {}})
                // find first node 
                let currentNode = this.scenarioNodes.find(node => !triggered[node.id] && (node.triggers.length > 0 || this.scenarioNodes.length == 1))

                // Sanity check for weird case
                if (!currentNode && this.scenarioNodes.length > 0) {
                    currentNode = this.scenarioNodes[0]
                }

                if(!currentNode) return []

                let orderedNodes = [currentNode]

                while (currentNode.triggers[0]?.triggered_node_id && orderedNodes.length < this.scenarioNodes.length) {
                    currentNode = nodes[currentNode.triggers[0]?.triggered_node_id]
                    orderedNodes.push(currentNode)                    
                }
                return orderedNodes
            }
        },
        watch: {
            itemID: {
                async handler(val) {
                    // Load current scenario data, if needed
                    if (val) {
                        // todo: handle invalid uuid response
                        await this.dispatchStoreRequest('Scenarios/getByID', val)

                        this.updateLocalScenarioData()

                        // todo: common/cleaner system
                        document.title = process.env.VUE_APP_PAGE_TITLE + ' - ' + [this.scenario.name, this.scenario.title].join(' - ')
                    }
                },
                immediate: true
            },
        },
        async mounted() {
            // Load common data types
            await this.dispatchStoreRequest('Scenarios/getTypeList')
            await this.dispatchStoreRequest('Nodes/getTypeList')
            await this.dispatchStoreRequest('Triggers/getTypeList')
            await this.dispatchStoreRequest('Games/getTypeList')
            await this.dispatchStoreRequest('Games/getList')
            await this.dispatchStoreRequest('Narratives/getList')
            await this.dispatchStoreRequest('Media/getTypeList')
            await this.dispatchStoreRequest('Media/getList')

            // Todo: load when each time ? (if changed in other tab ?)
            // Load node content data list if needed
            for (var i = 0; i < this.$store.state.Nodes.type_list.length; i++) {
                const type = this.$store.state.Nodes.type_list[i]

                switch (type.slug) {
                    case 'game':
                        await this.dispatchStoreRequest('Games/getTypeList')
                        await this.dispatchStoreRequest('Games/getList')
                        break

                    // case 'proposal': todo
                    case 'simple_proposal':
                        await this.dispatchStoreRequest('Proposals/getList')
                        break

                    case 'video_part':
                        await this.dispatchStoreRequest('VideoParts/getList')
                        break

                    case 'modal':
                        await this.dispatchStoreRequest('Modals/getList')
                        break

                    case 'sms':
                    case 'audio_message':
                        if (this.messageList.length <= 0 || this.characterList.length <= 0) {
                            await this.dispatchStoreRequest('Messages/getList')
                            await this.dispatchStoreRequest('Characters/getList')
                        }
                        break

                    case 'embed':
                        if (this.embedList.length <= 0) {
                            await this.dispatchStoreRequest('Embeds/getList')
                        }
                        break
                    
                }
            }

            this.updateLocalScenarioData()

            // Game nodes events
            this.$el.addEventListener('select-item', this.showItemPane)
            this.$el.addEventListener('item-selected', this.hideRightPanel)

            // Game nodes specific event
            this.$el.addEventListener('select-item-game', this.showGamePanel)

            // Show settings pane by default for new scenario
            if (!this.itemID) {
                this.showSettingsPane()
            }
        },
        beforeDestroy() {
            this.$el.removeEventListener('select-item', this.showItemPane)
            this.$el.removeEventListener('item-selected', this.hideRightPanel)
            this.$el.removeEventListener('select-item-game', this.showGamePanel)
        },
        methods: {
            getOptionLabel(option) {
                if (!option)
                    return '~ Élément supprimé ~'

                return [option.identifier, option.name, option.title].filter(str => str || false).join(' - ') || '~ Élément sans titre ou supprimé ~'
            },
            showDeleteModal(){
                this.$refs['delete-modal-detail-scenario'].show();
            },
            async deleteItem(){
                await this.$apollo.query({
                    query: FC_DELETE_ITEM("scenario"),
                    variables: {
                        id: this.itemID
                    }
                })
                this.$store.dispatch('Logs/ContentLog', {
                    id: this.itemID,
                    action: 'content_delete'
                }, {root:true})
                this.$refs['delete-modal-detail-scenario'].hide();
                this.$router.push({
                    name: 'scenario-list'
                })
            },
            showItemPane() {
                // if (this.currentPaneTarget)
                //     this.currentPaneTarget.dispatchEvent(new CustomEvent('set-selected', { bubbles: true, composed: true, detail: false }))

                // this.currentPaneTarget = event.target
                // this.currentPaneTarget.dispatchEvent(new CustomEvent('set-selected', { bubbles: true, composed: true, detail: true }))

                this.currentPaneType = "item"
                this.$refs['rightPanel'].show()
            },
            showSettingsPane() {
                if (this.currentPaneType == 'settings')
                    return
                
                // Show settings pane
                this.currentPaneType = "settings"
                this.$refs['rightPanel'].show()
            },
            didHideRightPanel() {
                if (this.currentPaneTarget)
                    this.currentPaneTarget.dispatchEvent(new CustomEvent('set-selected', { bubbles: true, composed: true, detail: false }))

                this.currentPaneType = null
            },
            hideRightPanel() {
                this.$refs['rightPanel'].hide()
            },
            showGamePanel() {
                this.itemListing = this.currentNode.nodeType.slug
                this.itemSearchText = 'Rechercher un minijeu'
            },
            itemSelected(item) {                
                let currentNode = this.scenario.nodes.find(node => node.id === this.currentNode.id)
                currentNode.node_content = item.id
                this.hideRightPanel()
            },
            saveScenario(duplicate = false) {
                let data = {
                    ...this.scenario,
                    // Make sure to save nodes as they appear on the screen (eliminate lost/ghost nodes)
                    nodes: this.nodes
                }

                if (duplicate === true) {
                    delete data.id
                    data.name += ' (copie)'
                }

                this.dispatchStoreRequest('Scenarios/saveLight', data).then((response) => {
                    if (response?.id) {
                        this.$router.push({
                            name: 'scenario-edit',
                            params: {
                                itemID: response.id
                            }
                        })
                    }
                    this.$bvToast.toast("Vos modifications ont bien été enregistrés !", {
                        title: `Succès !`,
                    });

                    this.hideRightPanel()
                })
            },
            updateAndSaveSettings(settings) {
                if (!this.scenario || !settings)
                    return

                // Update scenario data
                const keys = Object.keys(settings)
                for (var i = 0; i < keys.length; i++) {
                    this.scenario[keys[i]] = settings[keys[i]]
                }

                // Save scenario
                this.saveScenario()
            },
            previewScenario() {
                this.$store.dispatch('Utils/openFrontURL', 'scenario/' + this.itemID)
            },
            updateLocalScenarioData() {
                const remoteData = this.$store.state.Scenarios.items[this.itemID] //todo: computed?

                if (!remoteData) {
                    this.scenario.id = null
                    this.scenario.name = ""
                    this.scenario.description = ""
                    this.scenario.title = "Nouveau scénario"
                    this.scenario.location = null
                    this.scenario.type = (this.scenarioTypeList && this.scenarioTypeList.length > 0 ? this.scenarioTypeList.find(type => type.slug == 'scenario') || this.scenarioTypeList[0] : null)
                    this.scenario.contentLogs = []
                    this.scenario.spawnPoint = null
                    this.scenario.mapPoint = null
                    this.scenario.scenes = null
                    this.scenario.presetScenario = null
                    this.scenario.linkedScenario = null
                    this.scenario.correction = null
                    this.scenario.revisionDelay = 1
                    return
                }

                this.scenario.id = remoteData.id
                this.scenario.name = remoteData.name
                this.scenario.title = remoteData.title
                this.scenario.description = remoteData.description
                this.scenario.contentLogs = remoteData.contentLogs
                this.scenario.nodes = remoteData.nodes
                
                if (remoteData.scenario_type_id) {
                    const type = this.scenarioTypeList.find((type) => {
                        if (type.id == remoteData.scenario_type_id) {
                            return true
                        }

                        return false
                    })

                    this.scenario.type = type
                }

            },
            changeScenarioOrder({ moved }) {
                if(!moved) return
                const {element: node , oldIndex, newIndex} = moved
                
                
                const oldPreviousNode = this.nodes[oldIndex - 1]
                const newPreviousNode = this.nodes[newIndex - 1]

                const targetNode = this.nodes[newIndex]
                const targetNodeTriggeredId = this.nodes[newIndex].triggers[0]?.triggered_node_id

                const currentNodeTriggeredId = node.triggers[0]?.triggered_node_id

                                

                // si on va vers le bas la cible trigger le current et si la cible avait un trigger le current le récupère 
                if(newIndex > oldIndex) {
                    targetNode.triggers = [{
                        name: null,
                        node_id: targetNode.id,
                        // signal_id: null,
                        // triggerType:,
                        triggered_node_id: node.id,
                    }]
                    node.triggers = targetNodeTriggeredId ? [{
                        name: null,
                        node_id: node.id,
                        // signal_id: null,
                        // triggerType:,
                        triggered_node_id: targetNodeTriggeredId,
                    }] : []
                } else {
                    // si on va vers le haut et qu'il y a encore une previous node, la previous trigger le current
                    if(newPreviousNode) {
                        newPreviousNode.triggers = [{
                            name: null,
                            node_id: newPreviousNode.id,
                            // signal_id: null,
                            // triggerType:,
                            triggered_node_id: node.id,
                        }]
                    }
                    // le current node trigger la target 
                    node.triggers = [{
                        name: null,
                        node_id: node.id,
                        // signal_id: null,
                        // triggerType:,
                        triggered_node_id: targetNode.id,
                    }]
                }


                // si le current node avait un previous node, ce previous node trigger ce que le current node triggerait 
                if(oldPreviousNode) {
                    oldPreviousNode.triggers = currentNodeTriggeredId ? [{
                        name: null,
                        node_id: oldPreviousNode.id,
                        // signal_id: null,
                        // triggerType:,
                        triggered_node_id: currentNodeTriggeredId,
                    }] : []
                }               
                
            },
            selectGame(node) {
                this.currentNode = node
                this.showItemPane()
                this.showGamePanel()
            },
            removeGame(nodeId) {
                let nodeIndex = this.scenario.nodes.findIndex(node => node.id === nodeId)
                let orderedNodeIndex = this.nodes.findIndex(node => node.id === nodeId)
                if(orderedNodeIndex !== 0) {
                    let node = this.nodes[orderedNodeIndex]
                    let previousNode = this.nodes[orderedNodeIndex - 1]
                    previousNode.triggers = [...node.triggers]
                    if (previousNode.triggers.length > 0) {
                        previousNode.triggers[0].node_id = previousNode.id
                    }
                }
                this.scenario.nodes.splice(nodeIndex, 1)
            },
            addNewGame(game) {
                const newNodeId = Date.now()

                let lastOrderedNode = this.nodes[this.nodes.length - 1]
                this.scenario.nodes = this.scenario.nodes || []

                if(lastOrderedNode) {
                    lastOrderedNode.triggers = [{
                        name: null,
                        node_id: lastOrderedNode.id,
                        // signal_id: null,
                        // triggerType:,
                        triggered_node_id: newNodeId,
                    }]
                }
                this.scenario.nodes.push({
                    id: newNodeId,
                    node_content: game.id,
                    scenario_id: this.scenario.id,
                    position_x: 1,
                    position_y: 1,
                    triggers: [],
                    nodeType: { slug: 'activity' }
                })
                this.selectedGame = null
            },
            addNewNarrative(game) {
                const newNodeId = Date.now()

                let lastOrderedNode = this.nodes[this.nodes.length - 1]

                this.scenario.nodes = this.scenario.nodes || []

                if(lastOrderedNode) {
                    lastOrderedNode.triggers = [{
                        name: null,
                        node_id: lastOrderedNode.id,
                        // signal_id: null,
                        // triggerType:,
                        triggered_node_id: newNodeId,
                    }]
                }

                this.scenario.nodes.push({
                    id: newNodeId,
                    node_content: game.id,
                    scenario_id: this.scenario.id,
                    position_x: 1,
                    position_y: 1,
                    triggers: [],
                    nodeType: { slug: 'narrative' }
                })

                this.selectedNarrative = null
            },
            addNewVideo(game) {
                const newNodeId = Date.now()

                let lastOrderedNode = this.nodes[this.nodes.length - 1]

                this.scenario.nodes = this.scenario.nodes || []

                if(lastOrderedNode) {
                    lastOrderedNode.triggers = [{
                        name: null,
                        node_id: lastOrderedNode.id,
                        // signal_id: null,
                        // triggerType:,
                        triggered_node_id: newNodeId,
                    }]
                }

                this.scenario.nodes.push({
                    id: newNodeId,
                    node_content: game.id,
                    scenario_id: this.scenario.id,
                    position_x: 1,
                    position_y: 1,
                    triggers: [],
                    nodeType: { slug: 'video' }
                })

                this.selectedVideo = null
            },
            goBack() {
                this.$router.push({name:'scenario-list', params: {
                    previousPage: this.previousPage
                }})
            },
        }
    }
</script>

<style lang="scss">

.right-pane {
    top: 4rem;
    border-left: 1px solid rgba(0,0,0,0.2);
}
.add-node {
    width: 30%;
    margin: 0 auto;
}
.node-list {
    width: 95%;
    margin: 0 auto;
}
</style>