export default {
	name: 'Données de connexion',
	content: [
		{ 
			cells: [ 'CLIENT', '', 'PROMOTION', '', 'UTILISATEURS', '', '', '', '', '' ],
		},
		{
			table: 'customersGroups',
			headers: [ 'ID Client', 'Client', 'ID Promotion', 'Promotion', 'Inscrits', 'En attente', 'Désactivés', 'Actifs', 'Inactifs', 'Durée totale de connexion' ],
			row: (customerGroup) => {
				return [
					{ value: customerGroup.customer.identifier },
					{ value: customerGroup.customer.name },
					'identifier',
					'name',
					{ value: (customerGroup.users.length || 0) },
					{ value: (customerGroup.usersData.countbyState.pending || 0) },
					{ value: (customerGroup.usersData.countbyState.disabled || 0) },
					{ value: (customerGroup.usersData.countbyState.active || 0) },
					{ value: (customerGroup.usersData.countbyState.inactive || 0) },
					{ value: (customerGroup.usersData.activeTime || 0), type: 'duration' }
				]
			},
		}
	]
}