import gql from 'graphql-tag'

const GC_USER_INFOS = gql`
query getCurrentUser ($cognito_id:String!) {
  user(where: {cognito_id: {_eq: $cognito_id}}) {
    id
    first_name
    last_name
    email
    role
    cognito_id
    managed_groups {
      customer {
        id
        identifier
        name
        quota
      }
    }
  }
}
`

const CG_GET_ORGA = gql`
query getCurrentOrga($id: uuid!) {
  organisations(where: {admin_id: {_eq: $id}}) {
    id
    name
  }
}

`

export {
    GC_USER_INFOS,
    CG_GET_ORGA
}