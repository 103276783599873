<template>
	<div class="w-full question flex flex-row relative justify-center items-center my-8">
		<!-- Header/Handle -->
		<div class="handle self-stretch w-10 flex justify-center items-center cursor-move">
			<div class="rotate-icon">
				<b-icon-justify></b-icon-justify>
			</div>
		</div>

		<!-- Content -->
		<div class="flex items-center flex-1">
			<!-- Index -->
			<div class="handle cursor-pointer index bg-principal-selected w-10 self-stretch flex justify-center items-center text-xs">
				<div>{{ slide.order + 1 }}</div>
			</div>

			<!-- Form -->
			<div class="content p-8 w-full">
				<!-- Title -->
				<div>
					<label for="slide-title">Titre</label>
					<input type="text" id="slide-title" class="block twn-input w-full" v-model="title">
				</div>

				<!-- Text -->
				<div class="mt-4">
					<label for="w-full slide-text">Texte</label>
					<textarea
						id="slide-text"
						class="w-full twn-input"
						rows="4"
						v-model="text"
					/>
				</div>

				<!-- Character picture and message medium -->
				<div class="input-field w-full mt-4 flex justify-around items-center">
					<!-- Audio -->
					<v-select
					class="twn-select w-full mr-2"
					placeholder="Fichier son"
					:getOptionLabel="getOptionLabel"
					:options="audioList"
					:reduce="medium => medium.id"
					v-model="audio" />
				</div>
			</div>
		</div>

		<!-- Footer -->
		<div
			@click="$emit('remove')"
			class="cursor-pointer deleteQuestion self-stretch w-10 flex justify-center items-center text-white"
		>
			<b-icon-x></b-icon-x>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	export default {
		name: 'NarratorSlide',
		props: {
			slide: {
				type: Object,
				required: true
			}
		},
		computed: {
			...mapState({
				audioList: (state) => state.Assets.assets.filter(medium => medium.type.slug == 'audio'),
				metaTypes: state => {
					return state.Utils.metaTypeList.reduce((dict, meta) => {
						dict[meta.slug] = meta
						return dict
					}, {})
				}
			}),
			title: {
				get() {
					return this.slide.title
				},
				set(title) {
					this.$emit('update', {
						title
					})
				}
			},
			text: {
				get() {
					return this.slide.text
				},
				set(text) {
					this.$emit('update', {
						text
					})
				}
			},
			audio: {
				get() {
					return this.getMetaValue('audio')
				},
				set(value) {
					this.setMetaValue('audio', value)
				}
			}
		},
		methods: {
			getOptionLabel(option) {
				if (!option)
					return '~ Élément supprimé ~'

				return [option.identifier, option.name, option.title].filter(str => str || false).join(' - ') || '~ Élément sans titre ou supprimé ~'
			},
			getMeta(slug) {
				if (!this.metaTypes[slug])
					return { meta: { value: '~ Meta value error ~' }, index: -1 }

				for (var i = 0; i < this.slide.metas.length; i++) {
					if (this.slide.metas[i].meta_type_id == this.metaTypes[slug].id) {
						return { meta: this.slide.metas[i], index: i }
					}
				}
				
				return { meta: null, index: -1 }
			},
			getMetaValue(slug, defaultValue = null) {
				const { meta } = this.getMeta(slug)

				return ((meta && meta.value) || defaultValue)
			},
			setMetaValue(slug, value) {
				// Copy metas
				let metas = [...this.slide.metas]

				// Try to find existing meta with this slug
				let { meta, index } = this.getMeta(slug)

				// Update, add or remove meta value
				if (meta) {
					if (value != null) {
						meta.value = value
					} else {
						metas.splice(index, 1)
					}
				} else if (value) {
					metas.push({
						value,
						meta_type_id: this.metaTypes[slug].id
					})
				}

				this.$emit('update', {
					metas
				})
			}
		}
	}
</script>

<style lang="scss">
	.question {
		background: white;
		box-shadow: $cardShadow;
		border-radius: 10px;

		&.removeState {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}

		color: $textLight;
		.index {
			background-color: rgba(220, 151, 153, 0.1);
		}

		.rotate-icon {
			transform: rotate(90deg);
		}

		.image-content {
			.image {
				img {
					max-height: 150px;
				}
			}
		}

		.deleteQuestion {
			@apply bg-principal-selected;
			border-radius: 0 10px 10px 0;
		}

		input, textarea, select {
			color: $textMainColor;
		}
	}
</style>