<template>
	<div
		class="w-full question flex flex-row relative justify-center items-center"
		:class="noStyle ? 'no-style' : ''"
	>
		<div v-if="canAddQuestion" class="handle self-stretch w-10 flex justify-center items-center cursor-move">
			<div class="rotate-icon">
				<b-icon-justify></b-icon-justify>
			</div>
		</div>
		<div class="flex items-center flex-1">
			<div
				v-if="canAddQuestion"
				class="handle cursor-pointer number bg-principal-selected w-10 self-stretch flex justify-center items-center text-xs"
			>
				<div>{{ number + 1 }}</div>
			</div>
			<div class="content p-10 w-full" v-if="!imageType">
				<div v-if="hasLabel" class="input-field w-full">
					<div
						v-if="hasMedia"
						class="image-content text-center mb-4"
						@click="openMediaChoice"
						@dragover="allowDrop"
						@drop.prevent.stop="dropImageInQuestion($event)"
					>
						<div class="drop-zone p-10 w-full h-full border-dashed border-2 cursor-pointer" v-if="!image">
							<div class="m-auto w-1/3 break-words text-center text-xs">
								Ajouter une image depuis la bibliothèque de média
							</div>
						</div>
						<div v-else class="relative image inline-block m-auto h-2/3">
							<div
								class="w-4 h-4 absolute -right-2 -top-2 cursor-pointer pt-1"
								@click.stop="clearMedia"
							>
								<img src="@/assets/images/NODE_CROSS.svg" />
							</div>
							<img :src="image.thumbnail_url" alt="" class="cursor-pointer" />
						</div>
					</div>

					<b-form-textarea
						v-model="questionLabel"
						max-rows="6"
						type="text"
						:placeholder="
							options && options.questionPlaceholder ? options.questionPlaceholder : 'Question'
						"
					></b-form-textarea>
				</div>
				<div class="answers w-full flex flex-row justify-center items-center mt-5">
					<!-- Answer text -->
					<div class="box-answers w-full" v-bind:class="[answers.length == 0 ? 'w-0' : '']">
						<draggable v-model="answers" handle=".answer-handle">
							<div
								v-for="(answer, i) in answers"
								:key="i"
								class="w-full flex flex-row justify-center items-center"
							>
								<!-- Number -->
								<template v-if="!options || !options.fixedAnswers">
									<div class="answer-handle cursor-pointer w-10 flex items-center justify-center">
										<div class="rotate-icon">
											<b-icon-justify></b-icon-justify>
										</div>
									</div>
									<div class="number answer-handle w-10 text-center self-stretch p-2 cursor-pointer">
										<div>{{ i + 1 }}</div>
									</div>
								</template>

								<!-- Input/Text -->
								<div class="input-field w-full flex justify-around items-center p-2"
								:class="{ 'no-handle': (options && options.fixedAnswers) }">
									<span v-if="options && options.fixedAnswers && !options.editableAnswers">{{answer.text}}</span>
									<b-form-textarea
										v-else
										max-rows="6"
										class="h-full border-none"
										:class="{ 'w-full': !hasPicture, 'w-1/2': hasPicture }"
										@input="updateAnswerText($event, i)"
										type="text"
										:placeholder="
											options && options.answerPlaceholder
												? options.answerPlaceholder
												: 'Réponse'
										"
										:value="answer.text"
										/>

										<v-select
										v-if="hasPicture"
										class="twn-select w-1/3 ml-auto mr-4"
										placeholder="Image"
										:options="mediaList"
										label="name"
										:reduce="medium => medium.id"
										:value="answer.media_id"
										@input="updateAnswerMedium($event, i)" />
								</div>
								<div
									class="bg-principal-selected cursor-pointer self-stretch flex items-center text-white justify-center w-10"
									@click="removeAnswer(i)"
									v-if="!options || (options && !options.fixedAnswers)"
								>
									<b-icon-x></b-icon-x>
								</div>
							</div>
						</draggable>
					</div>

					<!-- Right/Wrong answer switch -->
					<div class="check-answers self-stretch flex flex-col justify-around items-center">
						<div
							v-for="(answer, i) in answers"
							:key="i"
							class="w-full mx-3 flex flex-row justify-center items-center"
						>
							<label class="switch">
								<template v-if="!options || options.hasMultipleAnswers === undefined">
									<input
										type="checkbox"
										:checked="answer.is_correct"
										@input="checkAnswer(i)"
									/>
									<span class="slider round"></span>
								</template>
								<input
									v-else
									type="radio"
									:name="'answer_' + number"
									:checked="answer.is_correct" 
									@input="checkAnswer(i)"
								/>
							</label>
						</div>
					</div>

					<!-- New answer button -->
					<div
						v-if="!options || (options && !options.fixedAnswers)"
						v-bind:class="[answers.length == 0 ? 'w-full' : '']"
					>
						<div
							@click="addDefaultAnswer(number)"
							class="plus m-auto rounded-full w-10 h-10 flex justify-center items-center cursor-pointer"
						>
							+
						</div>
					</div>
				</div>
				<template v-if="hasLabel && (!options || (options && !options.noCorrection))">
					<div class="w-2/3 inline-block pr-2 mt-10">
						<label :for="'correction-' + number" class="uppercase font-principal-medium text-sm w-full">Correction :</label>
						<b-form-textarea
							v-model="correctionText"
							class="w-full"
							max-rows="6"
							:id="'correction-' + number"
							:placeholder="
								options && options.correctionPlaceholder ? options.correctionPlaceholder : ''
							"
						></b-form-textarea>
					</div>

					<div class="w-1/3 inline-block pl-2 mt-10">
						<label :for="'correction-video-' + number" class="uppercase font-principal-medium text-sm w-full">Video de correction :</label>
						<v-select
						:id="'correction-video-' + number"
						class="twn-select"
						placeholder="Choisissez une vidéo"
						:getOptionLabel="getMediaLabel"
						:options="videoList"
						:reduce="medium => medium.id"
						v-model="correctionVideo" />
					</div>
				</template>
			</div>
			<div
				v-else
				class="w-full m-5 image-content"
				@click="openMediaChoice"
				@dragover="allowDrop"
				@drop.prevent.stop="dropImageInQuestion($event)"
			>
				<div class="drop-zone p-10 w-full h-full border-dashed border-2" v-if="!image">
					<div class="m-auto w-1/3 break-words text-center text-xs">
						Ajouter une image depuis la bibliothèque de média
					</div>
				</div>
				<div v-else class="image m-auto w-1/5 h-2/3">
					<img :src="image.thumbnail_url" alt="" class="" />
				</div>
			</div>
			<div></div>
		</div>
		<div
			v-if="canAddQuestion"
			@click="removeQuestion"
			class="cursor-pointer deleteQuestion self-stretch w-10 flex justify-center items-center text-white"
		>
			<b-icon-x></b-icon-x>
		</div>
	</div>
</template>
<script>

import { mapState } from 'vuex'

import Draggable from "vuedraggable"
import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"

export default {
	name: "Question",
	components: {
		Draggable
	},
	mixins: [dispatchStoreRequest],
	props: {
		number: {
			type: Number,
			required: true,
			default: null
		},
		options: {
			type: Object,
			required: false
		},
		question: {
			type: Object,
			required: true,
			default: null
		},
		imageType: {
			type: Boolean,
			required: false,
			default: false
		},
		mediaLoaded: {
			type: Boolean,
			required: false
		},
		noStyle: {
			Type: Boolean,
			required: false
		},
		canAddQuestion: {
			Type: Boolean,
			required: false,
			default: true
		},
		hasPicture: {
			Type: Boolean,
			required: false,
			default: false
		},
		hasLabel: {
			Type: Boolean,
			required: false,
			default: true
		},
		hasMedia: {
			Type: Boolean,
			required: false,
			default: true
		},
	},
	data() {
		return {
			image: null
		}
	},
	computed: {
		...mapState("Assets", {
			mediaList: (state) => state.assets,
			videoList: (state) => state.assets.filter(medium => ['video'].indexOf(medium.type.slug) > -1),
		}),
		questionLabel: {
			get() {
				return this.question.text
			},
			set(value) {
				this.$emit("update-question", this.number, {
					text: value
				})
			}
		},
		correctionText: {
			get() {
				return this.question.correction
			},
			set(value) {
				this.$emit("update-question", this.number, {
					correction: value
				})
			}
		},
		correctionVideo: {
			get() {
				return this.question.correction_media_id
			},
			set(medium_id) {
				this.$emit("update-question", this.number, {
					correction_media_id: medium_id
				})
			}
		},
		answers: {
			get() {
				return this.question.answers
			},
			set(value) {
				this.$emit("update-question", this.number, {
					answers: value
				})
			}
		}
	},
	methods: {
		getMediaLabel(medium) {
			return [medium.title, medium.name].filter(str => str || false).join(' - ')
		},
		updateAnswerText(input, i) {
			let newAnswers = [...this.answers]
			newAnswers[i].text = input
			this.answers = newAnswers
		},
		updateAnswerMedium(newValue, i) {
			let newAnswers = [...this.answers]
			newAnswers[i].media_id = newValue
			this.answers = newAnswers
		},
		removeQuestion() {
			this.$emit("delete-question", this.number)
		},
		addDefaultAnswer() {
			let newAnswers = [...this.answers]
			newAnswers.push({
				text: "",
				is_correct: false
			})
			this.$emit("update-question", this.number, {
				answers: newAnswers
			})
		},
		checkAnswer(index) {
			let correct = true
			let newAnswers = [...this.answers]

			if (!this.options || this.options.hasMultipleAnswers === undefined) {
				if (newAnswers[index].is_correct) {
					correct = false
				}
				newAnswers[index].is_correct = correct
			} else {
				for (var i = 0; i < newAnswers.length; i++) {
					newAnswers[i].is_correct = false

					if (this.options && this.options.multipleCorrection) {
						newAnswers[i].correction = this.wrongCorrectionText
					}
				}

				newAnswers[index].is_correct = true
				if (this.options && this.options.multipleCorrection) {
					newAnswers[index].correction = this.rightCorrectionText
				}
			}
			
			this.answers = newAnswers
		},
		removeAnswer(i) {
			let newAnswers = [...this.answers]
			newAnswers.splice(i, 1)
			this.answers = newAnswers
		},
		openMediaChoice() {
			this.$emit("open-panel")
		},
		async dropImageInQuestion(e) {
			if (e.dataTransfer) {
				const assetJSON = e.dataTransfer.getData("application/json")
				const asset = assetJSON ? JSON.parse(assetJSON) : null

				this.$emit("update-question", this.number, {
					media_id: asset.id
				})
			}
		},
		clearMedia() {
			this.$emit('update-question', this.number, {
				media_id: null
			})
		},
		allowDrop: function(event) {
			event.preventDefault()
		},
		async loadMedia() {
			if (this.question.media_id) {
				this.image = await this.dispatchStoreRequest("Assets/getAssetFromID", this.question.media_id, true)
			} else {
				this.image = null
			}
		},
	},
	watch: {
		'question.media_id': {
			handler() {
				this.loadMedia()
			},
			immediate: true,
		},
		'$store.state.Assets.assets': {
			handler() {
				this.loadMedia()
			},
		},
		async mediaLoaded() {
			this.loadMedia()
		}
	}
}
</script>
<style lang="scss">
.plus {
	background-color: rgba(225, 225, 225, 0.15);
}
.question:not(.no-style) {
	box-shadow: $cardShadow;
	border-radius: 10px;

	&.removeState {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	color: $textLight;
	.number {
		background-color: rgba(220, 151, 153, 0.1);
	}

	.rotate-icon {
		transform: rotate(90deg);
	}

	.image-content {
		.image {
			img {
				max-height: 150px;
			}
		}
	}

	.deleteQuestion {
		@apply bg-principal-selected;
		// border-radius: 0 10px 10px 0;
	}

	.answers {
		.box-answers {
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
		}
		.remove-state {
			> :first-child {
				@apply bg-principal-selected text-white;
			}
			> :last-child {
				@apply border-principal-selected;
			}
		}
		.input-field {
			border: solid 0.3px #e1e1e1;
			border-left: none;
			
			textarea {
				height: 1.4rem;
			}

			&.no-handle {
				border: solid 0.3px #e1e1e1;
			}
		}

		/* The switch - the box around the slider */
		.switch {
			@apply text-center cursor-pointer;
			position: relative;
			display: inline-block;
			width: 50px;
			height: 24px;
			margin-bottom: 0;
		}

		.switch input[type="radio"] {
			@apply appearance-none border-2 rounded-full w-4 h-4 cursor-pointer;
			border-color: #CCC;

			&:checked {
				@apply bg-red border-red;
			}
		}

		/* Hide default HTML checkbox */
		.switch input[type="checkbox"] {
			opacity: 0;
			width: 0;
			height: 0;
		}

		/* The slider */
		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #ccc;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		.slider:before {
			position: absolute;
			content: "";
			height: 16px;
			width: 16px;
			left: 4px;
			bottom: 4px;
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		input:checked + .slider {
			@apply bg-principal-selected;
		}

		input:checked + .slider:before {
			-webkit-transform: translateX(26px);
			-ms-transform: translateX(26px);
			transform: translateX(26px);
		}

		/* Rounded sliders */
		.slider.round {
			border-radius: 24px;
		}

		.slider.round:before {
			border-radius: 50%;
		}
	}
}
</style>
