<template>
	<div class="true-false">
		<Draggable v-model="questions" handle=".handle" v-bind="dragOptions">
			<div class="" v-for="(question, i) in questions" :key="i">
				<QuestionSimple
					class="my-5"
					:question="question"
					:number="i"
					:canAddQuestion="true"
					:hasMedia="true"
					:mediaLoaded="mediaLoaded"
					@delete-question="deleteQuestion"
					@update-question="updateQuestion"
					:options="options"
					@open-panel="openPanel(i)"
				>
				</QuestionSimple>
			</div>
		</Draggable>
		<div
			@click="addQuestion"
			class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl "
		>
			+
		</div>

		<RightPanel ref="rightPanel" title="Choix de média">
            <Medias
                :selectOnly="true"
                :draggable-asset="true"
                :default-type-filter-slug="panelFilters"
                @media-loaded="medialoaded = true"
                @select-media="onMediaSelected"
            />
        </RightPanel>
	</div>
</template>
<script>
import Draggable from "vuedraggable"
import QuestionSimple from "@/components/Games/Question_simple"
import RightPanel from '@/components/RightPanel'
import Medias from '@/views/Medias'

export default {
	name: "QuizSimple",
	components: {
		QuestionSimple,
		Draggable,
        RightPanel,
        Medias,
	},
	props: {
		game: {
			type: Object,
			required: true,
			default: null
		}
	},
	data() {
		return {
			options: {
				fixedAnswers: true,
				hasMultipleAnswers: false,
				editableAnswers: true,
			},
			mediaLoaded: false,
            panelQuestionIndex: null,
            panelFilters: [],
		}
	},
	computed: {
		questions: {
			get() {
				if (!this.game) return []
				return JSON.parse(JSON.stringify(this.game.questions))
			},
			set(value) {
				this.$emit("update-all-questions", value)
			}
		},
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			}
		}
	},
	watch: {
        game: {
            handler(game) {
                // Automaticly add one question if none provided
                if (game && game.questions.length <= 0) {
                    this.addQuestion()
                }
            },
            immediate: true
        }
    },
	methods: {
		addQuestion() {
			let question = {
				answers: [
					{
						text: "Vrai",
						is_correct: true
					},
					{
						text: "Faux",
						is_correct: false
					}
				],
				text: "",
				correction: ""
			}
			this.$emit("add-questions", [question])
		},
		updateQuestion(i, data) {
			this.$emit("update-question", i, data)
		},
		deleteQuestion(i) {
			this.$emit("delete-questions", [i])
		},
		openPanel(questionIndex) {
            this.panelFilters = ['image']
            this.panelQuestionIndex = questionIndex
            this.$refs['rightPanel'].show()
        },
        hidePanel() {
            this.$refs['rightPanel'].hide()
        },
        onMediaSelected(asset) {
            if (isNaN(this.panelQuestionIndex) || this.panelQuestionIndex < 0)
                return

            if (asset?.type?.slug !== 'image') {
                return
            }

            this.updateQuestion(this.panelQuestionIndex, {
                media_id: asset.id
            })
            this.hidePanel()
        },
	}
}
</script>

<style lang="scss"></style>
