export function getSurveyResultsFromLogs(logs, includeSessionsDetails = false) {
	const results = {
		answerCount: {},
		questionCount: {},
		answers: {},
	}

	if (includeSessionsDetails) {
		results.sessions = []
	}

	logs.forEach((log) => {
		const data = log.data

		if (!data) {
			return
		}

		// Count the number of time a question was answered and the number of time each of his answers were choosen
		const questionIds = Object.keys(data)

		questionIds.forEach((questionId) => {
			if (!data[questionId] || (!Array.isArray(data[questionId]) && !data[questionId].trim())) {
				return
			}

			const answerIds = (!Array.isArray(data[questionId]) ? [data[questionId]] : data[questionId])

			if (answerIds.length > 0) {
				if (!results.questionCount[questionId]) {
					results.questionCount[questionId] = 0
				}

				results.questionCount[questionId] += 1

				if (!results.answers[questionId]) {
					results.answers[questionId] = []
				}

				results.answers[questionId].push(answerIds)
			}

			answerIds.forEach((answerId) => {
				if (!results.answerCount[answerId + questionId]) {
					results.answerCount[answerId + questionId] = 0
				}

				results.answerCount[answerId + questionId] += 1
			})
		})

		if (includeSessionsDetails) {
			results.sessions.push({
				id: log.session_id,
				answers: log.data,
			})
		}
	})
	
	return results
}

export function getCoursesSurveys(data) {
	// Get survey results from logs
	return data.surveys.reduce((list, survey) => {
		if (!data.logsByDataKey[survey.id]) {
			return list
		}

		list.push({
			...survey,
			results: getSurveyResultsFromLogs(data.logsByDataKey[survey.id] || []),
		})

		return list
	}, [])
}

export function getCourseScenariosSurveys(data) {
	// For each scenario get their surveys results
	return data.scenarios.reduce((list, scenario) => {
		const surveys = scenario.nodes.reduce((surveyList, node) => {
			const survey = data.surveysById[node.node_content]
			
			if (!survey) {
				return surveyList
			}

			surveyList.push({
				...survey,
				results: getSurveyResultsFromLogs(data.logsByDataKey[survey.id] || [], true),
			})

			return surveyList
		}, [])

		if (surveys.length <= 0) {
			return list
		}

		list.push({
			...scenario,
			surveys,
		})

		return list
	}, [])
}