<template>
    <div class="game-simple" v-if="game && game.questions && game.questions.length > 0">

        <!-- Enable correction or not -->
        <div class="input-field float-right mb-6">
            <span class="mr-2">Mode conversation avec bonnes / mauvaises réponses</span>
            <label class="switch">
                <input type="checkbox" v-model="enableCorrection" />
                <span class="slider round"></span>
            </label>
        </div>

        <div class="content w-full flex mb-8">
            <div class="flex-grow">
                <!-- Character name -->
                <div class="input-field w-full">
                    <b-form-input
                    placeholder="Nom du personnage"
                    type="text"
                    v-model="name" />
                </div>

                <!-- Character picture and conversation medium -->
                <div class="input-field w-full mt-4 flex justify-around items-center">
                    <!-- Picture -->
                    <v-select
                    class="twn-select w-full mr-2"
                    placeholder="Image portrait"
                    :getOptionLabel="getOptionLabel"
                    :options="pictureList"
                    :reduce="medium => medium.id"
                    v-model="picture" />

                    <!-- Medium -->
                    <v-select
                    class="twn-select w-full ml-2"
                    placeholder="Media vidéo"
                    :getOptionLabel="getOptionLabel"
                    :options="mediaList"
                    :reduce="medium => medium.id"
                    v-model="video" />
                </div>
            </div>

            <!-- Type -->
            <div class="flex flex-col justify-center px-4" v-if="types">
                <!-- todo: component to list type as select or radio (all type or only some) -->
                <label
                class="cursor-pointer"
                :class="{ 'mb-0': (index > 0) }"
                v-for="(slug, index) in ['conversation_visio', 'conversation_facetime'].filter(slug => types[slug] || false)"
                :key="slug"
                :for="'type' + slug">
                    <input
                    :id="'type' + slug"
                    class="mr-1"
                    type="radio"
                    :name="'type'"
                    :value="types[slug].id"
                    v-model="type">
                    {{ types[slug].name }}
                </label>
            </div>
        </div>

        <!-- Conversation/Question List -->
        <Draggable v-model="conversations" handle=".handle">
            <Conversation
            v-for="(conversation, index) in game.questions"
            :key="conversation.id || index"
            class="my-5"
            :identifier="index + 1"
            :question="conversation"
            :enableCorrection="enableCorrection"
            @update-question="updateQuestion(index, $event)"
            @delete-question="deleteQuestion(index)" />
        </Draggable>

        <div
            @click="addConversation"
            class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl mt-6"
        >
            +
        </div>
    </div>
</template>
<script>

import { mapState } from 'vuex'

import Draggable from 'vuedraggable'

import Conversation from '@/components/Games/Conversation'

export default {
    name: 'Conversations',
    components: {
        Draggable,
        Conversation
    },
    props: {
		game: {
			type: Object,
			required: true,
			default: null
		},
        getGameMetaValue: {
            type: Function,
            required: true
        }
    },
    data() {
        return {}
    },
    computed: {
        ...mapState("Games", {
            types(state){
                if (!state.question_type_list || state.question_type_list.length <= 0)
                    return null

                return state.question_type_list.reduce((dict, type) => {
                    dict[type.slug] = type
                    return dict
                }, {})
            }
        }),
        ...mapState("Assets", {
            pictureList: (state) => state.assets.filter(medium => medium.type.slug == 'image'),
            mediaList: (state) => state.assets.filter(medium => ['video'].indexOf(medium.type.slug) > -1)
        }),
        conversations: {
            get() {
                if (!this.game)
                    return []

                return JSON.parse(JSON.stringify(this.game.questions));
            },
            set(value){
                this.$emit('update-all-questions', value)
            }
        },
        introVideo() {
            if (!this.game.medias || this.game.medias.length <= 0 || !this.game.medias[0].media_id)
                return null

            const medium = this.game.medias.find((medium) => (medium.order == 0))

            return (medium ? medium.media_id : null)
        },
        name: {
            get() {
                return this.getGameMetaValue('character_name')
            },
            set(value) {
                this.setGameMetaValue('character_name', value)
            }
        },
        picture: {
            get() {
                return this.getGameMetaValue('character_picture')
            },
            set(value) {
                this.setGameMetaValue('character_picture', value)
            }
        },
        video: {
            get() {
                return this.introVideo
            },
            set(value) {
                this.updateConversationIntroVideo(value)
            }
        },
        type: {
            get() {
                const defaultTypeId = (this.types && this.types['conversation_visio'] && this.types['conversation_visio'].id)

                if (!this.game.questions || this.game.questions.length <= 0)
                    return defaultTypeId

                return this.game.questions[0].question_type_id
            },
            set(value) {
                if (!this.game.questions || this.game.questions.length <= 0)
                    return

                // Update all question type
                this.game.questions.forEach(question => {
                    question.question_type_id = value
                })
            }
        },
        enableCorrection: {
            get() {
                return this.game.show_correction
            },
            set(value) {
                this.game.show_correction = value
            }
        }
    },
    watch: {
        game: {
            handler(game) {
                // Automaticly add one conversation if none provided
                if (game && game.questions.length <= 0) {
                    this.addConversation()
                }
            },
            immediate: true
        }
    },
    methods: {
        getOptionLabel(option) {
            if (!option)
                return '~ Élément supprimé ~'

            return [option.identifier, option.name, option.title].filter(str => str || false).join(' - ') || '~ Élément sans titre ou supprimé ~'
        },
        addConversation() {
            const defaultTypeId = (this.types && this.types['conversation_visio'] && this.types['conversation_visio'].id)

            // Add a question to represent the conversation
            const question = {
                text: '',
                correction: '',
                help: '',
                media_id: null,
                answers: [ { text: '', media_id: null, is_correct: false } ],
                question_type_id: defaultTypeId
            }

            this.$emit('add-questions', [ question ])
        },
        updateQuestion(i, data) {
            this.$emit('update-question', i, data)
        },
        updateConversationIntroVideo(medium_id) {
            this.$emit('update-game-media', medium_id)
        },
        setGameMetaValue(slug, value) {
            this.$emit('update-meta-value', { slug, value })
        },
        deleteQuestion(i) {
            this.$emit('delete-questions', [i])
        }
    }
}
</script>

<style lang="scss">
</style>