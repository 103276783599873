<template>
    <div class="game-survey">
        <Draggable v-model="questions" handle=".handle" v-bind="dragOptions">
            <div v-for="(question, i) in questions" :key="i">
                <SurveyQuestion
                    class="my-5"
                    :number="i"
                    :question="question"
                    :questionCount="questionCount"
                    :answerCount="answerCount"
                    @delete-question="deleteQuestion"
                    @update-question="updateQuestion"
                    @open-panel="openPanel(i)"
                />
            </div>
        </Draggable>

        <div @click="addQuestion" class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl ">+</div>

        <RightPanel ref="rightPanel" title="Choix de média">
            <Medias
                :selectOnly="true"
                :draggable-asset="true"
                :default-type-filter-slug="panelFilters"
                @media-loaded="medialoaded = true"
                @select-media="onMediaSelected"
            />
        </RightPanel>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import Draggable from 'vuedraggable'
import SurveyQuestion from '@/components/Games/SurveyQuestion'
import RightPanel from '@/components/RightPanel'
import Medias from '@/views/Medias'

export default {
    name: 'Survey',
    components: {
        SurveyQuestion,
        Draggable,
        RightPanel,
        Medias,
    },
    props: {
		game: {
			type: Object,
			required: true,
			default: null
		}
    },
    data(){
        return {
            mediaLoaded: false,
            panelQuestionIndex: null,
            panelFilters: [],
            answerCount: {},
            questionCount: {},
        }
    },
    computed: {
        ...mapState('Auth', [ 'userInfo' ]),
        ...mapState('Course', { courseList: 'list' }),
        ...mapState('Games', {
            questionTypeSlugById(state){
                if (!state.question_type_list || state.question_type_list.length <= 0)
                    return {}

                return state.question_type_list.reduce((dict, questionType) => {
                    dict[questionType.id] = questionType.slug
                    return dict
                }, {})
            },
        }),
        isSuperAdmin() {
            return (this.userInfo && this.userInfo.role == 'superadmin')
        },
        questions:{
            get() {
                if (!this.game)
                    return []
                return JSON.parse(JSON.stringify(this.game.questions));
            },
            set(value){
                this.$emit('update-all-questions', value)
            }
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
    },
    watch: {
        game: {
            handler(game) {
                // Automaticly add one question if none provided
                if (game && game.questions.length <= 0) {
                    this.addQuestion()
                }

                // Asynchronously load survey results
                this.loadResults()
            },
            immediate: true
        }
    },
    methods:{
        addQuestion(){
            let question = {
                answers:[{
                    text: '',
                    is_correct: false,
                }],
                text: '',
                correction: '',
                question_type_id: null,
                required: false,
            }
            this.$emit('add-questions', [question])
        },
        updateQuestion(i, data){
            this.$emit('update-question', i, data)
        },
        deleteQuestion(i){
            this.$emit('delete-questions', [i])
        },
        openPanel(questionIndex) {
            this.panelFilters = ['image']
            this.panelQuestionIndex = questionIndex
            this.$refs['rightPanel'].show()
        },
        hidePanel() {
            this.$refs['rightPanel'].hide()
        },
        onMediaSelected(asset) {
            if (isNaN(this.panelQuestionIndex) || this.panelQuestionIndex < 0)
                return

            if (asset?.type?.slug !== 'image') {
                return
            }

            this.updateQuestion(this.panelQuestionIndex, {
                media_id: asset.id
            })
            this.hidePanel()
        },
        async loadResults() {
            const questionById = this.questions.reduce((dict, question) => {
                dict[question.id] = question
                return dict
            }, {})

            this.answerCount = {}
            this.questionCount = {}

            if (!this.game.id) {
                return
            }

            let results = []

            if (this.isSuperAdmin) {
                results = await this.$store.dispatch('Logs/GetSurveyResults', {
                    id: this.game.id,
                })
            } else {
                if (!this.courseList || this.courseList.length <= 0) {
                    await this.$store.dispatch('Course/getList')
                }

                results = await this.$store.dispatch('Logs/GetSurveyResultsForCourses', {
                    id: this.game.id,
                    courseIds: this.courseList.map((course) => course.id),
                })
            }

            results.forEach((result) => {
                if (!result.data) {
                    return
                }

                // Count the number of time a question was answered and the number of time each of his answers were choosen
                const questionIds = Object.keys(result.data)

                questionIds.forEach((questionId) => {
                    let answerIds = result.data[questionId]
                    const questionTypeSlug = this.questionTypeSlugById[questionById[questionId].question_type_id]

                    // Sanitize
                    answerIds = (Array.isArray(answerIds) ? answerIds : answerIds.trim())

                    if (answerIds.length > 0) {
                        if (!this.questionCount[questionId]) {
                            this.$set(this.questionCount, questionId, 0)
                        }

                        this.questionCount[questionId] += 1
                    }

                    if (Array.isArray(answerIds)) {
                        result.data[questionId].forEach((answerId) => {
                            if (!this.answerCount[answerId]) {
                                this.$set(this.answerCount, answerId, 0)
                            }

                            this.answerCount[answerId] += 1
                        })
                    } else if (questionTypeSlug !== 'open') {
                        if (!this.answerCount[answerIds]) {
                            this.$set(this.answerCount, answerIds, 0)
                        }

                        this.answerCount[answerIds] += 1
                    }
                })
            })
        }
    }
}
</script>