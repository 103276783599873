const defaults = {
    true_false: {
        instruction: 'Choisissez la bonne réponse',
    },
    dragdrop: {
        instruction: '',
    },
    dragdrop_picture: {
        instruction: '',
    },
    drag_drop: {
        instruction: '',
    },
    quiz: {
        instruction: '',
    },
    quiz_simple: {
        instruction: 'Répondez à la question (plusieurs réponses possibles)',
    },
    quiz_picture: {
        instruction: '',
    },
    quiz_table: {
        instruction: '',
    },
    cell_by_cell: {
        instruction: '',
    },
    reorder: {
        instruction: '',
    },
    vocabulary: {
        instruction: '',
    },
    gap_fill: {
        instruction: '',
    },
    hotspots: {
        instruction: 'Consultez chaque point d’attention sur l’image',
    },
    message_gallery: {
        instruction: '',
    },
    message_phone: {
        instruction: '',
    },
    conversation: {
        instruction: '',
    },
    survey: {
        instruction: 'Répondez aux questions',
    },
}

export default defaults