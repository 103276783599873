import apollo from '@/apolloClient'

import {
    GC_GET_NARRATIVE_TYPE_LIST,
    GC_GET_NARRATIVE_LIST,
    GC_GET_NARRATIVE_BY_ID,
    GC_ADD_NARRATIVE_ONE,
    GC_UPDATE_NARRATIVE_BY_ID,
    GC_DELETE_NARRATIVE_BY_ID,
    GC_GET_SLIDE_TYPE_LIST
} from '@/graphql/narrative'

export default {
    namespaced: true,
    state: {
        list: [],
        items: {},
        typeList: [],
        slideTypeList: [],
    },
    mutations: {
        SET_NARRATIVE_LIST(state, narratives) {
            state.list = (narratives || []);
        },
        SET_NARRATIVE_TYPE_LIST(state, types) {
            state.typeList = types;
        },
        SET_NARRATIVE(state, narrative) {
            if (!narrative || !narrative.id)
                return

            state.items[narrative.id] = narrative
        },
        SET_SLIDE_TYPE_LIST(state, types) {
            state.slideTypeList = types;
        }
    },
    actions: {
        async getTypeList({ commit }) {
            const response = await apollo.query({ query: GC_GET_NARRATIVE_TYPE_LIST })

            commit('SET_NARRATIVE_TYPE_LIST', response.data.narrative_type || [])
        },
        async getSlideTypeList({ commit }) {
            const response = await apollo.query({ query: GC_GET_SLIDE_TYPE_LIST })

            commit('SET_SLIDE_TYPE_LIST', response.data.slide_type || [])
        },
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_NARRATIVE_LIST })

            commit('SET_NARRATIVE_LIST', response.data.narrative)
        },
        async getByID({ commit }, id) {
            let response = await apollo.query({
                query: GC_GET_NARRATIVE_BY_ID,
                variables: { id }
            })

            if (!response.data.narrative_by_pk) {
                return
            }

            commit('SET_NARRATIVE', response.data.narrative_by_pk)
        },
        async save(context, data) {
            let response = null
            let result = {}

            // Copy and format data for GraphQL
            let gql_data = JSON.parse(JSON.stringify(data))

            let narrative = {
                id: gql_data.id,
                identifier: gql_data.identifier,
                title: gql_data.title,
                narrative_type_id: gql_data.narrative_type_id,
            }

            // Update or add the narrative
            if (gql_data.id) {
                // Set id
                let variables = {
                    id: gql_data.id,
                    narrative
                }

                // Format condition for deleted slides
                const existingSlides = gql_data.slides.filter((slide) => {
                    return (slide.id ? true : false)
                })

                variables.slide_ids = existingSlides.map((slide) => slide.id)

                // List all slide metas (existing or new) and create condition expression to delete old ones
                const slideMetasData = existingSlides.reduce((data, slide) => {
                    if (!slide.metas)
                        return data

                    // Add new condition rules (to delete existing meta that are no longer in the current list)
                    data.deleteCondition._or.push({
                        slide_id: { _eq: slide.id },
                        id: {
                            _nin: slide.metas.reduce((ids, meta) => {
                                if (meta.id) {
                                    ids.push(meta.id)
                                }

                                return ids
                            }, [])
                        },
                    })

                    // List all meta in existing slide (to be able to insert new ones and update existing ones)
                    slide.metas.forEach((meta) => {
                        meta.slide_id = slide.id

                        data.list.push(meta)
                    })

                    return data
                }, {
                    deleteCondition: { _or: [] },
                    list: [],
                })

                variables.delete_slide_metas_condition = slideMetasData.deleteCondition
                variables.slide_metas = slideMetasData.list

                // List all slide blocks (existing or new) and create condition expression to delete old ones
                const slideBlocksData = existingSlides.reduce((data, slide) => {
                    if (!slide.blocks)
                        return data

                    // Add new condition rules (to delete existing block that are no longer in the current list)
                    data.deleteCondition._or.push({
                        slide_id: { _eq: slide.id },
                        id: {
                            _nin: slide.blocks.reduce((ids, block) => {
                                if (block.id) {
                                    ids.push(block.id)
                                }

                                return ids
                            }, [])
                        },
                    })

                    // List all block in existing slide (to be able to insert new ones and update existing ones)
                    slide.blocks.forEach((block) => {
                        block.slide_id = slide.id

                        data.list.push(block)
                    })

                    return data
                }, {
                    deleteCondition: { _or: [] },
                    list: [],
                })

                variables.delete_slide_blocks_condition = slideBlocksData.deleteCondition

                // Format slide block media and meta list
                variables.delete_slide_block_media_condition = { _or: [] }
                variables.slide_block_media = []
                variables.delete_slide_block_metas_condition = { _or: [] }
                variables.slide_block_metas = []

                existingSlides.forEach((slide) => {
                    if (!slide.blocks)
                        return

                    slide.blocks.forEach((block) => {
                        if (!block.id) {
                            return
                        }

                        // Add new condition rules (to delete existing media that are no longer in the current list)
                        variables.delete_slide_block_media_condition._or.push({
                            slide_block_id: { _eq: block.id },
                            media_id: {
                                _nin: block.media.reduce((ids, medium) => {
                                    if (medium.media_id) {
                                        ids.push(medium.media_id)
                                    }

                                    return ids
                                }, [])
                            },
                        })

                        // List all media in existing block (to be able to insert new ones and update existing ones)
                        block.media.forEach((medium, index) => {
                            if (!medium.media_id) {
                                return
                            }

                            medium.slide_block_id = block.id
                            medium.order = (medium.order || index)

                            variables.slide_block_media.push(medium)
                        })

                        // Add new condition rules (to delete existing meta that are no longer in the current list)
                        variables.delete_slide_block_metas_condition._or.push({
                            slide_block_id: { _eq: block.id },
                            id: {
                                _nin: block.metas.reduce((ids, meta) => {
                                    if (meta.id) {
                                        ids.push(meta.id)
                                    }

                                    return ids
                                }, [])
                            },
                        })

                        // List all meta in existing block (to be able to insert new ones and update existing ones)
                        block.metas.forEach((meta) => {
                            meta.slide_block_id = block.id

                            variables.slide_block_metas.push(meta)
                        })
                    })
                })

                variables.slide_blocks = slideBlocksData.list.map((block) => {
                    if (block.id) {
                        // Remove media and metas for existing ones (upsert)
                        delete block.media
                        delete block.metas
                    } else {
                        // Format media and metas for new ones (insert)
                        block.media = {
                            data: block.media.filter((medium) => {
                                return !!medium.media_id
                            }).map((medium, index) => {
                                medium.order = (medium.order || index)
                                return medium
                            })
                        }
                        block.metas = { data: block.metas }
                    }
                
                    return block
                })

                // Format slides list for upsert
                variables.slides = gql_data.slides.map((slide) => {
                    if (slide.id) {
                        // Remove metas and blocks for existing ones (upsert)
                        delete slide.metas
                        delete slide.blocks
                    } else {
                        // Add narative id for new ones (insert)
                        slide.narrative_id = gql_data.id

                        // Format blocks and metas
                        slide.metas = { data: slide.metas }
                        slide.blocks = {
                            data: slide.blocks.map((block) => {
                                block.media = {
                                    data: block.media.filter((medium) => {
                                        return !!medium.media_id
                                    }).map((medium, index) => {
                                        medium.order = (medium.order || index)
                                        return medium
                                    })
                                }
                                block.metas = { data: block.metas }

                                return block
                            })
                        }
                    }

                    return slide
                })

                // Update remote data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_NARRATIVE_BY_ID,
                    variables
                })

                result.success = true
            } else {
                // Format slides for insert
                narrative.slides = { 
                    data: gql_data.slides.map(slide => {
                        // Format metas and blocks for insert
                        slide.metas = { data: slide.metas }
                        slide.blocks = {
                            data: slide.blocks.map((block) => {
                                block.media = {
                                    data: block.media.filter((medium) => {
                                        return !!medium.media_id
                                    }).map((medium, index) => {
                                        medium.order = (medium.order || index)
                                        return medium
                                    })
                                }
                                block.metas = { data: block.metas }

                                return block
                            })
                        }

                        return slide
                    }) 
                }

                // Add remote data
                response = await apollo.mutate({
                    mutation: GC_ADD_NARRATIVE_ONE,
                    variables: {
                        narrative
                    }
                })

                result.id = response.data.insert_narrative_one.id
            }

            return result
        },
        async delete(context, id){
            const response = await apollo.mutate({
                mutation: GC_DELETE_NARRATIVE_BY_ID,
                variables: { id }
            })

            return response.data.delete_narrative_by_pk
        },
    }
}