<template>
    <div class="game-simple">
        <!-- Hotspots editor -->
        <div class="flex">
            <div class="hotspots" :class="{ 'empty': !backgroundImage }">
                <div
                class="hotspot"
                :class="[
                    hotspotSize,
                    { invert: hotspotInvertColors },
                ]"
                v-for="(hotspot, index) in hotspots"
                :key="index"
                :style="{ top: (hotspot.line * 100) + '%', left: (hotspot.column * 100) + '%' }"
                draggable="false"
                @mousedown="selectHotspot($event, index)"
                @mouseup="unselectHotspot"
                @mouseout="unselectHotspot"
                @mousemove="moveHotspot($event, index, hotspot)"
                >
                    <span draggable="false">{{ getHotspotIndexLabel(index) }}</span>
                </div>

                <img
                class="cursor-copy"
                draggable="false"
                :src="backgroundImage"
                @click="addHotspot">
            </div>

            <div class="w-1/5 pl-4">
                <button
                class="twn-button text-xs"
                @click="openHotspotBackgroundPanel">
                    {{ (backgroundImage ? 'Remplacer' : 'Définir') }} l'image
                </button>

                <button
                :disabled="!backgroundImage"
                @click="addHotspot(null)"
                class="twn-button text-xs mt-4">
                    Ajouter une pastille
                </button>

                <div class="mt-8">
                    <label
                        class="uppercase font-principal-medium text-sm w-full"
                        for="hotspot-symbol"
                    >
                        Symbole des pastilles
                    </label>
                    <v-select
                        id="hotspot-symbol"
                        class="twn-select"
                        :options="symbolList"
                        :clearable="false"
                        :reduce="symbol => symbol.value"
                        v-model="hotspotSymbol"
                    />

                    <label
                        class="uppercase font-principal-medium text-sm w-full mt-4"
                        for="hotspot-size"
                    >
                        Taille des pastilles
                    </label>
                    <v-select
                        id="hotspot-size"
                        class="twn-select"
                        :options="sizeList"
                        :clearable="false"
                        :reduce="size => size.value"
                        v-model="hotspotSize"
                    />

                    <label class="uppercase font-principal-medium text-sm w-full mt-4">
                        Inverser la couleur des pastilles
                    </label>
                    <Toggle
                        :checked="hotspotInvertColors"
                        :onInput="() => hotspotInvertColors = !hotspotInvertColors"
                    />
                </div>
            </div>
        </div>

        <p class="mt-4 mb-8">Glisser déposer chaque spot à l'endroit voulue</p>

        <!-- Hotspots/Questions list -->
        <Draggable v-model="hotspots" handle=".handle" v-bind="dragOptions">
            <div class="" v-for="(question, index) in hotspots" :key="index">
                <Hotspot
                class="my-5"
                :question="question"
                :identifier="getHotspotIndexLabel(index)"
                @delete-question="deleteQuestion(index)"
                @update-question="updateQuestion(index, $event)" />
            </div>
        </Draggable>

        <!-- Media panel -->
        <RightPanel ref="rightPanel" title="Choix d'image">
            <Medias
                :defaultTypeFilterSlug="['image']"
                :selectOnly="true"
                :draggable-asset="false"
                @select-media="updateHotspotBackground"
            ></Medias>
        </RightPanel>
    </div>
</template>
<script>

import { mapState } from 'vuex'

import Draggable from 'vuedraggable'

import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

import Hotspot from '@/components/Games/Hotspot'
import RightPanel from '@/components/RightPanel'
import Medias from '@/views/Medias'
import Toggle from '@/components/Toggle'

export default {
    name: 'Hotspots',
    components: {
        Hotspot,
        Draggable,
        RightPanel,
        Medias,
        Toggle,
    },
    mixins: [
        dispatchStoreRequest,
    ],
    props: {
		game: {
			type: Object,
			required: true,
			default: null
		},
        getGameMetaValue: {
            type: Function,
            required: true
        },
    },
    data() {
        return {
            selectedHotspot: null,
            selectedHotspotLastEvent: null,
            dragOptions: {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            },
            sizeList: [
                {
                    label: 'Très petite',
                    value: 'xsmall',
                },
                {
                    label: 'Petite',
                    value: 'small',
                },
                {
                    label: 'Moyenne',
                    value: 'medium',
                },
                {
                    label: 'Grande',
                    value: 'large',
                },
                {
                    label: 'Très grande',
                    value: 'xlarge',
                },
            ],
        }
    },
    async created() {
        await this.dispatchStoreRequest('Games/getQuestionTypeList')
    },
    computed: {
        ...mapState('Assets', {
            assets(state) {
                if (!state.assets || state.assets.length <= 0) return {}

                return state.assets.reduce((dict, asset) => {
                    dict[asset.id] = asset

                    return dict
                }, {})
            }
        }),
        ...mapState('Games', {
            questionTypesSlugById(state){
                if (!state.question_type_list || Object.keys(state.question_type_list).length <= 0) return {}

                return state.question_type_list.reduce((dict, questionType) => {
                    dict[questionType.id] = questionType.slug
                    return dict
                }, {})
            },
            questionTypesIdBySlug(state){
                if (!state.question_type_list || Object.keys(state.question_type_list).length <= 0) return {}

                return state.question_type_list.reduce((dict, questionType) => {
                    dict[questionType.slug] = questionType.id
                    return dict
                }, {})
            },
            symbolList(state) {
                if (!state.question_type_list || Object.keys(state.question_type_list).length <= 0) return []

                return state.question_type_list.filter((type) => {
                    return (type.slug.indexOf('symbol_') == 0)
                }).map((type) => {
                    return {
                        label: type.name,
                        value: type.slug,
                    }
                })
            }
        }),
        hotspotSymbol: {
            get() {
                const typeId = this.getGameMetaValue('hotspot_symbol')

                return (this.questionTypesSlugById[typeId] || 'symbol_target')
            },
            set(value) {
                const typeId = this.questionTypesIdBySlug[value]

                if (typeId && this.game?.questions.length > 0) {
                    for (let i = 0; i < this.game.questions.length; i += 1) {
                        this.$emit('update-question', i, {
                            question_type_id: typeId,
                        })
                    }
                }

                this.setGameMetaValue('hotspot_symbol', typeId)
            },
        },
        hotspotSize: {
            get() {
                return (this.getGameMetaValue('hotspot_size') || 'medium')
            },
            set(value) {
                this.setGameMetaValue('hotspot_size', value)
            },
        },
        hotspotInvertColors: {
            get() {
                return this.getGameMetaValue('invert_colors')
            },
            set(value) {
                // Convert boolean to string/null and save meta (true => '1', false => null)
                this.setGameMetaValue('invert_colors', (value && '1' || null))
            },
        },
        hotspots: {
            get() {
                if (!this.game)
                    return []

                return JSON.parse(JSON.stringify(this.game.questions));
            },
            set(value){
                this.$emit('update-all-questions', value)
            }
        },
        backgroundImage() {
            if (!this.game.medias || this.game.medias.length <= 0 || !this.game.medias[0].media_id || !this.assets[this.game.medias[0].media_id])
                return null

            return this.assets[this.game.medias[0].media_id].url
        }
    },
    methods: {
        getHotspotIndexLabel(index) {
            let label = ""

            const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
            for (var i = 0; i < Math.ceil((index + 1) / letters.length); i++) {
                label += letters[index % letters.length]
            }

            return label
        },
        addHotspot(event) {
            if (!this.backgroundImage)
                return

            let top = 0.4
            let left = 0.4

            if (event) {
                const container = event.currentTarget

                top = ((event.offsetY - (container.clientHeight * 0.075)) / container.clientHeight)
                left = ((event.offsetX - (container.clientWidth * 0.075)) / container.clientWidth)
            }

            // Add a question to represent a hotspot
            const typeId = (this.game?.questions?.[0]?.question_type_id || this.questionTypesIdBySlug['symbol_target'])

            const question = {
                text: '',
                correction: '',
                help: '',
                media_id: null,
                line: top,
                column: left,
                answers: [],
                question_type_id: (typeId || null),
            }

            this.$emit('add-questions', [ question ])
        },
        selectHotspot(event, index) {
            this.selectedHotspot = index
            this.selectedHotspotLastEvent = event
        },
        unselectHotspot(event) {
            if (event.buttons > 0)
                return

            this.selectedHotspot = null
        },
        moveHotspot(event, index, hotspot) {
            if (this.selectedHotspot != index || event.buttons <= 0)
                return

            // Compute delta since last event
            const parent = event.currentTarget.parentElement
            
            const deltaX = (event.clientX - this.selectedHotspotLastEvent.clientX) / parent.clientWidth
            const deltaY = (event.clientY - this.selectedHotspotLastEvent.clientY) / parent.clientHeight

            // Check if the new coordinates are out of container
            const maxTop = (parent.clientHeight - event.currentTarget.clientHeight) / parent.clientHeight
            const maxLeft = (parent.clientWidth - event.currentTarget.clientWidth) / parent.clientWidth

            let column = (hotspot.column + deltaX)
            let line = (hotspot.line + deltaY)

            if (column < 0) {
                column = 0
            } else if (column > maxLeft) {
                column = maxLeft
            }

            if (line < 0) {
                line = 0
            } else if (line > maxTop) {
                line = maxTop
            }

            // Update hotspot coordinates
            this.$emit('update-question', index, {
                column,
                line
            })

            this.selectedHotspotLastEvent = event
        },
        openHotspotBackgroundPanel() {
            this.$refs['rightPanel'].show()
        },
        updateHotspotBackground(asset){
            if (asset.type.slug != 'image')
                return

            this.$emit('update-game-media', asset.id)
            this.$refs['rightPanel'].hide()
        },
        updateQuestion(i, data){
            this.$emit('update-question', i, data)
        },
        deleteQuestion(i){
            this.$emit('delete-questions', [i])
        },
        setGameMetaValue(slug, value) {
            this.$emit('update-meta-value', { slug, value })
        },
    }
}
</script>

<style lang="scss">
    .hotspots {
        @apply w-4/5 relative;
        background-color: #232329;
        user-select: none;
        
        &.empty {
            height: 600px;
        }

        img {
            user-select: none;
            min-width: 100%;
        }
    }

    .hotspot {
        @apply absolute flex justify-center items-center rounded-full text-3xl font-principal-bold cursor-grab;
        width: 15%;
        background-color: rgba(255, 255, 255, 0.6);

        &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        span {
            user-select: none;
        }

        &.invert {
            color: white;
            background-color: rgba(180, 180, 180, 1);
        }

        &.xsmall {
            @apply text-base;
            width: 5%;
        }

        &.small {
            @apply text-2xl;
            width: 10%;
        }

        &.medium {
            @apply text-3xl;
            width: 15%;
        }

        &.large {
            @apply text-4xl;
            width: 20%;
        }

        &.xlarge {
            @apply text-5xl;
            width: 25%;
        }

    }
</style>