import utils from "@/utils/trackingReportTemplate"

import { getSurveyResultsFromLogs } from '../common/surveysData'

// Create a table with one answer per row and a column for each customer results
function getAnswersTableSpec(customerHeaders, question, surveyResultsByCustomerId, customerIds) {
	// Get answer name formating for this question type
	let formatAnswerName = (answer) => {
		return answer.text
	}

	if (question.type?.slug === 'scale') {
		formatAnswerName = (answer) => {
			return `(${answer.value}) ${answer.text}`
		}
	}

	// Handle question with open answer
	const answers = (question.type.slug !== 'open' ? question.answers : [{ text: 'Nb de réponse ouverte' }])

	return {
		table: () => answers,
		headers: [question.text].concat(customerHeaders),
		row(answer) {
			return [
				{ value: formatAnswerName(answer) },
			].concat(customerIds.map((customerId) => {
				const results = surveyResultsByCustomerId[customerId]
				const questionCount = results?.questionCount[question.id]

				if (!questionCount) {
					return { value: '-' }
				}

				if (question.type.slug === 'open') {
					return { value: questionCount }
				}

				const answerCount = (results.answerCount[answer.id + question.id] || 0)

				return {
					value: answerCount,
				}
			}))
		},
	}
}

export default {
	name: 'Sondages',
	content(data) {
		// Create common header for all survey answers tables
		const customerHeaders = []
		const customerIds = []

		// Get survey logs and their customer from user logs
		const customerSurveyLogs = {}

		// Group survey logs by customer and survey id
		data.users.forEach((user) => {
			if (!user.groups || !user.groups.length) {
				return
			}
			const customer = data.customersGroupsById[user.groups[0].id]?.customer

			if (!customer) {
				return
			}

			if (!customerSurveyLogs[customer.id]) {
				customerSurveyLogs[customer.id] = {}

				// Add to common header for all survey answers tables
				const name = [customer.identifier, customer.name].filter(str => str || false).join(' - ') || '~ Client sans titre ou supprimé ~'
				customerHeaders.push(name)
				customerIds.push(customer.id)
			}

			user.logs.forEach((log) => {
				if (log.type.slug === 'activity') {
					if (!customerSurveyLogs[customer.id][log.data_key]) {
						customerSurveyLogs[customer.id][log.data_key] = []
					}

					customerSurveyLogs[customer.id][log.data_key].push(log)
				}
			})
		})

		return data.coursesSurveys.reduce((content, survey) => {
			// Get the survey results for each customer
			const surveyResultsByCustomerId = utils.groupMap(customerSurveyLogs, (surveyLogsById) => {
				return getSurveyResultsFromLogs(surveyLogsById[survey.id] || [])
			})

			// Add the survey name
			const name = [survey.identifier, survey.title].filter(str => str || false).join(' - ')
			content.push({ text: name })
			content.push({})

			// Add a table for each question
			survey.questions.forEach((question) => {
				content.push(getAnswersTableSpec(customerHeaders, question, surveyResultsByCustomerId, customerIds))
				content.push({})

				// Handle question with open answer
				if (question.type.slug === 'open') {
					content.push({ text: 'Réponses ouvertes' })
					customerIds.forEach((customerId) => {
						const results = surveyResultsByCustomerId[customerId]
						const answers = results?.answers[question.id]

						answers?.forEach((answerIds) => {
							answerIds.forEach((answer) => {
								content.push({ cells: ['', answer] })
							})
						})
					})
					content.push({})
				}
			})

			// Add empty lines
			content.push({})
			content.push({})

			return content
		}, [])
	},
}