import utils from "@/utils/trackingReportTemplate"

import userFieldsValues from "@/constants/userFieldsValues"

export default function userProgressionDashboardData(data) {
	// Get user grouped by job and sort job by display order
	const usersByJob = utils.sortKeys(utils.groupBy(data.users, 'job'), userFieldsValues.job.map(job => job.value))

	const userCounts = {
		hasNotStarted: utils.groupMap(usersByJob, users => utils.countMatch(users, user => user.state != 'disabled' && !user.progressionData.hasStarted)),
		isInProgress: utils.groupMap(usersByJob, users => utils.countMatch(users, user => user.state != 'disabled' && user.progressionData.hasStarted && !user.progressionData.hasDoneLevel2)),
		hasDoneLevel1: utils.groupMap(usersByJob, users => utils.countMatch(users, user => user.state != 'disabled' && user.progressionData.hasDoneLevel1)),
		hasDoneLevel2: utils.groupMap(usersByJob, users => utils.countMatch(users, user => user.state != 'disabled' && user.progressionData.hasDoneLevel2))
	}

	// Get confirmed user grouped by job to remove inactive user from average result
	const confirmedUsersByJob = utils.sortKeys(utils.groupBy(data.confirmedUsers, 'job'), userFieldsValues.job.map(job => job.value))

	let hasFinishedInByJob = utils.groupMap(confirmedUsersByJob, (users) => {
		const userCount = users.length

		return {
			oneMonth: (utils.countMatch(users, user => user.progressionData.hasFinishedInOneMonth) / userCount),
			threeMonth: (utils.countMatch(users, user => user.progressionData.hasFinishedInThreeMonth) / userCount),
			oneYear: (utils.countMatch(users, user => user.progressionData.hasFinishedInOneYear) / userCount)
		}
	})

	const userCount = data.confirmedUsers.length
	
	return {
		userCounts,
		hasFinishedIn: {
			oneMonth: (utils.countMatch(data.confirmedUsers, user => user.progressionData.hasFinishedInOneMonth) / userCount),
			threeMonth: (utils.countMatch(data.confirmedUsers, user => user.progressionData.hasFinishedInThreeMonth) / userCount),
			oneYear: (utils.countMatch(data.confirmedUsers, user => user.progressionData.hasFinishedInOneYear) / userCount),
			byJob: hasFinishedInByJob
		}
	}
}