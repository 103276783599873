import utils from "@/utils/trackingReportTemplate"

const now = Date.now()
const oneDay = (1 * 60 * 60 * 1000 * 24)
const oneMonth = (oneDay * 30)
const threeMonth = (oneMonth * 3)
const oneYear = (oneDay * 365)

export default {
	scenarioDoneById(user) {
		// Get scenario done by id
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'complete_scenario') {
				dict[log.data_key] = true
			}

			return dict
		}, {})
	},
	progressionPerGroup(user, data) {
		const defaultCourse = data.courses[0]

		return user.groups.reduce((progressionPerGroup, group) => {
			// Get course from group or take the default one
			const course = (data.groupsById[group.id].course || defaultCourse)

			if (!course) {
				progressionPerGroup[group.id] = 0
				return progressionPerGroup
			}

			let scenarioDone = 0
			let scenarioAvailable = 0

			// Count scenario done and available in this group
			course.modules.forEach((module) => {
				// Count scenario done in this module
				module.scenarios.forEach((scenario) => {
					if (user.scenarioDoneById[scenario.id]) {
						scenarioDone += 1
					}
				})

				// Count scenario available in this module
				scenarioAvailable += module.scenarios.length

				// Count scenario available and done in the module sequences
				module.sequences.forEach((sequence) => {
					// Count scenario done in this sequence
					sequence.scenarios.forEach((scenario) => {
						if (user.scenarioDoneById[scenario.id]) {
							scenarioDone += 1
						}
					})

					// Count scenario available in this sequence
					scenarioAvailable += sequence.scenarios.length
				})
			})

			// Set progression percentage for this group
			if (scenarioAvailable <= 0) {
				progressionPerGroup[group.id] = 0
			} else {
				progressionPerGroup[group.id] = (scenarioDone / scenarioAvailable)
			}

			return progressionPerGroup
		}, {})
	},
	progression(user) {
		// Make an average of user progression per group
		return utils.groupAverage(user.progressionPerGroup, (progression) => progression)
	},
	job(user, data) {
		const metaType = data.metaTypesBySlug['job']

		// Check if the user has a job defined
		if (metaType && user.metas) {
			for (var i = 0; i < user.metas.length; i++) {
				if (user.metas[i].meta_type_id == metaType.id) {
					return user.metas[i].value
				}
			}
		}

		// If no value found return the default one
		return 'other'
	},
	state(user) {
		// Check if the user group is not disabled and still active (start/end date)
		const hasGroup = (user.groups.length > 0)
		let isGroupActive = (hasGroup && !user.groups[0].data.disabled)

		if (isGroupActive) {
			const startDate = new Date(user.groups[0].data.start_date)
			const endDate = new Date(user.groups[0].data.end_date)

			isGroupActive = (now > startDate.getTime() && now < endDate.getTime())
		}

		if (user.disabled || (hasGroup && !isGroupActive)) {
			return 'disabled'
		} else if (hasGroup && isGroupActive && (!user.cognito_id || !user.cognito_confirmed)) {
			return 'inactive'
		} else if (user.cognito_id) {
			if (user.cognito_confirmed) {
				return 'active'
			}

			return 'pending'
		}

		return 'exist'
	},
	progressionData(user, data) {
		// Init computed data
		let computedProgressionData = {
			hasStarted: false,
			hasDoneLevel1: false,
			hasDoneLevel2: false,
			hasFinishedInOneMonth: false,
			hasFinishedInThreeMonth: false,
			hasFinishedInOneYear: false,
			missionLog: {},
			investigationLog: {},
		}

		let firstLogTime = null
		let level2FinishTimestamp = null

		user.logs.forEach((log) => {
			const logTime = (new Date(log.created_at)).getTime()

			if (!firstLogTime || logTime < firstLogTime) {
				firstLogTime = logTime //todo: use group start ?? (if login before that)
			}

			if (!computedProgressionData.hasStarted && log.type.slug == 'activity') {
				computedProgressionData.hasStarted = true
			} else if (log.type.slug == 'module') {
				const missionModule = data.coursesByType.mission.moduleById[log.data_key]

				if (missionModule && !computedProgressionData.missionLog[missionModule.order]) {
					computedProgressionData.missionLog[missionModule.order] = log
				} else {
					const investigationModule = data.coursesByType.investigation.moduleById[log.data_key]

					if (investigationModule && !computedProgressionData.investigationLog[investigationModule.order]) {
						computedProgressionData.investigationLog[investigationModule.order] = log
					}
				}
			} else if (log.type.slug == 'course') {
				// Mark course has done and save timestamp
				if (log.data_key == data.coursesByType.mission.id && !computedProgressionData.level1DoneTimestamp) {
					computedProgressionData.hasDoneLevel1 = true
				} else if (log.data_key == data.coursesByType.investigation.id && !computedProgressionData.level2DoneTimestamp) {
					computedProgressionData.hasDoneLevel2 = true
					level2FinishTimestamp = logTime
				}
			}
		})

		// Check if the user as finished all level, and check when this happened
		if (computedProgressionData.hasDoneLevel1 && 
			computedProgressionData.hasDoneLevel2 && 
			firstLogTime && level2FinishTimestamp) {
			const timeToFinish = (level2FinishTimestamp - firstLogTime)

			if (timeToFinish <= oneMonth) {
				computedProgressionData.hasFinishedInOneMonth = true
			} else if (timeToFinish <= threeMonth) {
				computedProgressionData.hasFinishedInThreeMonth = true
			} else if (timeToFinish <= oneYear) {
				computedProgressionData.hasFinishedInOneYear = true
			}
		}

		return computedProgressionData
	},
	mailingLogs(user) {
		// Create a map of type => log from the user mailer logs
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'mail' && log.data.type) {
				dict[log.data.type] = log
			}

			return dict
		}, {})
	},
	// progressionPerChapter(user, data) {
	// 	// Create a dictionary of chapter progression by their id
	// 	return data.chapters.reduce((dict, chapter) => {
	// 		// Get order of the last visited zone in this chapter
	// 		const lastZoneOrder = user.planche_zone_visiteds.reduce((lastOrder, log) => {
	// 			const order = chapter.zoneOrders[log.planche_zone_id]

	// 			if (order > -1 && lastOrder < order) {
	// 				lastOrder = order
	// 			}

	// 			return lastOrder
	// 		}, -1)

	// 		// Get progression percentage
	// 		dict[chapter.id] = ((lastZoneOrder + 1) / (chapter.zoneOrders.count || 1))

	// 		return dict
	// 	}, {})
	// },
	// progression(user) {
	// 	// Make an average of user progression per chapter
	// 	return utils.groupAverage(user.progressionPerChapter, (progression) => progression)
	// },
	createdWeekId(user) {
		// Generate a uniq week id for the user subscription date
		return utils.weekId(new Date(user.created_at))
	},
	activeTimeData(user) {
		// Init computed data
		let computedTimeData = {
			times: [],
			sum: 0,
			max: 0,
			average: 0,
			weekIds: {},
			monthIds: {},
			yearIds: {},
			lastSessionTimestamp: 0
		}

		const sessionStops = {}

		// Get all event date times
		const times = user.logs.reduce((times, log) => {
			// Exclude "passive" logs
			if (['mail'].indexOf(log.type.slug) > -1) {
				return times
			}
			
			// Add log creation time
			const logTime = (new Date(log.created_at)).getTime()
			times.push(logTime)

			// Register all log time who should always trigger a new session
			if (['login', 'logout'].indexOf(log.type.slug) > -1) {
				sessionStops[logTime] = true
			}

			return times
		}, []).sort()

		// Get all data by spliting user logs in sessions
		const oneHour = (1 * 60 * 60 * 1000) // in 
		const fiveMinutes = (5 * 60 * 1000)

		let lastLogTime = 0

		times.forEach((logTime) => {
			const deltaTime = (logTime - lastLogTime)

			// Check if two logs are too far apart or if we need to create a new session
			if (deltaTime > oneHour || sessionStops[logTime]) {
				// Start a new session with the minimal time (5 min)
				computedTimeData.lastSessionTimestamp = logTime
				computedTimeData.times.push(fiveMinutes)

				// Update sum
				computedTimeData.sum += fiveMinutes

				// Register session week, month and year as active
				const logDate = new Date(logTime)
				
				const weekId = utils.weekId(logDate)
				const monthId = utils.monthId(logDate)
				const yearId = utils.yearId(logDate)

				if (computedTimeData.weekIds[weekId] === undefined) {
					computedTimeData.weekIds[weekId] = true
				}
				if (computedTimeData.monthIds[monthId] === undefined) {
					computedTimeData.monthIds[monthId] = true
				}
				if (computedTimeData.yearIds[yearId] === undefined) {
					computedTimeData.yearIds[yearId] = true
				}
			} else {
				// Increment current session time
				computedTimeData.times[computedTimeData.times.length - 1] += deltaTime

				// Update sum
				computedTimeData.sum += deltaTime
			}

			// Update max
			const sessionTime = computedTimeData.times[computedTimeData.times.length - 1]

			if (sessionTime > computedTimeData.max) {
				computedTimeData.max = sessionTime
			}

			lastLogTime = logTime
		})

		// Compute average time
		if (computedTimeData.times.length > 0) {
			computedTimeData.average = (computedTimeData.sum / computedTimeData.times.length)
		}

		return computedTimeData
	},
	successRatePerGame(user) {
		// Get best success rate for each game played
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'game' && log.data != null) {
				const successRate = (dict[log.data_key] || -1)

				// Only keep best success rate from all attempt
				if (log.data > successRate) {
					dict[log.data_key] = log.data
				}
			}

			return dict
		}, {})
	},
	successRate(user) {
		if (Object.keys(user.successRatePerGame).length <= 0)
			return -1

		// Make an average of user success rate per game
		return utils.groupAverage(user.successRatePerGame, (successRate) => successRate)
	},
	// successRatePerChapter(user, data) {
	// 	// Create a dictionary of average games success rate by chapter id
	// 	return data.chapters.reduce((dict, chapter) => {
	// 		const results = chapter.games.reduce((results, gameId) => {
	// 			const successRate = user.successRatePerGame[gameId]

	// 			if (successRate != undefined) {
	// 				results.sum += successRate
	// 				results.count += 1
	// 			}

	// 			return results
	// 		}, {
	// 			sum: 0,
	// 			count: 0
	// 		})

	// 		// Compute average success rate
	// 		if (results.count > 0) {
	// 			dict[chapter.id] = (results.sum / results.count)
	// 		}

	// 		return dict
	// 	}, {})
	// }
}