<template>
	<div class="customer" v-if="customer">
		<!-- Header -->
		<div class="customer-header">
			<div class="buttons flex flex-row justify-between">
				<router-link class="twn-button text-xs" :to="{ name: 'customer-list' }">
					Retour
				</router-link>

				<div v-if="!isReadOnly" class="buttons-right">
					<button
						@click="deleteCustomer"
						class="twn-button text-xs danger mr-4"
						type="button"
						:disabled="!itemID || hasPendingStoreRequest"
					>
						Supprimer
					</button>

					<button
						@click="duplicateCustomer"
						class="twn-button text-xs mr-4"
						type="button"
						:disabled="!itemID || hasPendingStoreRequest"
					>
						Dupliquer
					</button>

					<button
						@click="saveCustomer"
						class="twn-button text-xs"
						type="button"
						:disabled="hasPendingStoreRequest"
					>
						Enregistrer
					</button>
				</div>
			</div>
		</div>

		<!-- Customer common fields -->
		<div class="pb-6 mb-10 mt-12 border-gray-light border-b-1">
			<!-- Left block -->
			<div class="inline-block w-2/3 align-top pr-4 border-gray-light border-r-1">
				<!-- Identifier -->
				<div class="form-group inline-block w-1/3 pr-2">
					<label for="customer-name" class="uppercase font-principal-medium text-sm">Identifiant</label>
					<b-form-input
						v-model="customer.identifier"
						type="text"
						id="customer-name"
						class="w-full"
						:disabled="isReadOnly"
					></b-form-input>
				</div>

				<!-- Name -->
				<div class="form-group inline-block w-2/3 pl-2">
					<label for="customer-name" class="uppercase font-principal text-sm"
						>Titre</label
					>
					<b-form-input v-model="customer.name" type="text" id="customer-name" class="w-full" :disabled="isReadOnly"></b-form-input>
				</div>

				<!-- Quota -->
				<div class="form-group inline-block w-1/3 pr-2">
					<label for="customer-name" class="uppercase font-principal-medium text-sm">Quota utilisateurs</label>
					<b-form-input
						v-model="customer.quota"
						type="text"
						id="customer-name"
						class="w-full"
						:disabled="isReadOnly"
					></b-form-input>
				</div>
			</div>

			<!-- Right block -->
			<div class="inline-block w-1/3 align-top pl-4">
				<!-- Managers -->
				<label for="module-managers">Responsables</label>

				<!-- List -->
				<div class="manager" v-for="(manager, index) in customer.managers" :key="manager.user_id">
					<span>{{getManagerLabel(usersData[manager.user_id])}}</span>
					<button v-if="!isReadOnly" @click="removeManager(index)"><b-icon-x></b-icon-x></button>
				</div>

				<!-- Add input -->
				<v-select
				id="module-managers"
				placeholder="Ajouter un responsable client"
				:options="managerList"
				:getOptionLabel="getManagerLabel"
				:reduce="manager => manager.id"
				:disabled="isReadOnly"
				v-model="selectedManager"
				@input="addManager($event)" />

				<!-- Supervisors -->
				<label for="module-supervisors" class="mt-4">Superviseurs</label>

				<!-- List -->
				<div class="supervisor" v-for="(supervisor, index) in customer.supervisors" :key="supervisor.user_id">
					<span>{{getSupervisorLabel(usersData[supervisor.user_id])}}</span>
					<button v-if="!isReadOnly" @click="removeSupervisor(index)"><b-icon-x></b-icon-x></button>
				</div>

				<!-- Add input -->
				<v-select
				id="module-supervisors"
				placeholder="Ajouter un superviseur"
				:options="supervisorList"
				:getOptionLabel="getSupervisorLabel"
				:reduce="supervisor => supervisor.id"
				:disabled="isReadOnly"
				v-model="selectedSupervisor"
				@input="addSupervisor($event)" />
			</div>
		</div>

		<div class="mb-4">
			<CustomerGroups
			:loading="isUpdatingGroups"
			:customer="customer"
			:is-read-only="isReadOnly"
			@add-to-group="onAddToGroup"
			@remove-from-group="onRemoveFromGroup" />
		</div>

		<CustomerDashboard v-if="customer && customer.id" :customer="customer" />

		<!-- Modals -->
		<b-modal
			ref="delete-modal-customer"
			class="bootstrap"
			centered
			hide-footer
			id="delete-modal-customer"
			hide-header
		>
			<div class="d-block text-center my-6 uppercase font-semibold">
				<h3>Confirmer la suppression</h3>
			</div>

			<div class="flex flex-row justify-evenly items-center">
				<button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-customer')">
					Retour
				</button>
				
				<button type="button" class="mt-4 twn-button danger" @click="confirmDelete">Supprimer</button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

	import CustomerGroups from "./CustomerGroups"
	import CustomerDashboard from '@/components/dashboard/Customer'

	export default {
		name: 'CustomerDetail',
		mixins: [ dispatchStoreRequest ],
		components: {
			CustomerGroups,
			CustomerDashboard
		},
		props: {
			itemID: {
				type: String,
				required: false,
				default: null
			}
		},
		data() {
			return {
				customer: null,
				selectedManager: null,
				selectedSupervisor: null,
				isUpdatingGroups: false
			}
		},
		computed: {
			...mapState('Auth', [ 'userInfo' ]),
			isSuperAdmin() {
				return (this.userInfo && this.userInfo.role == 'superadmin')
			},
			isUserAdmin() {
				return (this.userInfo && (this.userInfo.role == 'superadmin' || this.userInfo.role == 'customer_manager'))
			},
			isReadOnly() {
				return !this.isSuperAdmin
			},
			...mapState({
				userList: state => state.Utils.userList,
			}),
			usersData() {
				return this.userList.reduce((data, user) => {
					data[user.id] = user

					return data
				}, {})
			},
			managerList() {
				if (!this.customer || !this.usersData)
					return []

				const managerRoles = ['customer_manager']
				const ids = this.customer.managers.map(manager => manager.user_id)

				return this.userList.filter(user => user.managed_groups.length <= 0 && ids.indexOf(user.id) < 0 && managerRoles.indexOf(this.usersData[user.id].role) > -1)
			},
			supervisorList() {
				if (!this.customer || !this.usersData)
					return []

				const supervisorRoles = ['customer_supervisor']
				const ids = this.customer.supervisors.map(supervisor => supervisor.user_id)

				return this.userList.filter(user => ids.indexOf(user.id) < 0 && supervisorRoles.indexOf(this.usersData[user.id].role) > -1)
			}
		},
		watch: {
			itemID: {
				async handler(id) {
					// Load current customer data, if needed
					if (id) {
						// Get customer from store
						// todo: handle invalid uuid response
						await this.dispatchStoreRequest('Customers/getByID', id, true)

						// Create a deep local copy of the store data
						this.customer = JSON.parse(JSON.stringify(this.$store.state.Customers.items[id]))

						// todo: common/cleaner system
						document.title = process.env.VUE_APP_PAGE_TITLE + ' - ' + [this.customer.identifier, this.customer.name].join(' - ')
					}
				},
				immediate: true
			}
		},
		async created() {
			if (!this.itemID) {
				// Create a new customer
				this.customer = {
					identifier: '',
					name: 'Nouveau client',
					managers: [],
					supervisors: [],
				}
			}
		},
		async mounted() {
			await this.dispatchStoreRequest('Promotion/getList')
		},
		methods: {
			getManagerLabel(manager) {
				if (!manager)
					return 'Utilisateur supprimé'

				return [manager.first_name, manager.name].filter(str => str || false).join(' ') + ' - ' + manager.email
			},
			addManager(user_id) {
				if (!user_id)
					return

				this.customer.managers.push({
					user_id
				})

				this.$nextTick(() => this.selectedManager = null)
			},
			removeManager(index) {
				if (this.customer.managers.length <= 0)
					return

				this.customer.managers.splice(index, 1)
			},
			getSupervisorLabel(supervisor) {
				if (!supervisor)
					return 'Utilisateur supprimé'

				return [supervisor.first_name, supervisor.name].filter(str => str || false).join(' ') + ' - ' + supervisor.email
			},
			addSupervisor(user_id) {
				if (!user_id)
					return

				this.customer.supervisors.push({
					user_id
				})

				this.$nextTick(() => this.selectedSupervisor = null)
			},
			removeSupervisor(index) {
				if (this.customer.supervisors.length <= 0)
					return

				this.customer.supervisors.splice(index, 1)
			},
			async duplicateCustomer(){
				this.customer.name = this.customer.name + ' (copie)'

				// Remove fields used for update format
				delete this.customer.id

				this.customer.managers.forEach(manager => {
					delete manager.customer_id
				})

				this.customer.supervisors.forEach(supervisor => {
					delete supervisor.customer_id
				})

				this.$router.push({
					name: 'customer-edit'
				})
			},
			async saveCustomer() {
				const response = await this.dispatchStoreRequest("Customers/save", this.customer)

				this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })

				if (!this.itemID && response.id) {
					this.$router.push({
						name: 'customer-edit',
						params: {
							itemID: response.id
						}
					})
				}
			},
			deleteCustomer() {
				if (this.itemID) {
					this.$refs["delete-modal-customer"].show()
				}
			},
			async confirmDelete() {
				await this.dispatchStoreRequest("Customers/delete", this.customer.id)

				this.$router.push({ name: 'customer-list' })
			},
			async onAddToGroup(groupID) {
				if (this.isUpdatingGroups)
					return

				this.isUpdatingGroups = true

				await this.dispatchStoreRequest("Customers/addToGroup", {
					id: this.customer.id,
					groupID
				})

				this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })

				this.customer.groups.unshift({ id: groupID })

				this.isUpdatingGroups = false
			},
			async onRemoveFromGroup(groupID) {
				if (this.isUpdatingGroups)
					return

				this.isUpdatingGroups = true

				await this.dispatchStoreRequest("Customers/removeFromGroup", {
					groupID
				})

				this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })

				for (var i = 0; i < this.customer.groups.length; i++) {
					if (this.customer.groups[i].id == groupID) {
						this.customer.groups.splice(i, 1)
						break
					}
				}

				this.isUpdatingGroups = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	.manager {
		@apply w-full flex bg-gray-background px-3 py-1 mb-2;

		span {
			@apply flex-grow;
		}

		button {
			@apply text-red-700;
		}
	}

	.supervisor {
		@apply w-full flex bg-gray-background px-3 py-1 mb-2;

		span {
			@apply flex-grow;
		}

		button {
			@apply text-red-700;
		}
	}
</style>