<template>
	<div v-if="game">
		<!-- Table -->
		<div class="uppercase font-principal-bold text-sm">Tableau {{ game.type.name }}</div>

		<GameTable
			:game="game"
			:hasPictureOnLineHeaders="game.type.slug == 'dragdrop_picture'"
			:canAddColumn="false"
			@add-column="addColumn"
			@add-line="addLine"
			@update-header="updateHeader"
			@delete-column="deleteColumn"
			@delete-line="deleteLine"
			:assets="assets"
		>
			<template slot-scope="{ question }">
				<div
					v-if="question && question.answers && question.answers.length > 0"
					class="h-full flex-row flex-wrap flex-center w-full cursor-pointer"
					@click="checkAnswer(question)"
				>
					<div class="m-2 flex flex-row flex-center" v-for="answer in question.answers" :key="answer.id">
						<label class="switch">
							<input type="checkbox" :checked="answer.is_correct" @click="checkAnswer(question)" />
							<span class="slider round"></span>
						</label>
					</div>
				</div>
			</template>
		</GameTable>
	</div>
</template>

<script>
import GameTable from "@/components/common/game/Table"
import {mapState } from 'vuex'

export default {
	name: "DragDrop",
	components: {
		GameTable,
	},
	props: {
		game: {
			type: Object,
			required: true,
			default: null
		}
	},
	data() {
		return {}
	},
	created() {
		if (this.questions.length <= 0) {
			this.addLine({ lineCount: 0, columnCount: 0 })
			this.addColumn({ lineCount: 1, columnCount: 0 })
			this.addColumn({ lineCount: 1, columnCount: 1 })
		}
	},
	computed: {
		...mapState("Assets", {
			assets(state) {
				if (!state.assets || state.assets.length <= 0) return {}

				return state.assets.reduce((dict, asset) => {
					dict[asset.id] = asset

					return dict
				}, {})
			}
		}),
		questions() {
			if (!this.game) return []
			return JSON.parse(JSON.stringify(this.game.questions))
		},
		correction:{
			get(){
				return this.game.correction
			},
			set(value){
				this.$emit('update-correction', value)
			}
		},
	},
	methods: {
		addLine({ lineCount, columnCount }) {
			this.$emit("add-headers", [
				{
					content: "",
					line: lineCount,
					column: null
				}
			])

			let questions = []

			for (var i = 0; i < columnCount; i++) {
				questions.push({
					answers: [
						{
							text: "",
							is_correct: false
						}
					],
					text: "",
					column: i,
					line: lineCount,
					correction: ""
				})
			}

			this.$emit("add-questions", questions)
		},
		addColumn({ lineCount, columnCount }) {
			this.$emit("add-headers", [
				{
					content: "",
					line: null,
					column: columnCount
				}
			])

			let questions = []

			for (var i = 0; i < lineCount; i++) {
				questions.push({
					answers: [
						{
							text: "",
							is_correct: false
						}
					],
					text: "",
					column: columnCount,
					line: i,
					correction: ""
				})
			}

			this.$emit("add-questions", questions)
		},
		deleteLine(line) {
			// Find header for this line and remove it
			let headerIndex = null

			for (var i = 0; i < this.game.headers.length; i++) {
				if (this.game.headers[i].line == line) {
					headerIndex = i
				} else if (this.game.headers[i].line > line) {
					const header = this.game.headers[i]

					this.$emit("update-header", i, {
						line: header.line - 1
					})
				}
			}

			if (headerIndex != null) {
				this.$emit("delete-headers", [headerIndex])
			}

			// Find questions on this line and remove them
			let questionIndexes = []

			for (var j = 0; j < this.game.questions.length; j++) {
				if (this.game.questions[j].line == line) {
					questionIndexes.push(j)
				} else if (this.game.questions[j].line > line) {
					const question = this.game.questions[j]

					this.$emit("update-question", j, {
						line: question.line - 1
					})
				}
			}

			this.$emit("delete-questions", questionIndexes)
		},
		deleteColumn(column) {
			// Find header for this column and remove it
			let headerIndex = null

			for (var i = 0; i < this.game.headers.length; i++) {
				if (this.game.headers[i].column == column) {
					headerIndex = i
				} else if (this.game.headers[i].column > column) {
					const header = this.game.headers[i]

					this.$emit("update-header", i, {
						column: header.column - 1
					})
				}
			}

			if (headerIndex != null) {
				this.$emit("delete-headers", [headerIndex])
			}

			// Find questions on this column and remove them
			let questionIndexes = []

			for (var j = 0; j < this.game.questions.length; j++) {
				if (this.game.questions[j].column == column) {
					questionIndexes.push(j)
				} else if (this.game.questions[j].column > column) {
					const question = this.game.questions[j]

					this.$emit("update-question", j, {
						column: question.column - 1
					})
				}
			}

			this.$emit("delete-questions", questionIndexes)
		},
		updateHeader(i, data) {
			this.$emit("update-header", i, data)
		},
		checkAnswer(question) {
			let answers = [...question.answers]

			let questionID = this.game.questions.indexOf(question)
			answers[0].is_correct = answers[0].is_correct ? false : true
			this.$emit("update-question", questionID, {
				answers
			})
		}
	}
}
</script>

<style lang="scss">
/* The switch - the box around the slider */
.switch {
	@apply text-center cursor-pointer;
	position: relative;
	display: inline-block;
	width: 50px;
	height: 24px;
	margin-bottom: 0;
}

.switch input[type="radio"] {
	@apply appearance-none border-2 rounded-full w-4 h-4 cursor-pointer;
	border-color: #CCC;

	&:checked {
		@apply bg-red border-red;
	}
}

/* Hide default HTML checkbox */
.switch input[type="checkbox"] {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 16px;
	width: 16px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	@apply bg-principal-selected;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 24px;
}

.slider.round:before {
	border-radius: 50%;
}
</style>
