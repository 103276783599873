<template>
	<div class="dashboard-main">
		<!-- Header -->
		<div class="flex items-center justify-between">
			<div>
				<Toggle
				v-if="isSuperAdmin"
				:checked="includeOtherRoles"
				:onInput="() => includeOtherRoles = !includeOtherRoles"></Toggle>
				<span v-if="isSuperAdmin" class="ml-2">Inclure les admins/responsables clients</span>

				<template v-if="isUserManager && managedCustomer">
					<span>Client : {{ managedCustomer.name }}</span>
					<br>
					<span>Référent : {{ userInfo.first_name }} {{ userInfo.last_name }} ({{ userInfo.email }})</span>
				</template>
			</div>
			<button type="button" class="mb-4 twn-button" @click="ExportReport" v-bind:disabled="isLoading">
				<span v-if="!isLoading">
					Exporter le rapport
				</span>
				<span v-else>
					Chargement...
				</span>
			</button>
		</div>

		<!-- Dashboard -->
		<p v-if="isLoading || !dashboardData">
			Chargement...
		</p>
		<div v-else>
			<!-- Users count and state data -->
			<CategoryBlock title="Utilisateurs">
				<!-- KPIs -->
				<div class="inline-block w-full align-top pr-8">
					<div class="dashboard-main-kpis">
						<KPIBlock
							title="Nombre de promotions en cours"
							:number="dashboardData.groupsData.counts.active"
						/>
						<KPIBlock
							title="Durée totale de connexion"
							:time="dashboardData.userActivityDashboardData.activeTime.sum.all"
						/>
						<KPIBlock
							title="Temps moyen passé par connexion"
							:time="dashboardData.userActivityDashboardData.activeTime.average.all"
						/>
					</div>

					<div class="dashboard-main-kpis">
						<KPIBlock
						title="Utilisateurs inscrits"
						help="Nombre total d'utilisateurs tous statuts confondus"
						:number="dashboardData.usersDashboardData.total" />
						<KPIBlock
						title="Utilisateurs en attente"
						help="Utilisateurs qui n'ont pas encore reçu leur mail d'invitation"
						:number="dashboardData.usersDashboardData.pending" />
						<KPIBlock
						title="Utilisateurs suspendus"
						:number="dashboardData.usersDashboardData.disabled" />
					</div>
				</div>
			</CategoryBlock>

			<!-- Users activity data -->
			<CategoryBlock title="Données de connexion">
				<!-- Active users -->
				<SubCategoryBlock class="dashboard-main-active-user mb-8" title="Nombre d'utilisateurs actifs par">
					<div class="inline-block ml-6">
						<span class="mr-2">Mois</span>
						<Toggle
						:checked="!showUserActivityByMonth"
						:onInput="() => showUserActivityByMonth = !showUserActivityByMonth"></Toggle>
						<span class="ml-2">Années</span>
					</div>

					<LineChart :data="dashboardData.userActivityDashboardData.activeCount[(showUserActivityByMonth ? 'months' : 'years')]" />
				</SubCategoryBlock>
			</CategoryBlock>

			<!-- Common survey results -->
			<CategoryBlock title="Résultats des sondages">
				<div class="dashboard-main-surveys">
					<p v-if="!dashboardData.coursesSurveys.length">
						Aucun résultats pour le moment
					</p>
					<SurveyResultsBlock
						v-for="(survey, index) in dashboardData.coursesSurveys"
						:key="survey.id"
						:survey="survey"
						:class="{ 'mb-8': index < dashboardData.coursesSurveys.length - 2 }"
					/>
				</div>
			</CategoryBlock>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	import trackingReport from '@/utils/trackingReport'

	import userFieldsValues from "@/constants/userFieldsValues"
	import template from "@/constants/mainTrackingReportTemplate"

	import Toggle from '@/components/Toggle'

	import CategoryBlock from "./block/Category"
	import SubCategoryBlock from "./block/SubCategory"
	import KPIBlock from "./block/KPI"
	import SurveyResultsBlock from "./block/SurveyResults";
	import LineChart from "./chart/Line"

	export default {
		name: 'MainDashboard',
		components: {
			Toggle,
			CategoryBlock,
			SubCategoryBlock,
			KPIBlock,
			SurveyResultsBlock,
			LineChart,
		},
		data() {
			return {
				isLoading: false,
				dashboardData: null,
				includeOtherRoles: false,
				showUserActivityByMonth: true,
				jobs: userFieldsValues.job.reduce((dict, job) => {
					dict[job.value] = (job.labelShort || job.label)

					return dict
				}, {})
			}
		},
		computed: {
			...mapState('Auth', [ 'userInfo' ]),
			isSuperAdmin() {
				return (this.userInfo && this.userInfo.role == 'superadmin')
			},
			isUserManager() {
				return (this.userInfo && this.userInfo.role == 'customer_manager')
			},
			userCountsLabels() {
				const keys = Object.keys(this.dashboardData.userProgressionDashboardData.userCounts)

				const labels = {
					hasNotStarted: 'utilisateurs inactifs',
					isInProgress: 'utilisateurs en cours',
					hasDoneLevel1: 'niveau 01 terminé',
					hasDoneLevel2: 'niveau 02 terminé'
				}

				return keys.map((key) => (labels[key] || key))
			},
			hasFinishedInLabels() {
				return ['J+1 mois', 'J+3 mois', 'J+1 an']
			},
			managedCustomer() {
				if (!this.isUserManager) {
					return null
				}

				return (this.userInfo && this.userInfo.managed_groups?.[0]?.customer) || null
			},
		},
		watch: {
			async includeOtherRoles(includeOtherRoles) {
				this.isLoading = true

				// Load tracking data
				let payload = null

				if (includeOtherRoles) {
					payload = { roles: ['user', 'superadmin', 'customer_manager'] }
				}

				this.dashboardData = await trackingReport.loadData(this.$store, template, payload)

				this.isLoading = false
			}
		},
		async mounted() {
			this.isLoading = true

			// Load tracking data
			this.dashboardData = await trackingReport.loadData(this.$store, template)

			this.isLoading = false
		},
		methods: {
			inlineBarChartStyle(index, data) {
				if (index > 0)
					return ''

				return 'width: calc(' + (100 / Object.keys(data).length) + '% + 100px);'
			},
			async ExportReport() {
				this.isLoading = true
				
				try {
					await trackingReport.export('', this.$store, template, null, this.dashboardData)
				} catch (err) {
					// console.error(err)
				}

				this.isLoading = false
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-main {
		@apply max-w-screen-lg mx-auto;

		&-kpis {
			@apply flex flex-wrap justify-between;

			&:first-child {
				@apply mb-8;
			}
		}

		.dashboard-category {
			@apply w-full mb-5;

			.dashboard-kpi {
				@apply flex-1 mx-4;

				&:first-child {
					@apply ml-0;
				}

				&:last-child {
					@apply mr-0;
				}
			}
		}

		&-active-user {
			& > p {
				display: inline-block;
			}
		}

		&-finish-by-job {
			@apply flex;
			padding-left: 100px;

			.dashboard-chart {
				@apply pr-8 mb-3;
			}

			& > div:first-child {
				margin-left: -100px;

				p.ct-label {
					margin-left: 100px;
				}
			}

			p.ct-label {
				@apply text-dashboard-blue-dark;

				font-style: italic;
			}
		}

		&-surveys {
			@apply flex flex-wrap justify-between;

			& > div {
				width: calc(50% - 1rem);

				&:nth-child(odd) {
					margin-right: 1rem;
				}

				&:nth-child(even) {
					margin-left: 1rem;
				}
			}
		}
	}
</style>