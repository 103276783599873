<template>
	<div class="dashboard-subcategory">
		<p>{{title}}</p>
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'SubCategoryBlock',
		props: {
			title: {
				type: String,
				required: true
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-subcategory {
		@apply bg-white rounded-dashboard-sm p-8;

		& > p {
			@apply text-lg text-black uppercase mb-2;
		}
	}
</style>