<template>
	<div class="w-full">
		<div class="w-full flex">
			<!-- Common fields and metas -->
			<div class="left-block flex-grow w-2/3 my-4 border-gray-light border-r-1">
				<div class="w-1/5 pr-4">
					<label for="module-name">Identifiant</label>
					<input type="text" id="module-name" class="block twn-input w-full" v-model="identifier">
				</div>

				<div class="w-3/5 px-4">
					<label for="module-title">Titre</label>
					<input type="text" id="module-title" class="block twn-input w-full" v-model="title">
				</div>

				<div class="w-1/5 px-4">
					<label for="module-title">Durée (min)</label>
					<input type="text" id="module-title" class="block twn-input w-full" :value="getMetaValue('duration', 0)" @input="setMetaValue('duration', $event.target.value)">
				</div>
				
				<div class="w-1/2 pr-4 mt-4">
					<label for="module-thumbnail">Vignette (écran de sélection des missions)</label>
					<v-select
					id="module-thumbnail"
					placeholder="Rechercher une image..."
					:options="pictureList"
					:getOptionLabel="getOptionLabel"
					:reduce="medium => medium.id"
					:value="getMetaValue('thumbnail')"
					@input="setMetaValue('thumbnail', $event)" />
				</div>
				
				<div class="w-1/2 px-4 mt-2">
					<label for="module-narrator-intro">Narrateur - Introduction</label>
					<v-select
					id="module-narrator-intro"
					placeholder="Rechercher une narration..."
					:options="narrativeList"
					:getOptionLabel="getOptionLabel"
					:reduce="medium => medium.id"
					:value="getMetaValue('narrator_intro')"
					@input="setMetaValue('narrator_intro', $event)" />
				</div>

				<div class="w-1/2 pr-4 mt-2">
					<label for="module-cover">Cover (écran d’accueil de la mission)</label>
					<v-select
					id="module-cover"
					placeholder="Rechercher une image..."
					:options="pictureList"
					:getOptionLabel="getOptionLabel"
					:reduce="medium => medium.id"
					:value="getMetaValue('cover')"
					@input="setMetaValue('cover', $event)" />
				</div>
				
				<div class="w-1/2 px-4 mt-2">
					<label for="module-narrator-outro">Narrateur - Épilogue</label>
					<v-select
					id="module-narrator-outro"
					placeholder="Rechercher une narration..."
					:options="narrativeList"
					:getOptionLabel="getOptionLabel"
					:reduce="medium => medium.id"
					:value="getMetaValue('narrator_outro')"
					@input="setMetaValue('narrator_outro', $event)" />
				</div>

				<div class="w-1/2 pr-4 mt-2">
					<label for="module-illustration">Illustration principale (écran de sélection des actes)</label>
					<v-select
					id="module-illustration"
					placeholder="Rechercher une image..."
					:options="pictureList"
					:getOptionLabel="getOptionLabel"
					:reduce="medium => medium.id"
					:value="getMetaValue('illustration')"
					@input="setMetaValue('illustration', $event)" />
				</div>

				<div class="w-full pr-4 mt-2">
					<label for="module-text-outro">Texte de félicitations en fin d’enquête</label>
					<textarea
						id="module-text-outro"
						class="w-full twn-input"
						rows="3"
						:value="getMetaValue('text_outro')"
						@input="setMetaValue('text_outro', $event.target.value)"
					/>
				</div>

				<p class="text-xl mt-4">Scores à atteindre pour cette enquête :</p>
				
				<div
				v-for="(type, index) in scoreTypeList"
				:key="type.id"
				class="w-1/4 pr-4 mt-2"
				:class="{ 'pl-4': (index > 0) }">
					<label :for="'module-score-type-' + type.slug">Jauge {{type.name}}</label>
					<input type="text" :id="'module-score-type-' + type.slug" class="block twn-input w-full" :value="getMetaValue(type.slug, 0)" @input="setMetaValue(type.slug, $event.target.value)">
				</div>
			</div>

			<!-- Documents -->
			<div class="w-1/3 my-4 pl-4">
				<label for="module-documents">Documentation</label>

				<!-- List -->
				<div class="document" v-for="(medium, index) in media" :key="medium.medium_id">
					<span>{{getOptionLabel(mediaData[medium.medium_id])}}</span>
					<button @click="removeDocument(index)"><b-icon-x></b-icon-x></button>
				</div>

				<!-- Add input -->
				<v-select
				id="module-documents"
				placeholder="Ajouter un document"
				:options="documentList"
				:getOptionLabel="getOptionLabel"
				:reduce="medium => medium.id"
				v-model="selectedDocument"
				@input="addDocument($event)" />
			</div>
		</div>

		<!-- Catch-up -->
		<div class="w-full">
			<p class="text-xl font-principal-medium mt-2">Rattrapage</p>

			<div class="inline-block w-1/3 pr-4 mt-2">
				<label for="module-catch-up-name">Identifiant</label>
				<input type="text" id="module-catch-up-name" class="block twn-input w-full" :value="getMetaValue('catch_up_identifier')" @input="setMetaValue('catch_up_identifier', $event.target.value)">
			</div>

			<div class="inline-block w-2/3 px-4">
				<label for="module-catch-up-title">Titre</label>
				<input type="text" id="module-catch-up-title" class="block twn-input w-full" :value="getMetaValue('catch_up_title')" @input="setMetaValue('catch_up_title', $event.target.value)">
			</div>

			<!-- Scenarios -->
			<label class="w-full text-lg mt-2" for="module-catch-up" :class="{ 'mb-2': (scenarios && scenarios.length) }">Scénarios</label>

			<!-- List -->
			<draggable v-model="scenarios">
				<div class="scenario" v-for="(scenario, index) in scenarios" :key="scenario.scenario_id">
					<div><b-icon-justify /></div>
					<span>{{getOptionLabel(scenarioData[scenario.scenario_id])}}</span>
					<button @click="removeScenario(index)"><b-icon-x></b-icon-x></button>
				</div>
			</draggable>

			<!-- Add input -->
			<v-select
			id="module-catch-up"
			:class="{ 'mt-4': (scenarios && scenarios.length) }"
			placeholder="Ajouter un scénario"
			:options="scenarioList"
			:getOptionLabel="getOptionLabel"
			:reduce="scenario => scenario.id"
			v-model="selectedScenario"
			@input="addScenario($event)" />
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	import Draggable from "vuedraggable"

	export default {
		name: 'MissionModuleForm',
		components: {
			Draggable
		},
		props: {
			module: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				selectedDocument: null,
				selectedScenario: null
			}
		},
		computed: {
			...mapState({
				scoreTypeList: state => state.Games.jauges,
				pictureList: state => state.Media.list.filter((doc) => doc.type.slug == 'image'),
				narrativeList: state => state.Narratives.list,
				documentMedia: state => state.Media.list.filter((doc) => doc.type.slug == 'documentation'),
				metaTypes: state => {
					return state.Utils.metaTypeList.reduce((dict, meta) => {
						dict[meta.slug] = meta
						return dict
					}, {})
				}
			}),
			identifier: {
				get() {
					return this.module.identifier
				},
				set(value) {
					this.$emit('update-module', {
						identifier: value
					})
				}
			},
			title: {
				get() {
					return this.module.title
				},
				set(value) {
					this.$emit('update-module', {
						title: value
					})
				}
			},
			metas() {
				return [...this.module.metas]
			},
			media() {
				return [...this.module.media]
			},
			mediaData() {
				return this.documentMedia.reduce((data, medium) => {
					data[medium.id] = medium

					return data
				}, {})
			},
			documentList() {
				const ids = this.media.map(medium => medium.medium_id)

				return this.documentMedia.filter(doc => ids.indexOf(doc.id) < 0)
			},
			scenarios: {
				get() {
					return [...this.module.scenarios]
				},
				set(scenarios) {
					// Make sure order field is correct
					scenarios.forEach((scenario, index) => {
						scenario.order = index
					})

					this.$emit('update-module', {
						scenarios
					})
				}
			},
			scenarioData() {
				return this.$store.state.Scenarios.list.reduce((data, scenario) => {
					data[scenario.id] = scenario

					return data
				}, {})
			},
			scenarioList() {
				const ids = this.scenarios.map(scenario => scenario.scenario_id)

				return this.$store.state.Scenarios.list.filter(scenario => ids.indexOf(scenario.id) < 0)
			}
		},
		methods: {
			getOptionLabel(option) {
				if (!option)
					return '~ Élément supprimé ~'

				return [option.identifier, option.name, option.title].filter(str => str || false).join(' - ') || '~ Élément sans titre ou supprimé ~'
			},
			getMeta(slug) {
				if (!this.metaTypes[slug])
					return { meta: { value: '~ Meta value error ~' }, index: -1 }

				for (var i = 0; i < this.metas.length; i++) {
					if (this.metas[i].meta_type_id == this.metaTypes[slug].id) {
						return { meta: this.metas[i], index: i }
					}
				}
				
				return { meta: null, index: -1 }
			},
			getMetaValue(slug, defaultValue = null) {
				const { meta } = this.getMeta(slug)

				return ((meta && meta.value) || defaultValue)
			},
			setMetaValue(slug, value) {
				let { meta, index } = this.getMeta(slug)

				if (meta) {
					if (value != null) {
						meta.value = value
					} else {
						this.metas.splice(index, 1)
					}
				} else if (value) {
					this.metas.push({
						value,
						meta_type_id: this.metaTypes[slug].id
					})
				}

				this.$emit('update-module', {
					metas: this.metas
				})
			},
			addDocument(medium_id) {
				if (!medium_id)
					return

				this.media.push({
					medium_id,
					order: this.media.length
				})

				this.$emit('update-module', {
					media: this.media
				})

				this.$nextTick(() => this.selectedDocument = null)
			},
			removeDocument(index) {
				if (this.media.length <= 0)
					return

				// Remove document
				this.media.splice(index, 1)
				
				// Update order fields
				this.media.forEach((sequence, i) => {
					if (i >= index){
						sequence.order--
					}
				})

				this.$emit('update-module', {
					media: this.media
				})
			},
			addScenario(scenario_id) {
				if (!scenario_id)
					return

				this.scenarios.push({
					scenario_id,
					order: this.scenarios.length
				})

				this.$emit('update-module', {
					scenarios: this.scenarios
				})

				this.$nextTick(() => this.selectedScenario = null)
			},
			removeScenario(index) {
				if (this.scenarios.length <= 0)
					return

				// Remove document
				this.scenarios.splice(index, 1)
				
				// Update order fields
				this.scenarios.forEach((scenario, i) => {
					if (i >= index){
						scenario.order--
					}
				})

				this.$emit('update-module', {
					scenarios: this.scenarios
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.left-block > div {
		@apply inline-block;
	}

	.document, .scenario {
		@apply w-full flex items-center bg-gray-background mb-2;

		div {
			@apply px-2 py-1 cursor-grab;
		}

		span {
			@apply flex-grow;
		}

		button {
			@apply px-3 py-1 text-red-700;
		}
	}

	.document span {
		@apply pl-3;
	}
</style>