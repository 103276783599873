<template>
  <section class="scenario-game d-flex">
    <div class="dragger d-flex items-center justify-center ">
      <b-icon-list class="h3 mb-0"></b-icon-list>
    </div>
    <div class="order d-flex items-center justify-center ">
      <p>{{index}}</p>
    </div>
    <div class="game p-4 d-flex items-center">
    
      <p class="mr-2">{{currentGame.name || currentGame.identifier}}</p>
      <span class="mr-2">-</span>
      <p class="mr-2">{{currentGame.title}}</p>
      <span v-if="currentGame.type && currentGame.type.name" class="mr-2">-</span>
      <p class="mr-2">{{currentGame.type && currentGame.type.name}}</p>
      <button
          type="button"
          class="twn-button text-sm ml-auto"
          @click="selectGame"
        >
          Modifier
        </button>
    </div>
    <div class="remove d-flex items-center justify-center" @click="$emit('remove-game', node.id)">
      <b-icon-x></b-icon-x>
    </div>


  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['node', 'index'],
  computed: {
    ...mapState({
        gameList: state => state.Games.list,
        narrativeList: state => state.Narratives.list,
        videoList: state => state.Media.list.filter((media) => {
          return (media.type.slug === 'video')
        }),
    }),
    currentGame() {
      if (this.node.nodeType.slug == 'video') {
        return this.videoList.find(video => video.id === this.node.node_content) || {}
      } else if (this.node.nodeType.slug == 'narrative') {
        return this.narrativeList.find(narrative => narrative.id === this.node.node_content) || {}
      }

      return this.gameList.find(game => game.id === this.node.node_content) || {}
    }
  },
  methods: {
    selectGame() {
      // Use custom event to make them available outside of the current Vue.js context 
      this.$emit('select-game', this.node)
    },
  },
}
</script>

<style lang="scss">
  .scenario-game {
    width: 100%;
    .dragger, .order {
      width: 4%;
    }
    .order {
      background: #FAEFF0;
    }
    .remove{
      width: 4%;
      background: #DC9799
;
    }
    .game {
      width: 88%;
    }
  }
</style>