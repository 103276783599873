import axios from 'axios'

import apollo from '@/apolloClient'

import {
    GC_GET_USER_LIST,
    GC_GET_USER_BY_ID,
    GC_ADD_USER_ONE,
    GC_UPDATE_USER_BY_ID,
    GC_DELETE_USER_BY_ID,
    GC_ADD_MULTIPLE_USER,
    GC_UPDATE_USER_COGNITO_ID,
    GC_UPDATE_MULTIPLE_USER_COGNITO_ID,
} from '@/graphql/user'

const authURL = process.env.VUE_APP_API_URL + '/auth'

export default {
    namespaced: true,
    state: {
        list: [],
        items: {},
    },
    mutations: {
        SET_USER_LIST(state, users) {
            state.list = (users || []);
        },
        SET_USER(state, user) {
            if (!user || !user.id)
                return

            state.items[user.id] = user
        },
    },
    actions: {
        // Return default user data object
        getDefault() {
            return {
                name: '',
                first_name: '',
                email: '',
                role: 'user',
                disabled: false,
                metas: [],
                groups: [],
            }
        },
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_USER_LIST })

            commit('SET_USER_LIST', response.data.user)

            return response.data.user
        },
        async getByID({ commit }, id) {
            let response = await apollo.query({
                query: GC_GET_USER_BY_ID,
                variables: { id }
            })

            if (!response.data.user_by_pk) {
                return
            }

            commit('SET_USER', response.data.user_by_pk)
        },
        resetPassword(context, email) {
            return axios.post(
                authURL + '/reset',
                {
                    username: email,
                }
            )
        },
        registerCognitoUser(context, email) {
            return axios.post(
                authURL + '/adminregister',
                {
                    email,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + context.rootGetters['Auth/userToken']
                    }
                }
            )
        },
        async registerExistingUser(context, { userId, email }) {
            try {
                const response = await context.dispatch('registerCognitoUser', email)

                if (!response.data.message) {
                    return false
                }

                await apollo.mutate({
                    mutation: GC_UPDATE_USER_COGNITO_ID,
                    variables: {
                        id: userId,
                        cognito_id: response.data.message,
                    }
                })
            } catch (error) {
                return {
                    error
                }
            }
        },
        async save(context, data) {
            let response = null
            let result = {}

            // Update or add the user
            if (data.user.id) {
                // Remove email and cognito status for update
                delete data.user.email
                delete data.user.cognito_confirmed

                // Format metas
                data.user.metas = data.user.metas.map((meta) => {
                    meta.user_id = data.user.id

                    return meta
                })

                // Format groups
                data.user.groups = data.user.groups.map((group) => {
                    return {
                        group_id: group.id,
                        user_id: data.user.id
                    }
                })

                // Update remote data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_USER_BY_ID,
                    variables: data.user
                })

                result.success = true
            } else {
                data.user.cognito_id = null

                // Format email for futur uniq check
                data.user.email = data.user.email.toLowerCase()

                // Register user if needed
                if (['superadmin', 'customer_manager', 'customer_supervisor'].indexOf(data.user.role) > -1) {
                    try {
                        response = await context.dispatch('registerCognitoUser', data.user.email)
                    } catch (error) {
                        return {
                            error
                        }
                    }
                    
                    data.user.cognito_id = response.data.message
                }

                // Format metas
                data.user.metas = data.user.metas.map((meta) => {
                    delete meta.id
                    delete meta.user_id

                    return meta
                })

                // Format groups
                data.user.groups = data.user.groups.map((group) => {
                    return {
                        group_id: group.id
                    }
                })

                // Add remote data
                response = await apollo.mutate({
                    mutation: GC_ADD_USER_ONE,
                    variables: data.user
                })

                result.id = response.data.insert_user_one.id

                // Add mail log if needed
                if (['superadmin', 'customer_manager', 'customer_supervisor'].indexOf(data.user.role) > -1) {
                    await context.dispatch('Logs/addUserLog', {
                        userId: result.id,
                        id: null,
                        data: { type: 'access-account' },
                        type: 'mail',
                    }, { root: true })
                }
            }

            await context.dispatch('Utils/getUsers', null, { root: true })

            return result
        },
        async delete(context, user) {
            if (!user.id)
                return {
                    error: true
                }

            let response = null

            try {
                response = await apollo.mutate({
                    mutation: GC_DELETE_USER_BY_ID,
                    variables: { id: user.id }
                })

                // Delete user from auth api if needed
                if (user.cognito_id) {
                    response = await axios.post(
                        authURL + '/admindelete',
                        {
                            username: user.cognito_id
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + context.rootGetters['Auth/userToken']
                            }
                        }
                    )
                }
            } catch (error) {
                return {
                    error
                }
            }

            await context.dispatch('Utils/getUsers', null, { root: true })

            return response.data.delete_users_by_pk
        },
        async importUsers({ dispatch, rootState }, { list: usersList, info, sendInviteEmails }) {
            // Format user data for GQL mutation
            const users = usersList.map((userData) => {
                const user = {
                    email: userData.email.toLowerCase(),
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    role: 'user',
                    metas: { data: [] },
                    groups: { data: [] },
                    cognito_id: null,
                }

                // Add group if needed
                if (userData.group_id) {
                    user.groups.data.push({
                        group_id: userData.group_id
                    })
                }

                return user
            })

            // Execute GQL mutation
            let response = null

            try {
                response = await apollo.mutate({
                    mutation: GC_ADD_MULTIPLE_USER,
                    variables: {
                        users
                    }
                })
            } catch (error) {
                // Handle uniq email constraint errors
                if (error.graphQLErrors[0].extensions.code === "constraint-violation") {
                    // Default error message
                    let message = 'Une ou plusieurs adresses email sont déjà utilisé pour une inscription veuillez les retirer du document'

                    // Try to find the index of the user causing the error to improve the error message
                    const matches = error.graphQLErrors[0].extensions.path.match(/objects\[(\d)\]/)

                    if (matches && matches.length > 1) {
                        message = `L'utilisateur avec l'adresse email ${users[matches[1]].email} existe déjà veuillez le retirer du document`
                    }

                    throw new Error(message)
                } else {
                    throw error
                }
            }

            // Check if we need to also send emails/register all users
            if (sendInviteEmails) {
                const waitPromise = (delay) => {
                    return new Promise(function(resolve) {
                        setTimeout(resolve, delay);
                    });
                }

                // Register every user inserted in the DB
                const updatedUsers = []

                // Load log types
                await dispatch('Logs/GetLogTypes', null, { root: true })

                for (var i = 0; i < users.length; i++) {
                    info.message = `Envoie des emails ${i + 1}/${users.length}...`
                    info.type = 'progress'

                    try {
                        const cognitoResponse = await dispatch('registerCognitoUser', users[i].email)
                        
                        // Set user cognito id
                        users[i].cognito_id = cognitoResponse.data.message
                        
                        // Add them to updated list
                        updatedUsers.push({
                            email: users[i].email,
                            cognito_id: cognitoResponse.data.message,
                            // Also add mail log
                            user_logs: {
                                data: {
                                    data: { type: 'access-account' },
                                    log_type_id: rootState.Logs.logTypes.mail,
                                },
                            },
                        })
                    } catch (error) {
                        info.message = `Erreur d'envoie de l'email à ${users[i].email}. Passage à l'utilisateur suivant...`
                        info.type = 'error'
                        info.errors.push(`Erreur d'envoie de l'email à ${users[i].email}.`)
                        await waitPromise(1500)
                    }
                }

                info.message = `Mise à jour des utilisateurs...`
                info.type = 'progress'

                // Upsert users to update their cognito_id
                await apollo.mutate({
                    mutation: GC_UPDATE_MULTIPLE_USER_COGNITO_ID,
                    variables: {
                        users: updatedUsers,
                    }
                })
            }

            // Reload user list utils cache
            await dispatch('Utils/getUsers', null, { root: true })

            return response?.data.insert_user.affected_rows
        }
    }
}