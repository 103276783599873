<template>
	<div class="mt-6">
		<span class="text-xl font-principal-medium">
			Contenu du chapitre
		</span>

		<div class="mt-4" v-if="module.sequences && module.sequences.length > 0">
			<SimpleSequenceForm
			:sequence="module.sequences[0]"
			@update-sequence="updateSequenceData" />
		</div>
	</div>
</template>

<script>
	import SimpleSequenceForm from "@/components/common/course/SimpleSequenceForm"

	export default {
		name: 'SimpleSequences',
		components: {
			SimpleSequenceForm
		},
		props: {
			module: {
				type: Object,
				required: true
			}
		},
		data() {
			return {}
		},
		watch: {
			module: {
				handler(module) {
					// Automaticly add one sequence if none provided
					if (module && module.sequences && module.sequences.length <= 0) {
						this.setSequence()
					}
				},
				immediate: true
			}
		},
		methods: {
			setSequence() {
				this.$emit('update-module', {
					sequences: [{
						identifier: '',
						title: '',
						order: 0,
						scenarios: [],
						media: [],
						metas: []
					}]
				})
			},
			updateSequenceData(sequenceData) {
				// Copy sequence data
				let sequence = {...this.module.sequences[0]}

				// Update specified data key
				const dataKeys = Object.keys(sequenceData)

				for (var i = 0; i < dataKeys.length; i++) {
					sequence[dataKeys[i]] = sequenceData[dataKeys[i]]
				}

				// Dispatch update to parent
				this.$emit('update-module', {
					sequences: [sequence]
				})
			}
		},
	}
</script>

<style lang="scss" scoped>
</style>