export default {
	filtered(arrayOfObject, field, value) {
		return arrayOfObject.filter((object) => {
			return (object[field] == value)
		})
	},

	countMatch(list, filter) {
		return list.filter(filter).length
	},

	/**
	 * Count matching item for each group filters and return a dictionary with group name as key and matching count as value.
	 **/
	countMatchByGroup(list, groupFilters) {
		const groupKeys = Object.keys(groupFilters)
		
		const initialDict = groupKeys.reduce((dict, key) => {
			dict[key] = 0
			return dict
		}, {})

		return list.reduce((dict, item) => {
			groupKeys.forEach((group) => {
				if (groupFilters[group](item)) {
					dict[group] += 1
				}
			})

			return dict
		}, initialDict)
	},

	countDistinct(list, field, filterNullable = false) {
		let keys = Object.keys(this.groupByUniq(list, field))

		if (filterNullable) {
			keys = keys.filter((key) => {
				return (key ? true : false)
			})
		}

		return keys.length
	},

	sumField(list, field, subfield = null) {
		let reduce = (sum, item) => {
			sum += item[field]

			return sum
		}

		if (subfield) {
			reduce = (sum, item) => {
				sum += item[field][subfield]

				return sum
			}
		}

		return list.reduce(reduce, 0)
	},

	maxField(list, field, subfield = null) {
		let reduce = (max, item) => {
			if (item[field] > max) {
				max = item[field]
			}

			return max
		}

		if (subfield) {
			reduce = (max, item) => {
				if (item[field][subfield] > max) {
					max = item[field][subfield]
				}

				return max
			}
		}

		return list.reduce(reduce, 0)
	},

	averageField(list, field, subfield = null) {
		if (list.length <= 0)
			return 0.0

		let sum = 0
		let count = 0

		// Create computing method
		let forEach = (item) => {
			const value = item[field]

			if (value != undefined) {
				sum += value
				count += 1
			}

			return sum
		}

		if (subfield) {
			forEach = (item) => {
				const value = item[field][subfield]

				if (value != undefined) {
					sum += value
					count += 1
				}

				return sum
			}
		}

		// Compute sum and count
		list.forEach(forEach)
		
		if (count <= 0)
			return 0.0

		return (sum / count)
	},

	averageValues(list) {
		if (list.length <= 0)
			return 0

		const sum = list.reduce((sum, value) => {
			sum += value 
			return sum
		}, 0)

		return (sum / list.length)
	},

	groupByUniq(list, field, transform = null) {
		return list.reduce((dict, item) => {
			if (transform) {
				item = transform(item)
			}

			dict[item[field]] = item

			return dict
		}, {})
	},

	groupBy(list, field) {
		return list.reduce((dict, item) => {
			if (!dict[item[field]])
				dict[item[field]] = []

			dict[item[field]].push(item)

			return dict
		}, {})
	},

	groupMap(dict, transform) {
		const keys = Object.keys(dict)

		let newDict = {}

		keys.forEach((key) => {
			newDict[key] = transform(dict[key])
		})

		return newDict
	},

	groupAverage(dict, transform) {
		const keys = Object.keys(dict)

		if (keys.length <= 0)
			return 0.0

		let sum = 0
		let count = 0

		keys.forEach((key) => {
			const value = transform(dict[key])

			if (value != undefined) {
				sum += value
				count += 1
			}
		})
		
		if (count <= 0)
			return 0.0

		return (sum / count)
	},

	/**
	 * Fill a dictionary with a default value, if none set, for each keys returned by the getNextKey function.
	 * The getNextKey function can return:
	 * - The first key, if null is passed
	 * - The next key, if a previous key is passed
	 * - null, if the last key is passed
	 **/
	fillDictionaryWithDefaultValue(dict, defaultValue, getNextKey = ((previousKey) => previousKey || null)) {
		let key = getNextKey(null)

		while (key !== null) {
			if (dict[key] === undefined) {
				dict[key] = defaultValue
			}

			key = getNextKey(key)
		}

		return dict
	},

	weekId(date) {
		// Generate a uniq and ordered week id for a date
		const weekDate = new Date(date.getTime())

		// Set to start of the week (previous monday)
		weekDate.setDate((weekDate.getDate() - (6 + weekDate.getDay()) % 7))

		// Create week id with Day+Month+Year
		return (weekDate.getFullYear() * 10000) + (weekDate.getMonth() * 100) + weekDate.getDate()
	},

	dateFromWeekId(weekId) {
		return new Date(Math.trunc(weekId / 10000), Math.trunc((weekId % 10000) / 100), (weekId % 100))
	},

	monthId(date) {
		// Create month id with FullYear and Month
		return date.getFullYear() + '_' + date.getMonth()
	},

	dateFromMonthId(monthId) {
		const parts = monthId.split('_')
		
		if (parts.length <= 1) {
			return false
		}

		const year = parts[0]
		const month = parts[1]

		return new Date(parseInt(year, 10), parseInt(month), 1)
	},

	generateNextMonthIdCallback(startDate, endDate) {
		return (previousMonthId) => {
			if (previousMonthId === null)
				return this.monthId(startDate)

			const previousDate = this.dateFromMonthId(previousMonthId)

			if (previousDate.getFullYear() >= endDate.getFullYear() && 
				previousDate.getMonth() >= endDate.getMonth())
				return null
			
			let nextDate = new Date(previousDate)
			nextDate.setMonth(previousDate.getMonth() + 1)

			return this.monthId(nextDate)
		}
	},

	yearId(date) {
		// Create year id with FullYear
		return date.getFullYear()
	},

	dateFromYearId(yearId) {
		return new Date(parseInt(yearId, 10), 0, 1)
	},

	generateNextYearIdCallback(startDate, endDate) {
		return (previousYearId) => {
			if (previousYearId === null)
				return this.yearId(startDate)

			if (previousYearId >= endDate.getFullYear())
				return null

			return (parseInt(previousYearId, 10) + 1)
		}
	},

	fillMissingWeek(data, fillMissing, from = null, to = new Date()) {
		// Check for missing week id
		const sortedWeekIds = Object.keys(data).map((k) => parseInt(k, 10)).sort()

		if (sortedWeekIds.length > 0) {
			// Get first week start date
			const firstWeekId = (from ? this.weekId(from) : sortedWeekIds[0])
			const firstMonday = this.dateFromWeekId(firstWeekId)

			// Get all weekIDs and add missing ones
			const oneWeek = (7 * 24 * 60 * 60 * 1000)
			const weekCount = Math.round((to.getTime() - firstMonday.getTime()) / oneWeek)

			let currentDate = firstMonday
			for (let i = 0; i < weekCount; i++) {
				const id = this.weekId(currentDate)

				if (!data[id]) {
					data[id] = fillMissing(id)
				}

				// Move to next week
				currentDate = new Date(currentDate.getTime() + oneWeek)
			}
		}

		return data
	},

	groupByWeek(list, weekIdField, from = null, to = new Date()) {
		// Group by weekIdField
		let data = this.groupBy(list, weekIdField)

		// Check for missing week and fill with empty array
		return this.fillMissingWeek(data, () => [], from, to)
	},

	/**
	 * Sort a dictionnary keys alphabetically or by a specified order.
	 * Usually used to improve display order.
	 * Return a new dictionnary.
	 **/
	sortKeys(dict, orderedKeys = null) {
		if (!orderedKeys) {
			orderedKeys = Object.keys(dict).sort()
		}

		const sortedDict = orderedKeys.reduce((sortedDict, key) => {
			if (dict[key]) {
				sortedDict[key] = dict[key]
			}

			return sortedDict
		}, {})

		return sortedDict
	}
}