<template>
  <div class="text-black">
    <!-- Buttons -->
    <div v-if="editor" class="mb-2">
      <button
        class="h-8 w-8 border"
        :class="{ 'bg-black text-white': editor.isActive('bold') }"
        @click="editor.chain().focus().toggleBold().run()"
      >
        <b-icon-type-bold />
      </button>

      <button
        class="h-8 w-8 border"
        :class="{ 'bg-black text-white': editor.isActive('italic') }"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <b-icon-type-italic />
      </button>

      <button
        class="h-8 w-8 border"
        :class="{ 'bg-black text-white': editor.isActive('link') }"
        @click="toggleLink()"
      >
        <b-icon-link />
      </button>
    </div>

    <editor-content class="twn-input" :editor="editor" />
  </div>
</template>

<script>
  import { Node } from "@tiptap/core"
  import { Editor, EditorContent } from '@tiptap/vue-2'
  
  // Base
  import Document from '@tiptap/extension-document'
  import Paragraph from '@tiptap/extension-paragraph'
  import Text from '@tiptap/extension-text'

  // Extra
  import History from '@tiptap/extension-history'
  import Bold from '@tiptap/extension-bold'
  import Italic from '@tiptap/extension-italic'
  import Link from '@tiptap/extension-link'
  
  import { createMarkdownEditor, createMarkdownExtension } from 'tiptap-markdown'
  
  import { defaultMarkdownSerializer } from "prosemirror-markdown"

  // Create a custom paragraph extension to properly serialize empty <p></p> in editor content and parse <br> from markdown-it
  const customParagraphExtension = createMarkdownExtension(
    Node.create({
      name: 'paragraph',
    }),
    {
      serialize: (state, node, parent, index) => {
        // Add new line with '\' for empty paragraph
        if (!node.textContent && !node.childCount) {
          state.write('\\\n')
          return
        }

        // Default parser if the paragraph is not empty
        defaultMarkdownSerializer.nodes.paragraph(state, node, parent, index)
      },
      parse: {
        updateDOM(element) {
          // Replace markdown-it <br> by real empty paragraph (<p></p>)
          element.innerHTML = element.innerHTML.replaceAll('<br>','</p><p>')
        },
      },
    }
  )
  
  const MarkdownEditor = createMarkdownEditor(Editor)

  export default {
    components: {
      EditorContent,
    },
    props: {
      value: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        editor: null,
      }
    },
    watch: {
      value(value) {
        const isSame = this.editor.getMarkdown() === value

        if (isSame) {
          return
        }

        this.editor.commands.setContent(value, false)
      },
    },
    mounted() {
      this.editor = new MarkdownEditor({
        content: this.value,
        extensions: [
          History,
          Document,
          Paragraph,
          Text,
          Bold,
          Italic,
          Link.configure({
            openOnClick: false,
          }),
        ],
        markdown: {
          extensions: [ customParagraphExtension ],
        },
        onUpdate: () => {
          this.$emit('input', this.editor.getMarkdown())
        },
      })
    },
    beforeDestroy() {
      this.editor.destroy()
    },
    methods: {
      toggleLink() {
        const previousUrl = this.editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
          return
        }

        // empty
        if (url === '') {
          this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run()

          return
        }

        // update link
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .run()
      }
    },
  }
</script>