<template>
  <div>
    <List
      v-if="!isLoading"
      listing="group"
      :fields="fields"
      order-by="end_date: desc, start_date: asc"
      search-text="Rechercher une promotion"
      :pageSize="pageSize"
      edit-route-name="promotions-edit"
    >
      <template v-slot:buttons>
        <div v-if="!isReadOnly" class="md:flex flex-row justify-end hidden md:w-1/3">
          <button
            type="button"
            class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
            @click="onAddPromotionClick"
          >
            Ajouter une nouvelle promotion
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import List from "@/components/List";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    List,
  },
  props: {
    pageSize:{
      type: Number,
      default: 30,
      required: false
    }
  },
  data: () => ({
    isLoading: false,
    defaultCourse: null,
  }),
  computed: {
    ...mapState('Auth', [ 'userInfo' ]),
    isSuperAdmin() {
      return (this.userInfo && this.userInfo.role == 'superadmin')
    },
    isUserAdmin() {
      return (this.userInfo && (this.userInfo.role == 'superadmin' || this.userInfo.role == 'customer_manager'))
    },
    isReadOnly() {
      return !this.isSuperAdmin && !this.isUserAdmin
    },
    availableScenarioCount() {
      if (!this.defaultCourse) {
        return null
      }

      let scenarioAvailable = 0

      // Count scenario available for default course
      this.defaultCourse.modules.forEach((module) => {
        // Count scenario available in this module
        scenarioAvailable += module.scenarios.length

        // Count scenario available and done in the module sequences
        module.sequences.forEach((sequence) => {
          // Count scenario available in this sequence
          scenarioAvailable += sequence.scenarios.length
        })
      })

      return scenarioAvailable
    },
    fields() {
      if (this.availableScenarioCount === null) {
        return null
      }

      const fields = [
        {
          key: "identifier",
          label: "Identifiant",
          sortable: true,
        },    
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        {
          key: 'start_date',
          db: `start_date
          end_date`,
          label: "Dates",
          sortable: true,
          formatter: (_value, _key, item) => {
            const start = (item.start_date ? item.start_date.split('-').reverse().join('/') : '')
            const end = (item.end_date ? item.end_date.split('-').reverse().join('/') : '')

            return start + ' - ' + end
          }
        },
        {
          key: 'disabled',
          label: 'Statut',
          sortable: true,
          formatter: (value, _key, item) => {
            if (value)
              return 'Suspendue'

            const now = new Date()
            const start = new Date(item.start_date)
            const end = new Date(item.end_date)

            return (now < start ? 'À venir' : (now > end ? 'Passé' : 'En cours'))
          }
        },
        {
          key: 'users_aggregate',
          label: "Stagiaires",
          sortable: true,
          db: `users_aggregate(where: {
            user: {
              role: { _eq: "user" },
            }
          }) {
            aggregate {
              count
            }
          }`,
          nested: 'count'
        },
        {
          label: 'Actifs',
          sortable: true,
          key: 'actives',
          nested: 'count',
          db: `actives: users_aggregate(where: {user: {
            role: { _eq: "user" },
            cognito_confirmed: {_eq: true}}}) {
            aggregate {
              count
            }
          }`
        },
        {
          label: 'Terminés',
          sortable: true,
          key: 'completed',
          nested: 'count',
          db: `completed: users_aggregate(where: {user: {user_logs_aggregate: {count: {predicate: {_gte: ${this.availableScenarioCount}}, arguments: data_key, distinct: true, filter: {type: {slug: {_eq: "complete_scenario"}}}}}}}) {
            aggregate {
              count
            }
          }`
        },
      ]

      if (this.isSuperAdmin) {
        fields.splice(2, 0,
          {
            key: 'customer',
            label: "Client",
            sortable: true,
            db: `customer {
              identifier
              name
            }`,
            formatter: (value) => {
              if (!value)
                return 'Aucun'

              return [value.identifier, value.name].filter(str => str || false).join(' - ') || '~ Client supprimé ~'
            }
          }
        )
      }

      return fields
    }
  },
  async mounted() {
    this.isLoading = true

    this.defaultCourse = await this.$store.dispatch('Course/GetDefaultCourse')

    this.isLoading = false
  },
  methods: {
    ...mapActions("EditItem", ["clearCurrentID"]),
    onAddPromotionClick() {
      this.clearCurrentID();
      this.$router.push({ name: "promotions-create" });
    },
  },
};
</script>