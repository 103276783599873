<template>
	<div class="p-4 shadowed">
		<!-- Scenarios -->
		<div>
			<label class="text-lg" :class="{ 'mb-2': (scenarios && scenarios.length) }" for="module-documents">Scenarios</label>

			<!-- List -->
			<draggable v-model="scenarios">
				<div class="scenario" v-for="(scenario, index) in scenarios" :key="scenario.scenario_id">
					<div><b-icon-justify /></div>
					<span>{{getOptionLabel(scenarioData[scenario.scenario_id])}}</span>
					<button @click="removeScenario(index)"><b-icon-x></b-icon-x></button>
				</div>
			</draggable>

			<!-- Add input -->
			<v-select
			id="module-documents"
			:class="{ 'mt-6': (scenarios && scenarios.length) }"
			placeholder="Ajouter un scenario"
			:options="scenarioList"
			:getOptionLabel="getOptionLabel"
			:reduce="scenario => scenario.id"
			v-model="selectedScenario"
			@input="addScenario($event)" />
		</div>
	</div>
</template>

<script>
	import Draggable from "vuedraggable"

	export default {
		name: 'MissionSequenceForm',
		components: {
			Draggable
		},
		props: {
			sequence: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				selectedScenario: null
			}
		},
		computed: {
			scenarios: {
				get() {
					return [...this.sequence.scenarios]
				},
				set(scenarios) {
					// Make sure order field is correct
					scenarios.forEach((scenario, index) => {
						scenario.order = index
					})

					this.$emit('update-sequence', {
						scenarios
					})
				}
			},
			scenarioData() {
				return this.$store.state.Scenarios.list.reduce((data, scenario) => {
					data[scenario.id] = scenario

					return data
				}, {})
			},
			scenarioList() {
				const ids = this.scenarios.map(scenario => scenario.scenario_id)

				return this.$store.state.Scenarios.list.filter(scenario => ids.indexOf(scenario.id) < 0)
			}
		},
		methods: {
			getOptionLabel(option) {
				if (!option)
					return '~ Élément supprimé ~'

				return [option.identifier, option.name, option.title].filter(str => str || false).join(' - ') || '~ Élément sans titre ou supprimé ~'
			},
			addScenario(scenario_id) {
				if (!scenario_id)
					return

				this.scenarios.push({
					scenario_id,
					order: this.scenarios.length
				})

				this.$emit('update-sequence', {
					scenarios: this.scenarios
				})

				this.$nextTick(() => this.selectedScenario = null)
			},
			removeScenario(index) {
				if (this.scenarios.length <= 0)
					return

				// Remove document
				this.scenarios.splice(index, 1)
				
				// Update order fields
				this.scenarios.forEach((scenario, i) => {
					if (i >= index){
						scenario.order--
					}
				})

				this.$emit('update-sequence', {
					scenarios: this.scenarios
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.shadowed {
		@apply rounded-md;
		box-shadow: $cardShadow;
	}

	.scenario {
		@apply w-full flex items-center bg-gray-background mb-2;

		div {
			@apply px-2 py-1 cursor-grab;
		}

		span {
			@apply flex-grow;
		}

		button {
			@apply px-3 py-1 text-red-700;
		}
	}
</style>