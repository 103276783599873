var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex"},[_c('table',{staticClass:"lms-table w-4/5"},[_c('tr',{staticClass:"lms-headers"},[_c('th',{staticClass:"b-n"}),_c('th',{staticClass:"b-n"}),_vm._l((_vm.columnHeaders),function(header,index){return _c('th',{key:header.column,staticClass:"lms-header text-center py-1 cursor-pointer",class:{
						'selected': (_vm.hoveredColumn == index),
						'highlighted': (_vm.hoveredColumn == (index + 1)),
					},attrs:{"title":(_vm.canAddColumn ? 'Supprimer la colonne' : 'Colonne ' + _vm.getColumnIndexLabel(index))},on:{"mouseenter":function($event){_vm.hoveredColumn = index},"mouseleave":function($event){return _vm.columnHeaderMouseLeave(index)},"click":function($event){return _vm.deleteColumn(index)}}},[(_vm.hoveredColumn != index || !_vm.canAddColumn)?_c('span',{staticClass:"header-index"},[_vm._v(" "+_vm._s(_vm.getColumnIndexLabel(index))+" ")]):_c('span',{staticClass:"header-delete"},[_vm._v(" x ")])])})],2),_c('tr',{staticClass:"lms-headers"},[_c('th',{staticClass:"b-n"}),_c('th',{staticClass:"b-n"}),_vm._l((_vm.columnHeaders),function(header,index){return _c('th',{key:header.column,staticClass:"lms-header bb-n text-center p-5",class:{
						'selected': (_vm.hoveredColumn == index),
						'highlighted': (_vm.hoveredColumn == (index + 1)),
					},attrs:{"title":"Editer l'entête de la colonne"},on:{"mouseenter":function($event){_vm.hoveredColumn = index},"mouseleave":function($event){return _vm.columnHeaderMouseLeave(index)}}},[(!_vm.hasPictureOnColumnHeaders)?_c('div',[_c('textarea',{staticClass:"w-full h-16 border-2",attrs:{"contenteditable":""},domProps:{"value":header.content},on:{"input":function($event){return _vm.updateColumnHeader(header.column, $event)}}})]):[(!header.image && !_vm.imageState)?_c('div',{key:header.image},[_c('button',{staticClass:"twn-button",attrs:{"type":"button"},on:{"click":function($event){return _vm.ShowRightPanel(null, index)}}},[_vm._v(" Ajouter une image ")])]):(_vm.imageState && !header.image)?_c('div',[_c('div',{staticClass:"drop-zone p-10 w-full h-full border-dashed border-2",on:{"dragover":_vm.allowDrop,"drop":function($event){$event.preventDefault();$event.stopPropagation();return _vm.DropAssetInHeader($event, null, index)},"click":function($event){return _vm.ShowRightPanel(null, index)}}},[_c('div',{staticClass:"m-auto w-1/3 break-words text-center text-xs"},[_vm._v(" Ajouter une image depuis la bibliothèque de média ")])])]):(header.image && _vm.assets[header.image])?_c('div',{staticClass:"relative flex flex-center",on:{"dragover":_vm.allowDrop,"drop":function($event){$event.preventDefault();$event.stopPropagation();return _vm.DropAssetInHeader($event, null, index)}}},[_c('div',{staticClass:"w-4 h-4 absolute right-0 top-0 cursor-pointer pt-1",on:{"pointerdown":function($event){$event.stopPropagation();return _vm.DeleteHeaderAsset(null, index)}}},[_c('img',{attrs:{"src":require("@/assets/images/NODE_CROSS.svg")}})]),_c('img',{attrs:{"src":_vm.assets[header.image].thumbnail_url,"alt":""}})]):(!_vm.assets[header.image])?_c('div',[_vm._v("Un probleme de média est survenu")]):_vm._e()]],2)})],2),_vm._l((_vm.lineHeaders),function(row,index){return _c('tr',{key:row.line,staticClass:"lms-line",class:{
				'selected': (_vm.hoveredLine == index),
				'highlighted': (_vm.hoveredLine == (index + 1)),
			}},[_c('td',{staticClass:"lms-line-header br-n py-6 px-3 cursor-pointer",class:{
						'bb-n': (index == (_vm.lineHeaders.length - 1)),
					},attrs:{"title":(_vm.canAddLine ? 'Supprimer la ligne' : 'Ligne ' + (index + 1))},on:{"mouseenter":function($event){_vm.hoveredLine = index},"mouseleave":function($event){return _vm.lineHeaderMouseLeave(index)},"click":function($event){return _vm.deleteLine(index)}}},[(_vm.hoveredLine != index || !_vm.canAddLine)?_c('span',{staticClass:"header-index"},[_vm._v(" "+_vm._s(index + 1)+" ")]):_c('span',{staticClass:"header-delete cursor-pointer"},[_vm._v(" x ")])]),_c('td',{staticClass:"lms-line-header br-n text-center py-6 px-8",class:{
						'bb-n': (index == (_vm.lineHeaders.length - 1)),
						'highlighted': (_vm.hoveredColumn == 0),
					},attrs:{"title":"Editer l'entête de la ligne"},on:{"mouseenter":function($event){_vm.hoveredLine = index},"mouseleave":function($event){return _vm.lineHeaderMouseLeave(index)}}},[(!_vm.hasPictureOnLineHeaders)?_c('div',[_c('textarea',{staticClass:"w-full h-16 border-2",attrs:{"contenteditable":""},domProps:{"value":row.content},on:{"input":function($event){return _vm.updateLineHeader(row.line, $event)}}})]):[(!row.image && !_vm.imageState)?_c('div',[_c('button',{staticClass:"twn-button",attrs:{"type":"button"},on:{"click":function($event){return _vm.ShowRightPanel(index, null)}}},[_vm._v(" Ajouter une image ")])]):(_vm.imageState && !row.image)?_c('div',[_c('div',{staticClass:"drop-zone p-10 w-full h-full border-dashed border-2",on:{"dragover":_vm.allowDrop,"drop":function($event){$event.preventDefault();$event.stopPropagation();return _vm.DropAssetInHeader($event, index, null)},"click":function($event){return _vm.ShowRightPanel(index, null)}}},[_c('div',{staticClass:"m-auto w-1/3 break-words text-center text-xs"},[_vm._v(" Ajouter une image depuis la bibliothèque de média ")])])]):(row.image && _vm.assets[row.image])?_c('div',{staticClass:"relative",on:{"dragover":_vm.allowDrop,"drop":function($event){$event.preventDefault();$event.stopPropagation();return _vm.DropAssetInHeader($event, null, index)}}},[_c('div',{staticClass:"w-4 h-4 absolute right-0 top-0 cursor-pointer pt-1",on:{"pointerdown":function($event){$event.stopPropagation();return _vm.DeleteHeaderAsset(index, null)}}},[_c('img',{attrs:{"src":require("@/assets/images/NODE_CROSS.svg")}})]),_c('img',{attrs:{"src":_vm.assets[row.image].thumbnail_url,"alt":""}})]):(!_vm.assets[row.image])?_c('div',[_vm._v("Un probleme de média est survenu")]):_vm._e()]],2),_vm._l((_vm.columnHeaders),function(header,hIndex){return _c('td',{key:header.column,staticClass:"lms-line-content",class:{
						'bb-n': (index == (_vm.lineHeaders.length - 1)),
						'selected': (_vm.hoveredColumn == hIndex),
						'highlighted': (_vm.hoveredColumn == (hIndex + 1)),
					},on:{"dragenter":function($event){$event.preventDefault();},"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addChoice($event, row.line, hIndex)}}},[_vm._t("default",null,{"question":_vm.questions && _vm.questions[row.line] ? _vm.questions[row.line][header.column] : null})],2)})],2)})],2),(_vm.canAddColumn)?_c('div',{staticClass:"ml-5 plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl",on:{"click":_vm.addColumn}},[_vm._v(" + ")]):_vm._e()]),(_vm.canAddLine)?_c('div',{staticClass:"mt-5 plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl",on:{"click":_vm.addLine}},[_vm._v(" + ")]):_vm._e(),_c('RightPanel',{ref:"rightPanel",attrs:{"title":"Choix de média"},on:{"hide":_vm.onRightPanelHide}},[_c('Medias',{attrs:{"defaultTypeFilterSlug":['image'],"onDragEnd":_vm.slideInRightPanel,"onDragStart":_vm.slideOutRightPanel,"selectOnly":true,"draggableAsset":true},on:{"select-media":_vm.SelectMedia}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }