<template>
	<div class="flex flex-col">
		<slot name="before" />

		<!-- Title -->
		<label>
			Titre
			<input
				type="text"
				class="twn-input w-full"
				v-model="title"
			>
		</label>

		<!-- Text -->
		<label class="relative mt-4">
			Texte
		</label>
		<MarkdownEditor
			class="w-full"
			v-model="text"
		/>

		<slot name="after" />
	</div>
</template>

<script>
	import MarkdownEditor from '@/components/common/MarkdownEditor'

	export default {
		name: 'BlockSlideFormText',
		components: {
			MarkdownEditor,
		},
		props: {
			block: {
				type: Object,
				required: true,
			},
		},
		computed: {
			title: {
				get() {
					return this.block.title
				},
				set(title) {
					this.$emit('update', {
						title,
					})
				},
			},
			text: {
				get() {
					return this.block.text
				},
				set(text) {
					this.$emit('update', {
						text,
					})
				},
			},
		},
	}
</script>