export default {
	name: 'Données pédagogiques',
	content: [
		{ 
			cells: [ 'CLIENT', '', 'PROMOTION', '', 'COMPLÉTION', '', '' ],
		},
		{
			table: 'customersGroups',
			headers: [ 'ID Client', 'Client', 'ID Promotion', 'Promotion', 'En cours', 'Parcour terminé' ],
			row: (customerGroup) => {
				return [
					{ value: customerGroup.customer.identifier },
					{ value: customerGroup.customer.name },
					'identifier',
					'name',
					{ value: (customerGroup.usersData.countByProgress.isInProgress || 0) },
					{ value: 0 }, // TODO
				]
			},
		}
	]
}