import apollo from '@/apolloClient'

import {
    GC_GET_PROMOTION_TYPE_LIST,
    GC_GET_PROMOTION_LIST,
    GC_GET_PROMOTION_BY_ID,
    GC_GET_PROMOTION_USER_DETAILS,
    GC_ADD_PROMOTION_ONE,
    GC_UPDATE_PROMOTION_BY_ID,
    GC_DELETE_PROMOTION_BY_ID,
    GC_ADD_USERS_TO_GROUP,
} from '@/graphql/promotion'

export default {
    namespaced: true,
    state: {
        list: [],
        items: {},
        typeList: [],
    },
    mutations: {
        SET_PROMOTION_LIST(state, promotions) {
            state.list = (promotions || []);
        },
        SET_PROMOTION_TYPE_LIST(state, types) {
            state.typeList = types;
        },
        SET_PROMOTION(state, promotion) {
            if (!promotion || !promotion.id)
                return

            state.items[promotion.id] = promotion
        },
    },
    actions: {
        async getTypeList({ commit }) {
            const response = await apollo.query({ query: GC_GET_PROMOTION_TYPE_LIST })

            commit('SET_PROMOTION_TYPE_LIST', response.data.group_type)
        },
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_PROMOTION_LIST })

            commit('SET_PROMOTION_LIST', response.data.group)
        },
        async getByID({ commit }, id) {
            let response = await apollo.query({
                query: GC_GET_PROMOTION_BY_ID,
                variables: { id }
            })

            if (!response.data.group_by_pk) {
                return
            }

            commit('SET_PROMOTION', response.data.group_by_pk)
        },
        async getPromotionUserDetailsByID(context, id) {
            let response = await apollo.query({
                query: GC_GET_PROMOTION_USER_DETAILS,
                variables: { id }
            })

            if (!response.data.user_by_pk) {
                return
            }

            return response.data.user_by_pk
        },
        async save(context, data) {
            let response = null
            let result = {}

            let promotion = {
                identifier: data.identifier,
                name: data.name,
                start_date: data.startDate,
                end_date: data.endDate,
                course_id: data.course,
                customer_id: data.customer_id,
                promotion_type_id: data.type,
                disabled: data.disabled
            }

            // Update or add the promotion
            if (data.id) {
                // Set id
                promotion.id = data.id

                // Get current sequences association and their ids
                promotion.sequences = []
                // promotion.sequences_ids = []

                // todo/note : shouldn't this be done in the caller ? (ie I don't have to know/don't care how you structure your data... I need MY format :p)
                // todo: compare to old db data and uniquIFY whith course sequences data ?? (or in the caller ?)
                data.sequences.forEach((sequence) => {
                    // promotion.sequences_ids.push(sequence.id)

                    promotion.sequences.push({
                        group_id: data.id,
                        sequence_id: sequence.id,
                        user_id: (sequence.userID || null),
                        start_date: sequence.startDate,
                        end_date: sequence.endDate,
                    })
                })

                // Get current users association and their ids
                promotion.users = []
                promotion.users_ids = []

                data.users.forEach((user) => {
                    promotion.users_ids.push(user.id)

                    promotion.users.push({
                        group_id: data.id,
                        user_id: user.id,
                    })
                })

                // Update remote data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_PROMOTION_BY_ID,
                    variables: promotion
                })

                result.success = true
            } else {
                // Get current sequences association
                promotion.sequences = data.sequences.map((sequence) => {
                    return {
                        sequence_id: sequence.id,
                        user_id: (sequence.userID || null),
                        start_date: sequence.startDate,
                        end_date: sequence.endDate,
                    }
                })

                // Get current users association
                promotion.users = data.users.map((user) => {
                    return {
                        user_id: user.id,
                    }
                })

                // Add remote data
                response = await apollo.mutate({
                    mutation: GC_ADD_PROMOTION_ONE,
                    variables: promotion
                })

                result.id = response.data.insert_group_one.id
            }

            await context.dispatch('Utils/getUsers', null, { root: true })
            await context.dispatch('getList')

            return result
        },
        async delete(context, id){
            const response = await apollo.mutate({
                mutation: GC_DELETE_PROMOTION_BY_ID,
                variables: { id }
            })

            await context.dispatch('getList')

            return response.data.delete_group_by_pk
        },
        async createCustomerGroup({ dispatch, state }, { user, userIds, managedCustomer }){
            await dispatch('getTypeList')

            const type = state.typeList.length > 0 && state.typeList[0]

            let users = []

            if (userIds) {
                users = userIds.map((id) => {
                    return { id }
                })
            } else if (user) {
                users = [{ id: user.id }]
            }

            const group = await dispatch('save', {
                identifier: managedCustomer.identifier,
                name: 'Promo ' + managedCustomer.name,
                startDate: new Date(),
                endDate: new Date('2100-01-01'),
                course: null,
                customer_id: managedCustomer.id,
                type: type && type.id,
                disabled: false,
                sequences: [],
                users,
            })

            return group
        },
        async addUsersToGroup(context, { id, userIds }){
            const response = await apollo.mutate({
                mutation: GC_ADD_USERS_TO_GROUP,
                variables: {
                    users: userIds.map((userId) => {
                        return {
                            group_id: id,
                            user_id: userId,
                        }
                    }),
                }
            })

            return response.data.insert_group_user
        },
    }
}