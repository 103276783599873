<template>
	<div class="flex flex-row my-6 shadowed bg-white rounded-md">
		<!-- Header -->
		<div class="handle w-10 flex justify-center items-center cursor-move">
			<div class="rotate-icon">
				<b-icon-justify></b-icon-justify>
			</div>
		</div>

		<!-- Left column -->
		<div
			class="index w-10 flex-center bg-principal-selected text-white"
		>
			{{ sequence.order + 1 }}
		</div>

		<div class="w-full p-6">
			<!-- Common fields -->
			<div class="w-full mr-4 mb-4">
				<div class="inline-block w-1/4 pr-4">
					<label :for="'sequence-identifier-' + sequence.order">Identifiant</label>
					<input :id="'sequence-identifier-' + sequence.order" type="text" class="block w-full twn-input" v-model="identifier" />
				</div>

				<div class="inline-block w-3/4 pl-4">
					<label :for="'sequence-title-' + sequence.order">Titre</label>
					<input :id="'sequence-title-' + sequence.order" type="text" class="block w-full twn-input" v-model="title" />
				</div>
			</div>
			
			<!-- Metas (duration, thumbnail, texts, ...) -->
			<div class="w-full mr-4 mb-4">
				<div class="inline-block w-1/4 pr-4">
					<label :for="'sequence-duration-' + sequence.order">Durée (min)</label>
					<input type="text"
					:id="'sequence-duration-' + sequence.order"
					class="block twn-input w-full" :value="getMetaValue('duration', 0)" @input="setMetaValue('duration', $event.target.value)">
				</div>

				<div class="inline-block w-3/4 pl-4">
					<label :for="'sequence-thumbnail-' + sequence.order">Vignette (écran de sélection des actes)</label>
					<v-select
					:id="'sequence-thumbnail-' + sequence.order"
					placeholder="Rechercher une image..."
					:options="pictureList"
					:getOptionLabel="getOptionLabel"
					:reduce="medium => medium.id"
					:value="getMetaValue('thumbnail')"
					@input="setMetaValue('thumbnail', $event)" />
				</div>
			</div>

			<div class="w-full mr-4 mb-4">
				<div class="inline-block w-1/2 pr-4">
					<label :for="'sequence-text-intro-' + sequence.order">
						Texte de présentation en début d'acte
					</label>
					<textarea
						:id="'sequence-text-intro-' + sequence.order"
						class="w-full twn-input"
						rows="6"
						:value="getMetaValue('text_intro')"
						@input="setMetaValue('text_intro', $event.target.value)"
					/>
				</div>

				<div class="inline-block w-1/2 pl-4">
					<label :for="'sequence-text-outro-' + sequence.order">
						Texte de félicitations en fin d’acte
					</label>
					<textarea
						:id="'sequence-text-outro-' + sequence.order"
						class="w-full twn-input"
						rows="6"
						:value="getMetaValue('text_outro')"
						@input="setMetaValue('text_outro', $event.target.value)"
					/>
				</div>
			</div>

			<!-- Scenarios -->
			<div class="w-full">
				<label class="text-lg" :class="{ 'mb-2': (scenarios && scenarios.length) }" :for="'sequence-scenarios-' + sequence.order">Scenarios</label>

				<!-- List -->
				<draggable v-model="scenarios">
					<div class="scenario" v-for="(scenario, index) in scenarios" :key="scenario.scenario_id">
						<div><b-icon-justify /></div>
						<span>{{getOptionLabel(scenarioData[scenario.scenario_id])}}</span>
						<button @click="removeScenario(index)"><b-icon-x></b-icon-x></button>
					</div>
				</draggable>

				<!-- Add input -->
				<v-select
				:id="'sequence-scenarios-' + sequence.order"
				:class="{ 'mt-6': (scenarios && scenarios.length) }"
				placeholder="Ajouter un scenario"
				:options="scenarioList"
				:getOptionLabel="getOptionLabel"
				:reduce="scenario => scenario.id"
				v-model="selectedScenario"
				@input="addScenario($event)" />
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	import Draggable from "vuedraggable"

	export default {
		name: 'InvestigationSequenceForm',
		components: {
			Draggable
		},
		props: {
			sequence: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				selectedScenario: null
			}
		},
		computed: {
			...mapState({
				pictureList: state => state.Media.list.filter((doc) => doc.type.slug == 'image'),
				metaTypes: state => {
					return state.Utils.metaTypeList.reduce((dict, meta) => {
						dict[meta.slug] = meta
						return dict
					}, {})
				}
			}),
			identifier: {
				get() {
					return this.sequence.identifier
				},
				set(identifier) {
					this.$emit('update-sequence', {
						identifier
					})
				}
			},
			title: {
				get() {
					return this.sequence.title
				},
				set(title) {
					this.$emit('update-sequence', {
						title
					})
				}
			},
			scenarios: {
				get() {
					return [...this.sequence.scenarios]
				},
				set(scenarios) {
					// Make sure order field is correct
					scenarios.forEach((scenario, index) => {
						scenario.order = index
					})

					this.$emit('update-sequence', {
						scenarios
					})
				}
			},
			scenarioData() {
				return this.$store.state.Scenarios.list.reduce((data, scenario) => {
					data[scenario.id] = scenario

					return data
				}, {})
			},
			scenarioList() {
				const ids = this.scenarios.map(scenario => scenario.scenario_id)

				return this.$store.state.Scenarios.list.filter(scenario => ids.indexOf(scenario.id) < 0)
			},
			metas() {
				return [...this.sequence.metas]
			}
		},
		methods: {
			getOptionLabel(option) {
				if (!option)
					return '~ Élément supprimé ~'

				return [option.identifier, option.name, option.title].filter(str => str || false).join(' - ') || '~ Élément sans titre ou supprimé ~'
			},
			getMeta(slug) {
				if (!this.metaTypes[slug])
					return { meta: { value: '~ Meta value error ~' }, index: -1 }

				for (var i = 0; i < this.metas.length; i++) {
					if (this.metas[i].meta_type_id == this.metaTypes[slug].id) {
						return { meta: this.metas[i], index: i }
					}
				}
				
				return { meta: null, index: -1 }
			},
			getMetaValue(slug, defaultValue = null) {
				const { meta } = this.getMeta(slug)

				return ((meta && meta.value) || defaultValue)
			},
			setMetaValue(slug, value) {
				let { meta, index } = this.getMeta(slug)

				if (meta) {
					if (value != null) {
						meta.value = value
					} else {
						this.metas.splice(index, 1)
					}
				} else if (value) {
					this.metas.push({
						value,
						meta_type_id: this.metaTypes[slug].id
					})
				}

				this.$emit('update-sequence', {
					metas: this.metas
				})
			},
			addScenario(scenario_id) {
				if (!scenario_id)
					return

				this.scenarios.push({
					scenario_id,
					order: this.scenarios.length
				})

				this.$emit('update-sequence', {
					scenarios: this.scenarios
				})

				this.$nextTick(() => this.selectedScenario = null)
			},
			removeScenario(index) {
				if (this.scenarios.length <= 0)
					return

				// Remove document
				this.scenarios.splice(index, 1)
				
				// Update order fields
				this.scenarios.forEach((scenario, i) => {
					if (i >= index){
						scenario.order--
					}
				})

				this.$emit('update-sequence', {
					scenarios: this.scenarios
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.shadowed {
		box-shadow: $cardShadow;
	}

	.scenario {
		@apply w-full flex items-center bg-gray-background mb-2;

		div {
			@apply px-2 py-1 cursor-grab;
		}

		span {
			@apply flex-grow;
		}

		button {
			@apply px-3 py-1 text-red-700;
		}
	}
</style>