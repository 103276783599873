import gql from 'graphql-tag'

const GC_GET_MEDIA_TYPE_LIST = gql`
query GetMediaTypes {
    media_type {
        id
        name
        slug
    }
}
`

const GC_GET_MEDIA_LIST = gql`
query GetMediaList {
    media {
    	id
        identifier
    	name: title
    	description
    	path
    	thumbnail_path
    	media_type_id
        type {
            slug
        }
    }
  }
`

export {
    GC_GET_MEDIA_TYPE_LIST,
    GC_GET_MEDIA_LIST
}