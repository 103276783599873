<template>
	<div class="dashboard-customer">
		<!-- Header -->
		<div class="flex items-center justify-between">
			<div>
				<Toggle
				v-if="isSuperAdmin"
				:checked="includeOtherRoles"
				:onInput="() => includeOtherRoles = !includeOtherRoles"></Toggle>
				<span v-if="isSuperAdmin" class="ml-2">Inclure les admins/responsables clients</span>
			</div>
			<button type="button" class="mb-4 twn-button" @click="ExportReport" v-bind:disabled="isLoading">
				<span v-if="!isLoading">
					Exporter le rapport
				</span>
				<span v-else>
					Chargement...
				</span>
			</button>
		</div>

		<!-- Dashboard -->
		<p v-if="isLoading || !trackingData">
			Chargement...
		</p>
		<div v-else>
			<!-- Users count and state data -->
			<CategoryBlock title="Utilisateurs">
				<!-- KPIs -->
				<div class="inline-block w-full align-top pr-8">
					<div class="dashboard-customer-kpis">
						<KPIBlock
							title="Nombre de promotions en cours"
							:number="activeGroupsCount"
						/>
						<KPIBlock
							title="Utilisateurs actifs"
							help="Utilisateurs ayant confirmer leur inscription en choisisant leur mot de passe"
							:number="trackingData.usersDashboardData.active"
						/>
						<KPIBlock
							title="Temps moyen passé par connexion"
							:time="trackingData.userActivityDashboardData.activeTime.average.all"
						/>
					</div>

					<div class="dashboard-customer-kpis">
						<KPIBlock
						title="Utilisateurs inscrits"
						help="Nombre total d'utilisateurs tous statuts confondus"
						:number="trackingData.usersDashboardData.total" />
						<KPIBlock
						title="Utilisateurs en attente"
						help="Utilisateurs qui n'ont pas encore reçu leur mail d'invitation"
						:number="trackingData.usersDashboardData.pending" />
						<KPIBlock
							title="Durée totale de connexion"
							:time="trackingData.userActivityDashboardData.activeTime.sum.all"
						/>
					</div>
				</div>
			</CategoryBlock>

			<!-- Users activity data -->
			<CategoryBlock title="Données de connexion">
				<!-- Active users -->
				<SubCategoryBlock class="dashboard-customer-active-user mb-8" title="Nombre d'utilisateurs actifs par">
					<div class="inline-block ml-6">
						<span class="mr-2">Mois</span>
						<Toggle
						:checked="!showUserActivityByMonth"
						:onInput="() => showUserActivityByMonth = !showUserActivityByMonth"></Toggle>
						<span class="ml-2">Années</span>
					</div>

					<LineChart :data="trackingData.userActivityDashboardData.activeCount[(showUserActivityByMonth ? 'months' : 'years')]" />
				</SubCategoryBlock>
			</CategoryBlock>
		</div>
	</div>
</template>

<script>
	import trackingReport from '@/utils/trackingReport'

	import userFieldsValues from "@/constants/userFieldsValues"
	import trackingReportTemplate from "@/constants/customerTrackingReportTemplate"

	import Toggle from '@/components/Toggle'

	import CategoryBlock from "./block/Category"
	import SubCategoryBlock from "./block/SubCategory"
	import KPIBlock from "./block/KPI"
	import LineChart from "./chart/Line"

	export default {
		name: 'MainDashboard',
		components: {
			Toggle,
			CategoryBlock,
			SubCategoryBlock,
			KPIBlock,
			LineChart,
		},
		props: {
			customer: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				isLoading: false,
				trackingData: null,
				includeOtherRoles: false,
				showUserActivityByMonth: true,
				jobs: userFieldsValues.job.reduce((dict, job) => {
					dict[job.value] = (job.labelShort || job.label)

					return dict
				}, {})
			}
		},
		computed: {
			isSuperAdmin() {
				const userInfo = this.$store.state.Auth.userInfo

				return (userInfo && userInfo.role == 'superadmin')
			},
			userCountsLabels() {
				const keys = Object.keys(this.trackingData.userProgressionDashboardData.userCounts)

				const labels = {
					hasNotStarted: 'utilisateurs inactifs',
					isInProgress: 'utilisateurs en cours',
					hasDoneLevel1: 'niveau 01 terminé',
					hasDoneLevel2: 'niveau 02 terminé'
				}

				return keys.map((key) => (labels[key] || key))
			},
			hasFinishedInLabels() {
				return ['J+1 mois', 'J+3 mois', 'J+1 an']
			},
			activeGroupsCount() {
				const nowTimestamp = Date.now()

				const activeGroups = this.customer.groups.filter((group) => {
					if (group.disabled) {
						return false
					}

					const startDate = new Date(group.start_date)
					const endDate = new Date(group.end_date)

					return (startDate.getTime() <= nowTimestamp && endDate.getTime() >= nowTimestamp)
				})

				return activeGroups.length
			}
		},
		watch: {
			customer: {
				async handler(customer) {
					this.isLoading = true

					// Load tracking data
					const payload = { id: customer.id }

					if (this.includeOtherRoles) {
						payload.roles = ['user', 'superadmin', 'customer_manager']
					}

					this.trackingData = await trackingReport.loadData(this.$store, trackingReportTemplate, payload)

					this.isLoading = false
				},
				immediate: true
			},
			async includeOtherRoles(includeOtherRoles) {
				this.isLoading = true

				// Load tracking data
				const payload = { id: this.customer.id }

				if (includeOtherRoles) {
					payload.roles = ['user', 'superadmin', 'customer_manager']
				}

				this.trackingData = await trackingReport.loadData(this.$store, trackingReportTemplate, payload)

				this.isLoading = false
			}
		},
		methods: {
			async ExportReport(){
				this.isLoading = true
				
				try {
					const customerName = (this.customer.identifier ? this.customer.identifier : this.customer.name).substring(0, 7).trim().replace(' ', '_')
					await trackingReport.export('Client_' + customerName, this.$store, trackingReportTemplate, null, this.trackingData)
				} catch (err) {
					// console.error(err)
				}

				this.isLoading = false
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-customer {
		@apply max-w-screen-lg mx-auto;

		&-kpis {
			@apply flex flex-wrap justify-between;

			&:first-child {
				@apply mb-8;
			}
		}

		.dashboard-category {
			@apply w-full mb-5;

			.dashboard-kpi {
				@apply flex-1 mx-4;

				&:first-child {
					@apply ml-0;
				}

				&:last-child {
					@apply mr-0;
				}
			}
		}

		&-active-user {
			& > p {
				display: inline-block;
			}
		}

		&-finish-by-job {
			@apply flex;
			padding-left: 100px;

			.dashboard-chart {
				@apply pr-8 mb-3;
			}

			& > div:first-child {
				margin-left: -100px;

				p.ct-label {
					margin-left: 100px;
				}
			}

			p.ct-label {
				@apply text-dashboard-blue-dark;

				font-style: italic;
			}
		}
	}
</style>