<template>
  <div class="mt-4">
    <div class="inline-block w-1/3 mb-4">
      <label v-if="!isReadOnly && !isUserManager">Ajouter un utilisateur</label>
      <v-select
      v-if="!isReadOnly && !isUserManager"
      :options="internsList"
      :filterable="true"
      label="name"
      placeholder="Rechercher un utilisateur..."
      v-model="selectedUser"
      @input="onUserSelect($event)"
      >
        <template #no-options>
          Aucun utilisateur disponible.
        </template>
      </v-select>
    </div>

    <div class="inline-block w-2/3 text-right px-4">
      <button v-if="!isReadOnly && !isUserManager" @click="onImportUserClick" type="button" class="twn-button block whitespace-no-wrap overflow-hidden text-xs mb-2 ml-auto">Importer des utilisateurs</button>
      <span>{{ this.promotion.users.length }}</span>
      <span> utilisateurs</span>
    </div>

    <div class="border-line-left border rounded p-4">
      <table class="group-interns w-full">
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Email</th>
          <th>Statut</th>
          <th>Progression</th>
          <th v-if="!isReadOnly"></th>
        </tr>

        <tr
          v-for="intern in promotion.users"
          :key="intern.id"
          class="cursor-pointer"
          @click="onEditInternClick(intern)"
        >
          <template v-if="interns[intern.id]">
            <td>{{interns[intern.id].first_name}}</td>
            <td>{{interns[intern.id].name}}</td>
            <td>{{interns[intern.id].email}}</td>
            <td>{{interns[intern.id].state}}</td>
            <td>{{interns[intern.id].progress}}</td>
            <td
              v-if="!isReadOnly"
              class="flex justify-center text-xl font-bold items-center cursor-pointer col-span-1"
              @click.stop="onRemoveInternClick(intern)"
            >
              <div class="border border-gray-light rounded-full text-center w-8 h-8">-</div>
            </td>
          </template>
        </tr>
      </table>
    </div>

    <UserImportModal v-model="isImportModalOpen" :defaultGroup="promotion.id" />
  </div>
</template>

<script>

import { mapState } from 'vuex'

import userFieldsValues from "@/constants/userFieldsValues"

import { getUserProgressionForGroup, getUserState, getUserGroupState } from "@/utils/userUtils"

import UserImportModal from '@/components/common/user/UserImportModal'

export default {
  props: {
    promotion: {
      type: Object,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UserImportModal
  },
  data() {
    return {
      isImportModalOpen: false,
      selectedUser: null,
      defaultCourse: null,
    }
  },
  async mounted() {
    this.defaultCourse = await this.$store.dispatch('Course/GetDefaultCourse')
  },
  computed: {
    ...mapState('Utils', ['userList']),
    ...mapState({
      userInfo: state => state.Auth.userInfo
    }),
    isUserManager() {
      return (this.userInfo && this.userInfo.role == 'customer_manager')
    },
    internsList() {
      if (!this.userList)
        return []

      return this.userList.filter((user) => {
        return (!user.groups || user.groups.length <= 0) && !this.promotion.users.some((intern) => (user.id == intern.id))
      }).map((user) => {
        return {
          id: user.id,
          name: [user.first_name, user.name].filter(str => str || false).join(' ') + ' - ' + user.email,
        }
      })
    },
    interns() {
      if (!this.defaultCourse || !this.promotion.users)
        return {}

      return this.promotion.users.reduce((dict, user) => {
        // Set user progress label
        const progression = getUserProgressionForGroup(user, this.promotion, this.defaultCourse)

        if (progression >= 1) {
          user.progress = 'Parcours terminé'
        } else if (progression > 0) {
          const percentage = (progression * 100)

          if (percentage > 0 && percentage < 1) {
            return percentage.toFixed(2) + '%'
          }

          user.progress = Math.round(percentage) + '%'
        } else {
          user.progress = '-'
        }

        // Set user state label
        user.state = userFieldsValues.stateMap[getUserState(user)]?.label || '-'

        // Add user group state label if needed
        const groupState = getUserGroupState(user)

        if (groupState === 'disabled') {
          user.state += ' - Promotion suspendue'
        } else if (groupState === 'inactive') {
          user.state += ' - Promotion inactive'
        }

        dict[user.id] = user

        return dict
      }, {})
    },
  },
  methods: {
    onUserSelect(intern) {
      this.$emit('add-intern', intern)

      this.$nextTick(() => this.selectedUser = null)
    },
    onEditInternClick(intern) {
      const routeData = this.$router.resolve({
        name: 'user-edit',
        params: {
          itemID: intern.id,
        },
      })
      window.open(routeData.href, '_blank');
    },
    onRemoveInternClick(intern) {
      this.$emit('remove-intern', intern)
    },
    onImportUserClick() {
      this.isImportModalOpen = true
    }
  },
};
</script>

<style lang="scss">
  .group-interns {
    @apply border-separate;

    tr {
      &:hover td {
        @apply border-gray-lighter;
      }
    }

    td {
      @apply py-1 border-t-1 border-b-1 border-white;
    }
  }
</style>