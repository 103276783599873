import utils from "@/utils/trackingReportTemplate"

import userFieldsValues from "@/constants/userFieldsValues"

export default {
	usersData(customer, data) {
		// Get users data from their id
		const users = customer.users.map((user) => {
			return data.usersById[user.id]
		})

		// Count user by state
		const countbyState = utils.groupMap(utils.groupBy(users, 'state'), (users) => {
			return users.length
		})

		const activeTime = utils.sumField(users, 'activeTimeData', 'sum')

		// Get user count by their progression steps
		const progressStepFilters = {
			isInProgress: (user) => (user.state != 'disabled' && user.progressionData.hasStarted && !user.progressionData.hasDoneLevel2),
			hasDoneLevel1: (user) => (user.state != 'disabled' && user.progressionData.hasDoneLevel1),
			hasDoneLevel2: (user) => (user.state != 'disabled' && user.progressionData.hasDoneLevel2)
		}
		const countByProgress = utils.countMatchByGroup(users, progressStepFilters)

		// Get user mailing rate by type
		const hasReceiveMail = (user, mailType) => {
			return !!user.mailingLogs[mailType]
		}
		const mailingFilters = {
			noLogin: (user) => (hasReceiveMail(user, 'reminder-no-login') || hasReceiveMail(user, 'reminder-no-login-active')),
			notFinishedAfterOneDay: (user) => (hasReceiveMail(user, 'reminder-not-finished-day')),
			notFinishedAfterOneMonth: (user) => (hasReceiveMail(user, 'reminder-not-finished-month'))
		}
		const rateByMailType = (users) => {
			const countByMailType = utils.countMatchByGroup(users, mailingFilters)

			return utils.groupMap(countByMailType, (count) => {
				if (users.length <= 0) {
					return 0.0
				}

				return (count / users.length)
			})
		}

		// Get user grouped by job and sort job by display order
		const usersByJob = utils.sortKeys(utils.groupBy(users, 'job'), userFieldsValues.job.map(job => job.value))

		const byJob = utils.groupMap(usersByJob, (users) => {
			return {
				count: users.length,
				activeTime: utils.sumField(users, 'activeTimeData', 'sum'),
				countByProgress: utils.countMatchByGroup(users, progressStepFilters),
				rateByMailType: rateByMailType(users),
			}
		})

		return {
			countbyState,
			activeTime,
			countByProgress,
			rateByMailType: rateByMailType(users),
			byJob
		}
	}
}