import utils from "@/utils/trackingReportTemplate"

import userFieldsValues from "@/constants/userFieldsValues"

function getActiveUserCountData(data) {
	// Create dictionary of active user ids per years and months
	let activeData = {
		years: {},
		months: {}
	}

	// Get count of active users per years and months
	data.users.forEach((user) => {
		const monthIds = Object.keys(user.activeTimeData.monthIds)
		const yearIds = Object.keys(user.activeTimeData.yearIds)

		monthIds.forEach((monthId) => {
			if (!activeData.months[monthId])
				activeData.months[monthId] = 0

			activeData.months[monthId] += 1
		})

		yearIds.forEach((yearId) => {
			if (!activeData.years[yearId])
				activeData.years[yearId] = 0

			activeData.years[yearId] += 1
		})
	}, {})

	// Make sure all months and years are filled with a value
	const startDate = data.firstCreatedUserDate
	const endDate = new Date()

	utils.fillDictionaryWithDefaultValue(activeData.years, 0, utils.generateNextYearIdCallback(startDate, endDate))
	utils.fillDictionaryWithDefaultValue(activeData.months, 0, utils.generateNextMonthIdCallback(startDate, endDate))

	return activeData
}

function getUserActiveTimeDataByJob(data) {
	// Get user grouped by job and sort job by display order
	const usersByJob = utils.sortKeys(utils.groupBy(data.confirmedUsers, 'job'), userFieldsValues.job.map(job => job.value))

	// Get active time sum and average by job
	let activeTimeByJob = {
		sum: utils.groupMap(usersByJob, (users) => {
			return utils.sumField(users, 'activeTimeData', 'sum')
		}),
		average: utils.groupMap(usersByJob, (users) => {
			return utils.averageField(users, 'activeTimeData', 'average')
		})
	}

	// Add sum and average for all users
	activeTimeByJob.sum.all = utils.sumField(data.confirmedUsers, 'activeTimeData', 'sum')
	activeTimeByJob.average.all = utils.averageField(data.confirmedUsers, 'activeTimeData', 'average')

	return activeTimeByJob
}

export default function userLoginsDashboardData(data) {
	return {
		activeCount: getActiveUserCountData(data),
		activeTime: getUserActiveTimeDataByJob(data)
	}
}