<template>
	<div class="w-full question flex flex-row relative justify-center items-center">
		<!-- Header/Handle -->
		<div class="handle self-stretch w-10 flex justify-center items-center cursor-move">
			<div class="rotate-icon">
				<b-icon-justify></b-icon-justify>
			</div>
		</div>

		<!-- Content -->
		<div class="flex items-center flex-1">
			<!-- Index -->
			<div
				v-if="identifier"
				class="handle cursor-pointer index bg-principal-selected w-10 self-stretch flex justify-center items-center text-xs"
			>
				<div>{{ identifier }}</div>
			</div>

			<!-- Form -->
			<div class="content p-10 w-full flex">
				<div class="flex-grow">
					<!-- Character name -->
					<div class="input-field w-full">
						<b-form-input
						placeholder="Nom du personnage"
						type="text"
						v-model="name" />
					</div>

					<!-- Character picture and message medium -->
					<div class="input-field w-full mt-4 flex justify-around items-center">
						<!-- Picture -->
						<v-select
						class="twn-select w-full mr-2"
						placeholder="Image portrait"
						:getOptionLabel="getMediaLabel"
						:options="pictureList"
						:reduce="medium => medium.id"
						v-model="picture" />

						<!-- Medium -->
						<v-select
						class="twn-select w-full ml-2"
						placeholder="Media vidéo ou son"
						:getOptionLabel="getMediaLabel"
						:options="mediaList"
						:reduce="medium => medium.id"
						v-model="medium" />
					</div>
				</div>

				<!-- Type -->
				<div class="flex flex-col px-4" v-if="types">
					<!-- todo: component to list type as select or radio (all type or only some) -->
					<label
					class="cursor-pointer"
					v-for="slug in ['message_visio', 'message_facetime', 'message_audio'].filter(slug => types[slug] || false)"
					:key="slug"
					:for="'type' + slug + identifier">
						<input
						:id="'type' + slug + identifier"
						class="mr-1"
						type="radio"
						:name="'type' + identifier"
						:value="types[slug].id"
						v-model="type">
						{{ types[slug].name }}
					</label>
				</div>
			</div>
		</div>

		<!-- Footer -->
		<div
			@click="deleteQuestion"
			class="cursor-pointer deleteQuestion self-stretch w-10 flex justify-center items-center text-white"
		>
			<b-icon-x></b-icon-x>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	export default {
		name: 'Message',
		props: {
			question: {
				type: Object,
				required: true
			},
			identifier: {
				type: String,
				required: false,
				default: null
			}
		},
		computed: {
			...mapState("Assets", {
				pictureList: (state) => state.assets.filter(medium => medium.type.slug == 'image'),
				mediaList: (state) => state.assets.filter(medium => ['audio', 'video'].indexOf(medium.type.slug) > -1)
			}),
			...mapState("Games", {
				types(state){
					if (!state.question_type_list || state.question_type_list.length <= 0)
						return null

					return state.question_type_list.reduce((dict, type) => {
						dict[type.slug] = type
						return dict
					}, {})
				}
			}),
			name: {
				get() {
					return this.question.text
				},
				set(value) {
					this.$emit("update-question", {
						text: value
					})
				}
			},
			picture: {
				get() {
					return this.question.media_id
				},
				set(value) {
					this.$emit("update-question", {
						media_id: value
					})
				}
			},
			medium: {
				get() {
					return (this.question.answers && this.question.answers.length > 0 && this.question.answers[0].media_id)
				},
				set(value) {
					let newAnswers = [...this.question.answers]

					if (value) {
						
						if (newAnswers.length <= 0) {
							newAnswers.push({
								text: '',
								is_correct: false,
								media_id: value
							})
						} else {
							newAnswers[0].media_id = value
						}
					} else {
						newAnswers = []
					}
					
					this.$emit("update-question", {
						answers: newAnswers
					})
				}
			},
			type: {
				get() {
					return this.question.question_type_id
				},
				set(value) {
					this.$emit("update-question", {
						question_type_id: value
					})
				}
			}
		},
		methods: {
			getMediaLabel(medium) {
				return [medium.title, medium.name].filter(str => str || false).join(' - ')
			},
			deleteQuestion() {
				this.$emit("delete-question")
			}
		}
	}
</script>

<style lang="scss">
	.question {
		background: white;
		box-shadow: $cardShadow;
		border-radius: 10px;

		&.removeState {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}

		color: $textLight;
		.index {
			background-color: rgba(220, 151, 153, 0.1);
		}

		.rotate-icon {
			transform: rotate(90deg);
		}

		.image-content {
			.image {
				img {
					max-height: 150px;
				}
			}
		}

		.deleteQuestion {
			@apply bg-principal-selected;
			border-radius: 0 10px 10px 0;
		}
}
</style>
