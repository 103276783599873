<template>
  <div>
    <List
      listing="narrative"
      :fields="fields"
      :filters="filters"
      search-text="Rechercher une présentation"
      :pageSize="pageSize"
      edit-route-name="narrative-edit"
    >
      <template v-slot:buttons>
        <div class="md:flex flex-row justify-around hidden md:w-1/3">
          <button
            type="button"
            class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
            @click="onAddNarrativeClick"
          >
            Ajouter une nouvelle présentation
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
  import List from "@/components/List";
  import { mapActions } from "vuex";

  export default {
    components: {
      List,
    },
    props: {
      pageSize:{
        type: Number,
        default: 30,
        required: false
      }
    },
    data: () => ({
      fields: [
        {
          label: 'Identifiant',
          key: 'identifier',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Titre',
          sortable: true,
        },
        {
          label: 'Slides',
          sortable: true,
          key: 'slides_aggregate',
          nested: 'count',
          db: `slides_aggregate {
            aggregate {
              count
            }
          }`
        },
        {
          label: 'Utilisations',
          sortable: true,
          key: 'usedInNodes_aggregate',
          nested: 'count',
          db: `
          usedInNodes_aggregate {
            aggregate {
              count
            }
          }
          `
        }
      ],
      filters: [
        {
          key:'type',
          label: 'Type'
        }
      ]
    }),

    methods: {
      ...mapActions("EditItem", ["clearCurrentID"]),
      onAddNarrativeClick() {
        this.clearCurrentID();
        this.$router.push({ name: "narrative-create" });
      },
    },
  };
</script>