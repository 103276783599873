import utils from "@/utils/trackingReportTemplate"

import userFieldsValues from "@/constants/userFieldsValues"

export default function usersDashboardData(data) {
	const countbyState = utils.groupMap(utils.groupBy(data.users, 'state'), (users) => {
		return users.length
	})

	// Get user grouped by job and sort job by display order
	const usersByJob = utils.sortKeys(utils.groupBy(data.users, 'job'), userFieldsValues.job.map(job => job.value))

	const percentByJob = utils.groupMap(usersByJob, (users) => {
		return (users.length / data.users.length)
	})

	const hasFinishedCount = utils.countMatch(data.users, user => user.progressionData.hasDoneLevel2)

	return {
		active: countbyState.active || 0,
		inactive: countbyState.inactive || 0,
		hasFinished: (hasFinishedCount / data.confirmedUsers.length),
		total: data.users.length,
		pending: countbyState.pending || 0,
		disabled: countbyState.disabled || 0,
		percentByJob
	}
}